import {
  AppBar,
  BackdropClassKey,
  BackdropProps,
  createStyles,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import chroma from "chroma-js";
import classNames from "classnames";
import { ReactElement } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textColor: {
      color:
        chroma.contrast(theme.palette.primary.main, "white") > 2
          ? "white"
          : "black",
    },

    title: {
      flex: 1,
    },
    modalConent: {
      marginTop: "70px",
    },

    modalBackdrop: {
      backgroundColor: theme.palette.common.black,
    },
  })
);

type DialogHeaderPropsType = {
  onClose:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
  title: string;
  disabledExit?: boolean;
};

const DialogHeader = (props: DialogHeaderPropsType): JSX.Element => {
  const classes = useStyles();

  return (
    <AppBar position="absolute" color="primary">
      <Toolbar>
        <Typography
          variant="h6"
          className={classNames(classes.title, classes.textColor)}
        >
          {props.title}
        </Typography>
        {!props.disabledExit && (
          <IconButton
            className={classes.textColor}
            onClick={props.onClose}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

type GeneralModalFormPropsType = {
  open: boolean;
  handleModalStatus: Function;
  title: string;
  form: ReactElement;
  notFullWidth?: boolean;
  hideBackdrop?: boolean;
  darkBackdrop?: boolean;
  disableExit?: boolean;
};

export const ModalForm = (props: GeneralModalFormPropsType) => {
  const classes = useStyles();
  //   const handleClickOpen = () => props.handleModalStatus(true);
  const handleClickClose = () => {
    props.handleModalStatus();
  };

  return (
    <Dialog
      disableBackdropClick
      open={props.open}
      onClose={handleClickClose}
      fullWidth={props.notFullWidth ? false : true}
      hideBackdrop={props.hideBackdrop}
      BackdropProps={
        props.darkBackdrop ? { classes: { root: classes.modalBackdrop } } : {}
      }
      maxWidth="sm"
    >
      <DialogHeader
        onClose={handleClickClose}
        title={props.title}
        disabledExit={props.disableExit}
      />
      <DialogContent
        className="mui-form"
        classes={{ root: classes.modalConent }}
      >
        {props.form}
      </DialogContent>
    </Dialog>
  );
};
