import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Store";
import { fetchActiveProfile, fetchProfiles } from "../actions/profilesActions";
import { initialRunner, RunnerType } from "../reducers/profilesReducers";

export const useSelectProfile = (
  accountId: string | null | undefined
): RunnerType | null => {
  const profileList = useSelector((state: AppState) => state.global.profiles);
  const activeProfile = useSelector(
    (state: AppState) => state.global.profiles.activeProfile
  );
  const [profile, setProfile] = useState<RunnerType>(initialRunner);
  const dispatch = useDispatch();

  useEffect(() => {
    if (accountId) {
      //if no profile list
      if (!profileList.profileIds) {
        //fetch all profiles
        fetchProfiles(
          accountId,
          (ids: Array<string>) => {
            if (ids.length > 0) {
              getActiveProfile(ids[0]);
            } else {
              //fail to fetch active profiles
              console.log("No profile found");
            }
          },
          () => {
            console.log("hit fail");
          }
        )(dispatch);
      } else {
        console.log("hit");
      }
    }
  }, [accountId]);

  const getActiveProfile = (profileId: string) => {
    fetchActiveProfile(
      profileId,
      (profile: RunnerType) => {
        setProfile(profile);
      },
      () => {
        console.log("fetch fail");
      }
    )(dispatch);
  };

  return profile;
};
