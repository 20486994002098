import React, { Fragment, useState } from "react";
import classNames from "classnames";
import { Drawer, IconButton, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { drawerStyles } from "../../global/styles/generalStyles";
import { ImagusSearchBar } from "../../ImagusComponents/FormInputs/ImagusSearchBar";
import { EventList } from "./EventList";
import { EventType } from "../types/participantsType";
import { RaceRedux } from "../../global/container/race/RaceRedux";
import { iconStyles } from "../../global/styles/iconStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...drawerStyles(theme),
    ...iconStyles(theme),
    hide: {
      display: "none",
    },
  })
);

type EventsPanelPropsType = {
  handleEventSearch: Function;
  eventList: Array<EventType> | null;
};

export const EventsPanel = (props: EventsPanelPropsType) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [selectedEvent, setSelectedEvent] = useState<EventType>();

  const toggleSearchBarDrawer = () => {
    setOpen(!open);
  };

  const handleEventSelect = (eventId: string) => {
    let event: EventType | undefined;
    if (props.eventList) {
      event = props.eventList.find((event) => event.eventId === eventId);
    }
    setSelectedEvent(event);
  };

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !open && classes.drawerPaperClose
          ),
        }}
      >
        <Fragment>
          <div className={classes.toolbar}>
            <div
              className={classNames(
                classes.toolbarSearch,
                !open && classes.hide
              )}
            >
              <ImagusSearchBar
                searchQuery=""
                handleSearch={props.handleEventSearch}
              />
            </div>
            {open ? (
              <IconButton onClick={toggleSearchBarDrawer}>
                <ChevronLeftIcon className={classes.greenIcon} />
              </IconButton>
            ) : (
              <IconButton onClick={toggleSearchBarDrawer}>
                <ChevronRightIcon className={classes.greenIcon} />
              </IconButton>
            )}
          </div>
          {open && (
            <EventList
              eventList={props.eventList}
              selectEvent={handleEventSelect}
              selectedEventId={selectedEvent?.eventId}
            />
          )}
        </Fragment>
      </Drawer>
      <main className={classes.content}>{/* <RaceRedux /> */}</main>
    </div>
  );
};
