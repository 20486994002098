import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Store";
import { authenticate } from "../actions/userActions";
import { RedirectToLogin } from "./RedirectToLogin";

type UserAuthPropsType = {
  children: ReactElement;
  principles?: string;
};

export const hasPermission = (
  requiredPrinciple: string | undefined,
  userPrinciples: Array<string> | null
): boolean => {
  if (!requiredPrinciple) {
    return true;
  } else {
    if (userPrinciples) {
      for (var i = 0; i < userPrinciples.length; i++) {
        if (requiredPrinciple === userPrinciples[i]) {
          return true;
        }
      }
    }
  }
  return false;
};

export const UserAuth = (props: UserAuthPropsType) => {
  const user = useSelector((state: AppState) => state.global.user);
  const userRoles = user.info && user.info.roles;
  // const [hasPrincicple, setHasPrinciple] = useState(true);
  const dispatch = useDispatch();

  let hasPrinciple = true;

  useEffect(() => {
    if (user && !user.fetchingAuth && !user.auth) {
      authenticate(true)(dispatch);
    }
  }, []);

  const handlePermissionCheck = (principle: string) => {
    if (!hasPermission(principle, userRoles)) {
      hasPrinciple = false;
    }
  };

  let authentication = false;
  if (user && user.auth !== null) {
    if (user.auth === true) {
      authentication = true;
      if (props.principles) {
        handlePermissionCheck(props.principles);
      }
    }
  }

  let isAuthenticated = false;
  if (authentication && hasPrinciple) isAuthenticated = true;

  if (isAuthenticated) {
    return props.children;
  } else if (
    user.auth === false ||
    user.fetchingAuth === null ||
    user.fetchingAuth ||
    userRoles === null
  ) {
    return null;
  } else {
    return <RedirectToLogin />;
  }
};
