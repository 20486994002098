import { SET_THEME } from "../../global/actions/actionNames";
import { SiteActionTypes } from "../types/siteTypes";

//Site Reducer's slice
export interface SiteState {
  themeName: string;
}

const initialThemeState: SiteState = {
  themeName: "darkTheme",
};

export const siteReducers = (
  state = initialThemeState,
  actions: SiteActionTypes
) => {
  switch (actions.type) {
    case SET_THEME:
      if (actions.themeName !== null) {
        return {
          ...state,
          themeName: actions.themeName,
        };
      }
      return state;
    default:
      return state;
  }
};
