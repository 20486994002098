import { blueTheme, darkTheme } from "../../global/styles/theme";

import {
  createStyles,
  FormControlLabel,
  makeStyles,
  Radio,
  Theme,
} from "@material-ui/core";

import classNames from "classnames";
import chroma from "chroma-js";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    blueLabel: {
      backgroundColor: blueTheme.palette.primary.main,
      color:
        chroma.contrast(blueTheme.palette.primary.main, "white") > 2
          ? "white"
          : "black",
    },

    darkLabel: {
      backgroundColor: darkTheme.palette.primary.main,
      color:
        chroma.contrast(darkTheme.palette.primary.main, "white") > 2
          ? "white"
          : "black",
    },

    label: {
      width: 150,
      height: 70,
      textAlign: "center",
      borderRadius: "2em",
      padding: "2px 12px",
      display: "inline-block",
    },
    text: {
      fontSize: "1.25rem",
      lineHeight: "70px",
    },
  })
);

type ThemeRadioPropsType = {
  theme: string;
};

export const ThemeRadio = (props: ThemeRadioPropsType) => {
  const classes = useStyles();
  const matchDict: { [index: string]: { value: string; style: string } } = {
    blue: { value: "blueTheme", style: classes.blueLabel },
    dark: { value: "darkTheme", style: classes.darkLabel },
  };

  return (
    <FormControlLabel
      value={matchDict[props.theme].value}
      control={<Radio />}
      label={
        <span
          className={classNames(matchDict[props.theme].style, classes.label)}
        >
          <span className={classes.text}>{matchDict[props.theme].value}</span>
        </span>
      }
      labelPlacement="top"
    />
  );
};
