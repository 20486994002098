import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../Store";
import { PhotoDisplay } from "../../components/profiles/PhotoDisplay";
import {
  fetchActiveProfile,
  fetchProfiles,
  getEnrolments,
  updateProfile,
} from "../../actions/profilesActions";

import { CircularProgress, Theme, Typography } from "@material-ui/core";
import { RunnerResponseModel } from "../../types/ApiReturnTypes";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { buttonStyles } from "../../styles/buttonStyles";
import { contentStyles, panelStyles } from "../../styles/generalStyles";
import chroma from "chroma-js";
import classNames from "classnames";
import { PermanentPhotos } from "../../components/profiles/PermanentPhotos";
import { EnrolmentType, RunnerType } from "../../reducers/profilesReducers";
import { act } from "react-dom/test-utils";
import { useSelectProfile } from "../../helperFunctions/useSelectProfile";
import { PhotoDetails } from "../../components/profiles/PhotoDetails";
import { profile } from "node:console";
import { uploadFile, detectFaces } from "../../actions/faceSearchActions";
import { EnrolmentInputType } from "../../types/faceSearchTypes";
import { CircleFaceType } from "../../../ImagusComponents/Gadgets/ImagusFacePicker";
import { useFetchImageForEnrolments } from "../../helperFunctions/useFetchImageForEnrolments";
import { fetchProfileById } from "../../../admin/actions/participantsActions";
import { useFetchImageForActiveProfile } from "../../helperFunctions/useFetchImageForActiveProfile";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...contentStyles(theme),
    ...panelStyles(theme),
    ...buttonStyles(theme),

    sectionHeader: {
      backgroundColor: theme.palette.primary.light,
      border: "1px solid black",
      borderRadius: "25px",
      color:
        chroma.contrast(theme.palette.primary.light, "white") > 2
          ? "white"
          : "black",
      paddingLeft: "15px",
    },

    photoPanel: {
      backgroundColor: theme.palette.contentBackgroundColor,
      height: "auto",
      borderRadius: "25px",
      margin: "15px auto",
    },

    enrolmentPanel: {
      borderRadius: "25px",
      height: "100%",
    },
  })
);

type PhotoDisplayReduxPropsType = {
  removePhoto?: Function;
};

export const PhotoDisplayRedux = (props: PhotoDisplayReduxPropsType) => {
  const [loading, setLoading] = useState(true);
  const accountInfo = useSelector((state: AppState) => state.global.user.info);
  const profileList = useSelector(
    (state: AppState) => state.global.profiles.profileIds
  );
  const activeProfile = useSelector(
    (state: AppState) => state.global.profiles.activeProfile
  );

  const faces = useSelector(
    (state: AppState) => state.global.faceSearch.detectedFaces
  );

  const classes = useStyles();
  const dispatch = useDispatch();
  useSelectProfile(accountInfo?.id);
  // useFetchImageForEnrolments(activeProfile.uid, activeProfile.enrolments);
  // useFetchImageForActiveProfile();
  // let permanentEnrolments: Array<EnrolmentType> = [];
  // activeProfile.enrolments.map((eachEnrolment) => {
  //   if (eachEnrolment.permanent) {
  //     return permanentEnrolments.push(eachEnrolment);
  //   }
  // });

  const fetchCurrentProfile = () => {
    fetchActiveProfile(activeProfile.uid)(dispatch);
  };

  const handleUpdateCurrentProfile = (
    newEnrolments: Array<EnrolmentInputType>,
    deleteEnrolments: Array<string>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    accountInfo &&
      updateProfile(
        activeProfile.uid,
        activeProfile.name,
        newEnrolments,
        deleteEnrolments,
        accountInfo.id,
        doneBack,
        failBack
      )(dispatch);
  };

  const handleUploadFile = (
    file: File | Blob,
    doneBack: Function,
    failBack?: Function
  ) => {
    uploadFile(
      file,
      true,
      (token: string) =>
        detectFaces(
          token,
          (result: Array<CircleFaceType>) => {
            doneBack(token, result);
          },
          failBack
        )(dispatch),
      failBack
    )(dispatch);
  };

  const addEnrolmentsToProfile = (
    newEnrolments: Array<EnrolmentInputType>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    updateProfile(
      activeProfile.uid,
      activeProfile.name,
      newEnrolments,
      [],
      undefined,
      () => {
        updateDone();
        doneBack && doneBack();
      },
      failBack
    )(dispatch);
  };

  const updateDone = () => {
    getProfileEnrolments(activeProfile.uid);
  };

  const removePhoto = (
    enrolmentIds: Array<string>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    handleUpdateCurrentProfile(
      [],
      enrolmentIds,
      () => {
        updateDone();
        doneBack && doneBack();
      },
      failBack
    );
  };

  const getProfileEnrolments = (profileId: string): Array<EnrolmentType> => {
    let enrolments: Array<EnrolmentType> = [];
    fetchActiveProfile(profileId, (profile: RunnerType) => {
      enrolments = profile.enrolments;
    })(dispatch);
    return enrolments;
  };

  if (!activeProfile) {
    return <CircularProgress />;
  } else {
    return (
      <div className={classes.appPanel}>
        <Typography variant="h5" className={classes.sectionHeader}>
          Your enrolments
        </Typography>
        <div
          className={classNames(classes.appPanelContent, classes.photoPanel)}
        >
          {/** Permanent Photos */}
          <PermanentPhotos
            profile={activeProfile}
            column={10}
            removePhoto={removePhoto}
          />
          {/* <PermanentPhotos enrolments={activeProfile.enrolments} column={10} /> */}
        </div>
        <Typography variant="h5" className={classes.sectionHeader}>
          Create enrolments
        </Typography>
        <div
          className={classNames(
            classes.appPanelContent,
            classes.content,
            classes.enrolmentPanel
          )}
        >
          <PhotoDetails
            faces={faces}
            uploadFile={handleUploadFile}
            addEnrolmentsToProfile={addEnrolmentsToProfile}
            selectedProfile={activeProfile}
          />
        </div>
      </div>
    );
  }
};
