import {
  createStyles,
  Grid,
  Button,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CircleFaceType } from "../../../ImagusComponents/Gadgets/ImagusFacePicker";
import { panelStyles } from "../../styles/generalStyles";
import { iconStyles } from "../../styles/iconStyles";
import CheckIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import classNames from "classnames";
import { buttonStyles } from "../../styles/buttonStyles";
import ImageUploadProcess from "../faceSearch/ImageUploadProcess";
import { setErrorMessage } from "../ErrorDisplay";
import { EnrolmentInputType } from "../../types/faceSearchTypes";
import { RunnerType } from "../../reducers/profilesReducers";
import FaceUpload from "../faceSearch/FaceUpload";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...panelStyles(theme),
    ...iconStyles(theme),
    ...buttonStyles(theme),
    title: {
      fontWeight: 600,
    },

    leftMargin: {
      marginLeft: "10px",
    },

    rightMargin: {
      marginRight: "10px",
    },
    content: {
      marginLeft: "10px",
      fontSize: "0.875rem",
      lineHeight: 1.8,
    },

    photoPanel: {
      backgroundColor: theme.palette.contentBackgroundColor,
      height: "185px",
      borderRadius: "25px",
      width: "50%",
    },

    approveIcon: {
      color: "#039487",
      // backgroundColor: "#039487",
    },

    denyIcon: {
      color: "#FF0000",
    },
  })
);

type PhotoDetailsPropsType = {
  faces: Array<CircleFaceType>;
  uploadFile: Function;
  addEnrolmentsToProfile: Function;
  handleDeleteDialog?: Function;
  handleEditButtonClick?: Function;
  selectedProfile: RunnerType;
  allowRemoveProfile?: boolean;
};

export const PhotoDetails = (props: PhotoDetailsPropsType) => {
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(false);
  const [faceIndex, setFaceIndex] = useState(0);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [showJumpButton, setShowJumpButton] = useState(false);
  /** used to skip first render */
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count) {
      checkDetails();
    } else {
      setCount(1);
    }
  }, [props.faces, faceIndex, props.selectedProfile]);

  const addEnrolments = (
    enrolment: EnrolmentInputType,
    doneBack?: Function,
    failBack?: Function
  ) => {
    props.addEnrolmentsToProfile(
      [enrolment],
      () => {
        doneBack && doneBack();
      },
      addEnrolmentFail
    );
  };

  const addEnrolmentFail = (error: Object) => {
    const errors = {} as Record<string, string>;
    if (props.selectedProfile.uid === "") {
      setErrors(
        setErrorMessage(
          errors,
          "image",
          Object.keys(error).length > 0
            ? "Please create a profile before submit photos"
            : ""
        )
      );
    } else {
      setErrors(
        setErrorMessage(
          errors,
          "image",
          Object.keys(error).length > 0
            ? "Photo has been previously enrolled"
            : ""
        )
      );
    }
    setErrors(errors);
  };

  const handleRemoveButtonClick = () => {
    props.handleDeleteDialog && props.handleDeleteDialog(true);
  };

  const handleEditButtonClick = () => {
    props.handleEditButtonClick && props.handleEditButtonClick(true);
  };

  const passThreshold: JSX.Element = (
    <div>
      <CheckIcon className={classNames(classes.approveIcon)} fontSize="large" />
      <Typography
        variant="body1"
        display="inline"
        className={classes.leftMargin}
      >
        Passed
      </Typography>
    </div>
  );

  const underThreshold = (message: string): JSX.Element => {
    return (
      <div>
        <CloseIcon className={classNames(classes.denyIcon)} fontSize="large" />
        <Typography
          variant="body1"
          display="inline"
          className={classes.leftMargin}
        >
          {message}
        </Typography>
      </div>
    );
  };

  const handleFaceChange = (index: number) => {
    setFaceIndex(index);
  };
  const handleDisplayDetails = (status: boolean) => {
    setShowDetails(status);
  };

  const roundFloat = (point: number): number => {
    return Math.round((point + Number.EPSILON) * 100) / 100;
  };

  const passFrontalCheck = (face: CircleFaceType) => {
    return face && face.resultMetrics.matchFrontalThreshold >= 100;
  };

  const passBlurrinessCheck = (face: CircleFaceType) => {
    return face && face.resultMetrics.matchBlurrinessThreshold >= 100;
  };

  const passConfidenceCheck = (face: CircleFaceType) => {
    return face && face.resultMetrics.matchConfidenceThreshold >= 100;
  };

  const passPersonCheck = (face: CircleFaceType) => {
    const exist = face.search && face.search.probability > 0;

    if (exist) {
      if (face.search.profileId !== props.selectedProfile.uid) {
        return false;
      }
    } else {
      if (props.selectedProfile.enrolments.length > 0) {
        return false;
      }
    }

    return true;
  };

  const checkDetails = () => {
    console.log("checking.....");
    const errors = {} as Record<string, string>;
    if (props.faces.length === 0) {
      setErrors(
        setErrorMessage(
          errors,
          "image",
          props.faces.length > 0 ? "" : "Cannot detect face"
        )
      );
    } else {
      //clear previous errors
      setErrors(
        setErrorMessage(
          errors,
          "image",
          props.faces.length > 0 ? "" : "No face detected"
        )
      );

      setErrors(
        setErrorMessage(
          errors,
          "frontal",
          passFrontalCheck(props.faces[faceIndex]) ? "" : "Frontal Error"
        )
      );
      setErrors(
        setErrorMessage(
          errors,
          "blurriness",
          passBlurrinessCheck(props.faces[faceIndex]) ? "" : "Image too blurry"
        )
      );

      setErrorMessage(
        errors,
        "confidence",
        passConfidenceCheck(props.faces[faceIndex])
          ? ""
          : "Bad contrast or bad lighting"
      );

      setErrorMessage(
        errors,
        "person",
        passPersonCheck(props.faces[faceIndex]) ? "" : "Person Error"
      );

      setErrors(errors);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={5}>
        <ImageUploadProcess
          permanent={true}
          detectedFaces={props.faces}
          uploadFile={props.uploadFile}
          disabledDialog
          setFaceIndex={handleFaceChange}
          setShowDetails={handleDisplayDetails}
          enrolFace={addEnrolments}
          errors={errors}
          profileName={props.selectedProfile.name}
        />
      </Grid>

      {showDetails && props.faces.length > 0 && (
        <Grid item xs={12} md={3} container spacing={1}>
          <Grid item xs={12} md={9}>
            <Typography
              variant="subtitle1"
              display="inline"
              className={classes.title}
            >
              Number of faces detected:
            </Typography>
            <Typography
              variant="body2"
              display="inline"
              className={classes.leftMargin}
            >
              {props.faces.length}
            </Typography>
          </Grid>
          {/** Eyes location */}
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              display="inline"
              className={classes.title}
            >
              Eye Locations:
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  display="inline"
                  className={classes.leftMargin}
                >
                  Left Eye Coordinate:{" "}
                  {roundFloat(props.faces[faceIndex].eyes.left.x)},{" "}
                  {roundFloat(props.faces[faceIndex].eyes.left.y)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  display="inline"
                  className={classes.leftMargin}
                >
                  Right Eye Coordinate:{" "}
                  {roundFloat(props.faces[faceIndex].eyes.right.x)},{" "}
                  {roundFloat(props.faces[faceIndex].eyes.right.y)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/** Check for bluriness */}
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              display="inline"
              className={classes.title}
            >
              Bluriness Check:
            </Typography>
            {passBlurrinessCheck(props.faces[faceIndex])
              ? passThreshold
              : underThreshold("Face too blurry")}
          </Grid>

          {/** Confidence */}
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              display="inline"
              className={classes.title}
            >
              Confidence Check:
            </Typography>
            {passConfidenceCheck(props.faces[faceIndex])
              ? passThreshold
              : underThreshold("Bad lighting or bad contrast")}
          </Grid>
          {/** Frontal */}
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              display="inline"
              className={classes.title}
            >
              Frontal Check:
            </Typography>
            {passFrontalCheck(props.faces[faceIndex])
              ? passThreshold
              : underThreshold("Non-frontal face detected")}
          </Grid>
          {/** Person Check */}
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              display="inline"
              className={classes.title}
            >
              Person Check:
            </Typography>
            {passPersonCheck(props.faces[faceIndex])
              ? passThreshold
              : underThreshold("Face might not belong to this profile")}
          </Grid>
        </Grid>
      )}
      {(!showDetails || props.faces.length === 0) && (
        <Grid item xs={12} md={3} />
      )}
      {props.allowRemoveProfile && (
        <Grid item xs={12} md={4} container justify="flex-end">
          <Grid item>
            <Button
              className={classNames(classes.darkBtn, classes.rightMargin)}
              onClick={handleEditButtonClick}
            >
              {" "}
              <EditIcon />
              Edit profile name
            </Button>
            <Button
              className={classNames(classes.darkBtn)}
              onClick={handleRemoveButtonClick}
            >
              {" "}
              <DeleteIcon />
              Remove Profile
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
