import { createStyles, Theme } from "@material-ui/core";
import {
  CreateCSSProperties,
  CSSProperties,
  PropsFunc,
  withStyles,
} from "@material-ui/styles";
// import { CSSProperties } from "@material-ui/core/styles/withStyles";

const drawerWidth = 200;

export const contentStyles = (theme: Theme) =>
  createStyles({
    appHeader: {
      // width: `calc(100% - ${drawerWidth}px)`,
      width: "100%",
      padding: "1rem 2rem",
      backgroundColor: theme.palette.background.default,
      borderBottom: "1px solid rgba(63, 63, 66, 0.2)",
    },
    root: {
      width: "100%",
      height: "100%",
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
      display: "block",
      margin: "10px auto",
    },
  });

export const navBarStyles = (theme: Theme): any => {
  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },

    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
  };
};

export const panelStyles = (theme: Theme) =>
  createStyles({
    appWrapper: {
      display: "block",
      margin: "0 auto",
      width: "100%",
      // height: "100%",
    },
    appMain: {
      width: "100%",
      height: "100%",
    },
    appPanel: {
      minHeight: "400px",
      background: theme.palette.common.white,
      border: "none",
      clear: "both",
      display: "block",
      margin: "24px 0 0",
      padding: 0,
      position: "relative",
      width: "100%",
    },
    appPanelContent: {
      display: "block",
      width: "100%",
      height: "auto",
      // margin: "15px",
    },
    appPanelTop: {
      marginTop: "25px",
    },
    "@media screen and (max-width:959px)": {
      appMain: {
        marginTop: "65px",
      },
    },
  });

export const headerStyles = (theme: Theme) =>
  createStyles({
    appHeader: {
      borderBottom: "1px solid " + theme.palette.primary.main,
      padding: "24px 10px",
    },
    headerWrapper: {
      height: "32px",
      alignItems: "center",
      display: "inline-flex",
      justifyContent: "space-between",
      width: "100%",
    },
    headerTitle: {
      fontSize: "1.5rem",
      fontStyle: "inherit",
      fontWeight: "bold",
      letterSpacing: "-0.01em",
      lineHeight: "32px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    actionItem: {
      flex: "1 0 auto",
    },
  });

export const inputStyles = (theme: Theme) =>
  createStyles({
    labelFormControl: {
      fontSize: "1.0625rem",
      fontWeight: 500,
      padding: "10px 5px",
    },
    label: {
      color: theme.palette.primary.light,
      "&$labelFocused": {
        color: theme.palette.primary.main,
      },
      "&$labelError": {
        color: theme.palette.error.main,
      },
      "&$labelDisabled": {
        color: theme.palette.grey[100],
      },
    },
    labelFocused: {},
    labelError: {},
    labelDisabled: {},

    inputFont: {
      fontSize: "0.9375rem",
      paddingLeft: "20px",
    },

    inputRoot: {
      padding: 0,
      marginBottom: theme.spacing(2),
      "label + &": {
        marginTop: theme.spacing(4),
      },
    },
    inputDisabled: {},
    inputError: {},

    underline: {
      "&:before": {
        borderBottomColor: theme.palette.primary.main,
      },
      "&:hover:before": {
        borderBottomColor: theme.palette.grey[500] + " !IMPORTANT",
      },
      "&:after": {
        borderBottomColor: theme.palette.primary.main,
      },
    },
    borderError: {
      borderColor: theme.palette.error.main,
      "&:focus": {
        borderColor: theme.palette.error.main,
        boxShadow: "0 0 0 0.1rem " + theme.palette.error.main,
      },
    },
    helper: {
      paddingLeft: "20px",
    },
    textFieldFormLabel: {
      fontSize: "1.0625rem",
      fontWeight: 500,
      padding: "10px 5px",
    },

    textFieldInput: {
      borderRadius: 4,
      background: theme.palette.common.white,
      border: "1px solid #ced4da",
      fontSize: "1rem",
      padding: "10px 12px",
      width: "calc(100% - 24px)",
      minWidth: theme.spacing(5),
      transition: "box-shadow 0.25s ease-in-out",
      "&:focus": {
        borderColor: theme.palette.primary.light,
        boxShadow: "0 0 0 0.2rem " + theme.palette.primary.dark,
      },
    },
  });

export const bannerStyles = (theme: Theme) =>
  createStyles({
    banner: {
      width: "calc(100% - 32px)",
      height: "auto",
      margin: "16px",
      "&$error": {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
      },
      "&$warning": {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.common.white,
      },
      "&$info": {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.common.black,
      },
    },
    error: {},
    warning: {},
    info: {},
    box: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      transition: "color 0.25s ease-in-out",
      whiteSpace: "pre-wrap",
      padding: "16px",
      "& $content": {
        color: "inherit",
        fontSize: "0.9375rem",
        fontWeight: "500",
        lineHeight: "1.5em",
      },
    },
    content: {},
    "@keyframes blinker": {
      "0%": {
        opacity: 1.0,
      },
      "50%": {
        opacity: 0.0,
      },
      "100%": {
        opacity: 1.0,
      },
    },
  });

const ToolBarHeight = 80;
const DrawWidth = 300;
const DrawCloseWidth = 64;
const DrawWideWidth = 520;
const DrawMidWidth = 320;

export const drawerStyles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "100%",
      zIndex: 1,
      overflow: "hidden",
      position: "relative",
      display: "flex",
    },
    subRoot: {
      // height: `calc(100vh - ${ToolBarHeight + 1}px)`,
      height: "100%",
    },
    drawerPaper: {
      position: "relative",
      height: `calc(100vh - ${ToolBarHeight + 30}px)`,
      width: DrawWidth,
      overflowX: "hidden",
      zIndex: 1800,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: DrawCloseWidth,
    },

    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      height: ToolBarHeight,
      // position: "absolute",
      width: "inherit",
      borderBottom: "1px solid " + theme.palette.grey[300],
      zIndex: 1200,
      backgroundColor: theme.palette.common.white,
    },

    toolbarSearch: {
      display: "inherit",
      width: "inherit",
    },
    toolbarInside: {
      width: DrawWidth - 2,
      marginLeft: 1,
      marginRight: 1,
    },

    list: {
      // marginTop: ToolBarHeight,
      zIndex: 1000,
      overflowY: "auto",
      overflowX: "hidden",
      height: "100%",
    },

    //content of the page
    content: {
      // height: "inherit",
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
      // marginTop: ToolBarHeight,
      display: "block",
    },
  });

export const tableStyles = (theme: Theme): any => {
  return {
    appTable: {
      overflow: "auto",
      width: "100%",
      "& tr": {
        "&$active": {
          background: "#e0e0e0",
          color: "rgba(0,0,0,.87)",
        },
      },
      "&$noBorder": {
        border: "none",
        "& tr, & th, & td": {
          border: "none",
        },
      },
      "&$lightBorder": {
        border: "1px solid " + theme.palette.grey[300],
        "& tr, & th, & td": {
          border: "1px solid " + theme.palette.grey[300],
        },
      },
      "&$innerBorder": {
        borderCollapse: "collapse",
        borderStyle: "hidden",
        "& tr": {
          border: "1px solid " + theme.palette.grey[300],
        },
      },
      "&$center": {
        "& th, & td": {
          textAlign: "center",
        },
      },
      "&$colorTop": {
        borderTop: ".2em solid " + theme.palette.primary.light,
      },
      "& $border": {
        fontSize: "0.875rem",
        fontWeight: 600,
      },
      "& $normal": {
        fontSize: "0.875rem",
      },
      "& $compact": {
        padding: "5px",
      },
      "& $short": {
        paddingLeft: "5px",
      },
      "& $slim": {
        height: "32px",
      },
      "& $top": {
        verticalAlign: "top",
      },
      "& $light": {
        color: theme.palette.grey[300],
      },
      "& $inlineDivider": {
        borderTop: "1px solid " + theme.palette.primary.light,
        height: "24px",
      },
    },
    noBorder: {},
    lightBorder: {},
    innerBorder: {},
    center: {},
    colorTop: {},
    active: {},
    border: {},
    normal: {},
    compact: {},
    short: {},
    slim: {},
    top: {},
    light: {},
    inlineDivider: {},
    // blueShine: {},
    "@media screen and (max-width:959px)": {
      appTable: {
        "& tr": {
          width: "auto !important",
          display: "block !important",
          height: "auto !important",
        },
        "& th, & td": {
          width: "auto !important",
          display: "block !important",
          minHeight: "30px",
        },
      },
    },
  };
};

export const colorLabel = () => {
  return {
    borderRadius: "2em",
    display: "inline-block",
    padding: "2px 12px",
  };
};

export const formText:
  | CSSProperties
  | CreateCSSProperties<{}>
  | PropsFunc<{}, CreateCSSProperties<{}>> = {
  width: "100%",
  textAlign: "center",
};

export const formRightBtnBlock:
  | CSSProperties
  | CreateCSSProperties<{}>
  | PropsFunc<{}, CreateCSSProperties<{}>> = {
  width: "auto",
  display: "flex",
  justifyContent: "flex-end",
  margin: "0 2% 0 2%",
};
