import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../Store";
import { createProfile, updateProfile } from "../../actions/profilesActions";
import { CreateProfileForm } from "../../components/profiles/CreateProfileForm";
import { RunnerType } from "../../reducers/profilesReducers";

type CreateProfileFormReduxPropsType = {
  onClose: Function;
  userId: string;
  edit?: boolean;
  currentProfile: RunnerType;
};

export const CreateProfileFormRedux = (
  props: CreateProfileFormReduxPropsType
) => {
  const userId = props.userId;
  const dispatch = useDispatch();
  const handleCreateProfile = (
    name: string,
    doneBack?: Function,
    failBack?: Function
  ) => {
    createProfile(name, userId!, doneBack, failBack)(dispatch);
  };

  const handleEditProfile = (
    newName: string,
    doneBack?: Function,
    failBack?: Function
  ) => {
    updateProfile(
      props.currentProfile.uid,
      newName,
      [],
      [],
      undefined,
      doneBack,
      failBack
    )(dispatch);
  };

  return (
    <CreateProfileForm
      edit={props.edit}
      onClose={props.onClose}
      createProfile={handleCreateProfile}
      editName={handleEditProfile}
      currentName={props.currentProfile.name}
    />
  );
};
