import classNames from "classnames";
import chroma from "chroma-js";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { buttonStyles } from "../../styles/buttonStyles";
import { iconStyles } from "../../styles/iconStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...buttonStyles(theme),
    ...iconStyles(theme),
    cameraButton: {
      //Mui-Button style (in used because MuiButton do not work with label)
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      lineHeight: 1.75,

      //custom styling

      height: "270px",
      color: theme.palette.grey[500],
      textTransform: "capitalize",
      padding: "22px 0",
      borderRadius: "3px",
      boxShadow: "0 0 0 1px " + theme.palette.primary.main,
      textAlign: "center",
      width: "100%",
      backgroundColor: theme.palette.contentBackgroundColor,
      "&:hover, &.hover, &:active, &.active": {
        cursor: "pointer",
        backgroundColor: theme.palette.primary.light,
        color:
          chroma.contrast(theme.palette.primary.light, "white") > 2
            ? "white"
            : "black",
      },
      "& > SPAN": {
        display: "block",
      },
    },
    disabled: {
      backgroundColor: theme.palette.grey[200],
      "&:hover, &.hover, &:active, &.active": {
        cursor: "not-allowed",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[500],
      },
      "& > SPAN": {
        display: "block",
      },
    },
    buttonText: {
      fontSize: "1.5rem",
      lineHeight: "30px",
    },
    hiddenItem: {
      opacity: 0,
      width: "0.1px",
      "&:hover, &.hover": {
        cursor: "pointer",
      },
    },
  })
);

type CameraLabelButtonPropsType = {
  enableCamera: Function;
  disabled?: boolean;
};

export const CameraLabelButton = (props: CameraLabelButtonPropsType) => {
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent) => {
    if (!props.disabled) {
      e.preventDefault();
      e.stopPropagation();

      props.enableCamera();
    }
  };

  return (
    <div>
      <label htmlFor="camera-button" onClick={handleClick}>
        <div
          className={classNames(
            classes.primaryBtn,
            classes.cameraButton,
            props.disabled && classes.disabled
          )}
        >
          <span className={classes.buttonText}>
            Click To <br /> Open Webcam
          </span>
          <br />
          <PhotoCameraIcon className={classes.largeIcon} />
        </div>
      </label>
      <input
        id="camera-button"
        disabled={props.disabled}
        className={classes.hiddenItem}
        type="button"
      />
    </div>
  );
};
