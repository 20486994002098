import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Link,
} from "@material-ui/core";
import classNames from "classnames";
import React, { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../Store";
import { userDelete } from "../../actions/userActions";
import { DeleteUserRedux } from "../../container/user/DeleteUserRedux";
import { buttonStyles } from "../../styles/buttonStyles";
import {
  contentStyles,
  panelStyles,
  headerStyles,
} from "../../styles/generalStyles";
import { SingleCheckBox } from "../formInputs/SingleCheckBox";
import { ModalForm } from "../gadgets/ModalForm";
import { TermsAndConditions } from "../TermsAndConditions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...contentStyles(theme),
    ...panelStyles(theme),
    ...headerStyles(theme),
    ...buttonStyles(theme),
    contentTitleText: {
      fontSize: "1rem",
      fontStyle: "inherit",
      fontWeight: 500,
      letterSpacing: "-0.01em",
      lineHeight: "32px",
    },
    root: {
      color: theme.palette.primary.main,
    },
    invalid: {
      color: theme.palette.error.main,
    },

    contentPanelBorder: {
      marginTop: "15px",
      border: "1px solid black",
      borderRadius: "25px",
      width: "auto",
      backgroundColor: theme.palette.contentBackgroundColor,
    },

    centralPanel: {
      margin: " 15px auto",
      width: "50%",
    },
    settingBox: {
      padding: "15px 15px",
    },
  })
);

type ProfileSettingPropsType = {};

export const ProfileSetting = (props: ProfileSettingPropsType) => {
  const classes = useStyles();
  const [agreed, setAgreement] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const userInfo = useSelector((state: AppState) => state.global.user.info);

  const handleTickBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAgreement(e.target.checked);
  };

  const handleLinkClick = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleDeleteClicked = () => {
    setOpenConfirm(true);
  };

  const handleCancel = () => {
    setOpenConfirm(false);
  };

  const handleConfirmDelete = () => {
    userInfo && userDelete(userInfo.id);
  };

  return (
    <div className={classNames(classes.appMain, classes.centralPanel)}>
      <Typography className={classes.headerTitle}>Setting</Typography>
      <div className={classes.appPanel}>
        <div className={classes.contentPanelBorder}>
          <div
            className={classNames(classes.appPanelContent, classes.settingBox)}
          >
            <Typography className={classes.contentTitleText}>
              Terms and Conditions
            </Typography>
            <SingleCheckBox
              name="agreed"
              checked={agreed}
              onChange={handleTickBoxChange}
              label="I have read and agreed to the "
              linkAtTheEnd="Term of User and Privacy Act"
              handleLinkClicked={handleLinkClick}
            />
            <Typography className={classes.contentTitleText}>
              Delete Your Account
            </Typography>
            <Typography variant="subtitle1">
              This will delete your account forever
            </Typography>
            <Link href="#" underline="always" onClick={handleDeleteClicked}>
              Proceed to delete
            </Link>
            <DeleteUserRedux
              open={openConfirm}
              handleCancle={handleCancel}
              handleConfirm={handleConfirmDelete}
            />
            <ModalForm
              title="Term and Condition"
              open={openModal}
              handleModalStatus={handleModalClose}
              form={<TermsAndConditions />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
