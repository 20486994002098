import {
  Coordinate,
  ImageType,
  PairCoordinateType,
  PersonType,
} from "../types/faceSearchTypes";

export const cropImageToFace = (img: HTMLCanvasElement, person: PersonType) => {
  let canvas: HTMLCanvasElement = document.createElement("canvas");
  let radius = 0;

  if (person.radius) {
    radius = person.radius;
  } else if (person.size) {
    radius = person.size / 2;
  }
  canvas.width = radius * 2;
  canvas.height = radius * 2;

  let ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.drawImage(
      img,
      person.centre.x - radius,
      person.centre.y - radius,
      radius * 2,
      radius * 2,
      0,
      0,
      radius * 2,
      radius * 2
    );
    let image = new Image();
    image.id = "pic";
    image.src = canvas.toDataURL();
    return image;
  }
  return null;
};

export const distanceBetweenEyes = (eyes: PairCoordinateType): number => {
  const x = Math.abs(eyes.left.x - eyes.right.x);
  const y = Math.abs(eyes.left.y - eyes.right.y);

  return Math.sqrt(x * x + y * y);
};

export const radiusFromEyes = (eyes: PairCoordinateType): number => {
  const betweenEyes = distanceBetweenEyes(eyes);
  return betweenEyes + betweenEyes / 2;
};

export const faceCentreFromEyes = (eyes: PairCoordinateType): Coordinate => {
  const x1 = eyes.left.x;
  const y1 = eyes.left.y; // First diagonal point
  const x2 = eyes.right.x;
  const y2 = eyes.right.y; // Second diagonal point

  const xc = (x1 + x2) / 2;
  const yc = (y1 + y2) / 2; // Center point
  const xd = (x1 - x2) / 2;
  const yd = (y1 - y2) / 2; // Half-diagonal

  const x3 = xc + yd;
  const y3 = yc - xd;

  // return th corner of square
  return {
    x: x3,
    y: y3,
  };
};

export const rotationFromEyes = (eyes: PairCoordinateType): number => {
  const run = eyes.left.x - eyes.right.x;
  const rise = eyes.left.y - eyes.right.y;

  return Math.atan2(rise, run) + Math.PI;
};

export const relativeEyesFromFace = (radius: number): PairCoordinateType => {
  const centreOffset = radius / 3;

  return {
    left: {
      x: -centreOffset,
      y: -centreOffset,
    },
    right: {
      x: centreOffset,
      y: -centreOffset,
    },
  };
};

export const toDegrees = (angle: number): number => {
  return angle * (180 / Math.PI);
};

export const toRadians = (angle: number): number => {
  return angle * (Math.PI / 180);
};

export const rotate = (
  center: Coordinate,
  rotatee: Coordinate,
  rads: number
): Coordinate => {
  let rot: Coordinate = { x: 0, y: 0 };
  const cosA = Math.cos(rads);
  const sinA = Math.sin(rads);

  rot.x =
    center.x + cosA * (rotatee.x - center.x) - sinA * (rotatee.y - center.y);
  rot.y =
    center.y + sinA * (rotatee.x - center.x) + cosA * (rotatee.y - center.y);
  return rot;
};

export const getEyes = (
  center: Coordinate,
  size: number,
  angle: number
): PairCoordinateType => {
  // console.log(centre, size, angle)

  let eyes: PairCoordinateType = {
    left: { x: 0, y: 0 },
    right: { x: 0, y: 0 },
  };
  // set eye pos assuming angle of zero...
  eyes.left.x = center.x - size / 6.0;
  eyes.right.x = center.x + size / 6.0;
  eyes.left.y = center.y - size / 6.0;
  eyes.right.y = center.y - size / 6.0;

  const rads = (angle * Math.PI) / 180.0;

  eyes.left = rotate(center, eyes.left, rads);
  eyes.right = rotate(center, eyes.right, rads);

  return eyes;
};
