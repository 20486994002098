import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import classNames from "classnames";
import { buttonStyles } from "../../styles/buttonStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...buttonStyles(theme),
    fullWidth: {
      width: "100%",
    },
    autoWidth: {
      width: "auto",
    },
  })
);

type SubmitButtonPropsType = {
  loading?: boolean;
  disabled?: boolean;
  title?: string;
  fullWidth?: boolean;
  isMainButton?: boolean;
  id?: string;
  onClick?: Function;
};

export const SubmitButton = (props: SubmitButtonPropsType) => {
  const classes = useStyles();

  const handleSubmit = () => {
    props.onClick && props.onClick();
  };

  if (props.loading) {
    return (
      <Button
        type={props.onClick ? "button" : "submit"}
        variant="contained"
        color="default"
        id={props.id ? props.id : "submitBtn"}
        className={classNames(
          classes.disabledBtn,
          props.fullWidth && classes.fullWidth,
          !props.fullWidth && classes.autoWidth
        )}
        disabled={true}
      >
        <CircularProgress
          size={14}
          color="inherit"
          style={{ marginRight: "5px" }}
        />
        Sending...
      </Button>
    );
  } else {
    return (
      <Button
        type={props.onClick ? "button" : "submit"}
        variant="contained"
        color="primary"
        id={props.id ? props.id : "submitBtn"}
        className={classNames(
          props.isMainButton && classes.primaryBtn,
          !props.isMainButton && classes.darkBtn,
          props.fullWidth && classes.fullWidth,
          !props.fullWidth && classes.autoWidth
        )}
        disabled={props.disabled ? true : false}
        // {...(props.onClick ? { onClick: props.onClick } : {})}
        onClick={handleSubmit}
      >
        {props.title ? props.title : "Submit"}
      </Button>
    );
  }
};
