import { createStyles, makeStyles, Theme } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import classNames from "classnames";
import { bannerStyles } from "../styles/generalStyles";
import { iconStyles } from "../styles/iconStyles";

export const setErrorMessage = (
  errors: Record<string, string>,
  errorType: string,
  errorMessage: string
): Record<string, string> => {
  if (!errorMessage || errorMessage === "") {
    delete errors[errorType];
  } else {
    errors[errorType] = errorMessage;
  }

  return errors;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...bannerStyles(theme),
    ...iconStyles(theme),
    rightMargin: {
      marginRight: "5px",
    },
  })
);

type ErrorDisplayPropsType = {
  errors: Record<string, string>;
};

export const ErrorDisplay = (props: ErrorDisplayPropsType) => {
  const classes = useStyles();
  let generalErrors: Array<JSX.Element> = [];
  Object.keys(props.errors).forEach((key: string) => {
    if (props.errors[key]) {
      generalErrors.push(
        <span key={"general_error_" + key}>
          {props.errors[key]}
          <br />
        </span>
      );
    }
  });

  if (Object.keys(props.errors).length > 0) {
    return (
      <div className={classNames(classes.banner, classes.error)}>
        <div className={classes.box}>
          <ErrorIcon
            className={classNames(classes.inheritIcon, classes.rightMargin)}
          />
          <span className={classes.content}>{generalErrors}</span>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
