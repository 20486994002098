import { userLogout } from "./userActions";

export const handleResponse = (
  response: Response,
  responseType: "HTML" | "JSON" | "TEXT" | "BUFFER" | "IMAGE" | "EMPTY" = "JSON"
): any => {
  //successfully get a response
  if (response.status === 200) {
    if (responseType === "IMAGE") {
      return response.blob().then((blob: Blob): string => {
        let objUrl = URL.createObjectURL(blob);
        return objUrl;
      });
    } else if (responseType === "JSON") {
      return response.json().then((json: JSON) => {
        return json;
      });
    } else if (responseType === "TEXT") {
      return response.body;
    } else if (responseType === "HTML") {
      return response.text();
    } else if (responseType === "EMPTY") {
      return;
    } else {
      return Promise.reject({
        api: "Wrong Response Type",
      });
    }
  }
  //unauthorised request detected by the api
  else if (response.status === 401) {
    //auto logout
    userLogout();
  }
  //content not found
  else if (response.status === 400 || response.status === 404) {
    return response.json().then((json) => {
      return Promise.reject(json.errors);
    });
  }
  //all others responses
  else
    return Promise.reject({ api: response.status + " " + response.statusText });
};

// export const handleResponse = (response: Response, responseType: "HTML") => {
//   return response.text();
// };
