import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Theme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileAvatar } from "../../../admin/actions/participantsActions";
import { AppState } from "../../../Store";
import { RunnerType } from "../../reducers/profilesReducers";
import { Race } from "../../reducers/raceReducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    raceSelected: {
      fontWeight: 800,
    },

    title: {
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      padding: "0 16px",
    },

    selectedTile: {
      backgroundColor: "#787c7f38",
    },
  })
);

type RaceItemPropsType = {
  race: RunnerType;
  selectedRace: string;
  selectRace: Function;
  sideComponent?: JSX.Element;
};

export const RaceItem = (props: RaceItemPropsType) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.race.enrolments.length > 0) {
      getProfileAvatar(props.race.uid)(dispatch);
    }
  }, [props.race]);

  return (
    <ListItem
      button
      onClick={() => props.selectRace(props.race.uid)}
      className={classNames(
        props.selectedRace === props.race.uid && classes.selectedTile
      )}
    >
      <ListItemAvatar>
        <Avatar src={props.race.avatar ? props.race.avatar : ""} />
      </ListItemAvatar>
      <ListItemText
        primary={props.race.name}
        disableTypography
        {...(props.sideComponent ? { secondary: props.sideComponent } : {})}
        className={classNames(
          classes.title,
          props.selectedRace === props.race.uid && classes.raceSelected
        )}
      />
    </ListItem>
  );
};
