import { SET_AUTH, SET_FETCHING_AUTH, SET_USER } from "../actions/actionNames";
import { UserActionTypes } from "../types/userTypes";

export interface InfoType {
  id: string;
  name: string;
  email: string;
  roles:Array<string>;
}

export interface UserState {
  /**Information regarding the username */
  info: InfoType | null;
  /** to prevent exessive api calls */
  fetchingAuth: boolean | null;
  /**authentication status */
  auth: boolean | null;
}

const initialUserState: UserState = {
  info: null,
  fetchingAuth: null,
  auth: false,
};

export const userReducers = (
  state = initialUserState,
  actions: UserActionTypes
) => {
  switch (actions.type) {
    case SET_FETCHING_AUTH:
      if (actions.status !== null) {
        return {
          ...state,
          fetchingAuth: actions.status,
        };
      }
      return state;

    case SET_AUTH:
      if (actions.authenticated !== null) {
        return {
          ...state,
          auth: actions.authenticated,
        };
      }
      return state;

    case SET_USER:
      if (actions.user !== null) {
        return {
          ...state,
          info: actions.user,
        };
      }
      return state;

    default:
      return state;
  }
};
