export const getAuthToken = () => {
  return getStorage("token");
};

export const getStorage = (name: string): string | null => {
  return localStorage.getItem(name);
};

export const setStorage = (name: string, value: string): void => {
  localStorage.setItem(name, value);
};

export const removeStorage = (name: string) => {
  localStorage.removeItem(name);
};

export const setCookie = (cookieName: string, value: string): boolean => {
  document.cookie = cookieName + "=" + value + ";path=/";
  return true;
};

export const removeCookie = (cookieName: string): boolean => {
  document.cookie =
    cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  return true;
};
