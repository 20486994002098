import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import {
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import { drawerStyles, headerStyles } from "../../styles/generalStyles";
import { iconStyles } from "../../styles/iconStyles";

import { EnrolmentType, RunnerType } from "../../reducers/profilesReducers";
import { ModalForm } from "../gadgets/ModalForm";
import { PermanentPhotoFormRedux } from "../../container/profiles/PermanentPhotoFormRedux";
import { EnrolmentInputType } from "../../types/faceSearchTypes";

import { ImagusRaceDrawer } from "../../../ImagusComponents/Gadgets/ImagusRaceDrawer";
import classNames from "classnames";
import { FaceSearchState } from "../../reducers/faceSearchReducers";
import { useFetchImageForRace } from "../../helperFunctions/useFetchImagesForRace";
import { updateProfile } from "../../actions/profilesActions";
import { PhotoDetails } from "../profiles/PhotoDetails";
import { PermanentPhotos } from "../profiles/PermanentPhotos";
import { useFetchImageForEnrolments } from "../../helperFunctions/useFetchImageForEnrolments";
import { ConfirmationDialog } from "../gadgets/ConfirmationDialog";
import { CreateProfileFormRedux } from "../../container/profiles/CreateProfileFormRedux";
import { InfoType } from "../../reducers/userReducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...drawerStyles(theme),
    ...iconStyles(theme),
    ...headerStyles(theme),
    hide: {
      display: "none",
    },

    title: {
      fontWeight: 600,
    },
  })
);

export type RacePageLayoutPropsType = {
  user: InfoType;
  raceList: Array<RunnerType> | null;
  fetchRaces: Function;
  enrolments: Array<EnrolmentType>;
  updateProfile: Function;
  updateRaceEnrolments: Function;
  getCurrentEnrolments: Function;
  getRaceDetails: Function;
  removeProfiles: Function;
  faceSearch: FaceSearchState;
  uploadFile: Function;
  permanentEnrolments?: Array<EnrolmentType>;
  profileName?: string;
};

export const RacePageLayout = (props: RacePageLayoutPropsType) => {
  const classes = useStyles();

  const [selectedRaceId, setSelectedRaceId] = useState("");
  const [selectedRace, setSelectedRace] = useState<RunnerType>();

  const [raceIndex, setRaceIndex] = useState<number>();
  const [modal, setModal] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

  const handleRaceSelect = (raceId: string) => {
    setSelectedRaceId(raceId);
    let race: RunnerType | undefined;
    if (props.raceList) {
      race = props.raceList.find((race) => race.uid === raceId);
      setRaceIndex(props.raceList.findIndex((race) => race.uid === raceId));
    }

    setSelectedRace(race);
  };

  const handleDeleteDialog = (status: boolean) => {
    setConfirmDialogOpen(status);
  };

  const resetSelection = () => {
    setRaceIndex(undefined);
    setSelectedRace(undefined);
    setSelectedRaceId("");
  };

  const addToProfileFail = () => {};

  const addFromPermanentList = (
    newIdList: Array<string>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    props.updateRaceEnrolments(
      selectedRaceId,
      newIdList,
      [],
      doneBack,
      failBack
    );
  };

  /** The process of adding enrolment from an item
   *  --Add enrolment to profile
   * --If success, add to the item
   * --Else check if the enrolment exist in this item
   * --If not, proceed to add to the item
   */
  const addNewEnrolments = (
    newEnrolments: Array<EnrolmentInputType>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    addEnrolmentsToProfile(newEnrolments, doneBack, failBack);
  };

  /**Add Enrolments To profile */
  const addEnrolmentsToProfile = (
    newEnrolments: Array<EnrolmentInputType>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    const currentEnrolments = props.enrolments;
    console.log(selectedRace);
    console.log(newEnrolments);
    props.updateProfile(
      selectedRaceId,
      selectedRace!.name,
      newEnrolments,
      [],
      (results: Array<string>) => {
        addToProfileDone(results);
        doneBack && doneBack();
      },
      failBack
    );
  };

  const addToProfileDone = (newEnrolments: Array<string>) => {
    //update the redux store
    // const currentEnrolmentIds = props
    //   .getCurrentEnrolments()
    //   .map((e: EnrolmentType) => e.enrolmentId);
    // //Filter out ids that were not included in the currentEnrolmentIds list
    // let newIdList: Array<string> = newEnrolments.filter(
    //   (ce) => !currentEnrolmentIds.includes(ce)
    // );
    // addEnrolmentsToItem(newIdList);
    console.log("add done");
    props.getCurrentEnrolments(selectedRaceId);
  };

  const removePhoto = (
    enrolmentsIds: Array<string>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    props.updateProfile(
      selectedRaceId,
      selectedRace!.name,
      [],
      enrolmentsIds,
      () => {
        props.getCurrentEnrolments(selectedRaceId);
        doneBack && doneBack();
      },
      failBack
    );
  };

  /** Add enrolments to item (race, hotel, ...) */
  const addEnrolmentsToItem = (
    enrolmentIdList: Array<string>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    props.updateRaceEnrolments(
      selectedRaceId,
      enrolmentIdList,
      [],
      () => {
        console.log("Add to this hotel successfull");
        props.getRaceDetails(
          selectedRaceId,
          () => {
            console.log("get race success");
          },
          (e: any) => {
            console.log(e);
          }
        );
      },
      (error: any) => {
        console.log("Add fail", error);
      }
    );
  };

  /** Remove enrolments from item (race, hotel, ...) */
  const removeEnrolmentsFromItem = (
    enrolmentsIdList: Array<string>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    props.updateRaceEnrolments(
      selectedRaceId,
      [],
      enrolmentsIdList,
      () => {
        props.getRaceDetails(selectedRaceId);
        doneBack && doneBack();
      },
      failBack
    );
  };

  const handleRemoveProfile = () => {
    props.removeProfiles([selectedRaceId], () => {
      resetSelection();
      props.fetchRaces("");
    });
  };

  const handleEditProfileName = (status: boolean) => {
    setIsProfileModalOpen(status);
  };

  const onEditProfileDone = () => {
    setIsProfileModalOpen(false);

    props.fetchRaces("", (result: Array<RunnerType>) => {
      setRaceIndex(result.findIndex((race) => race.uid === selectedRaceId));
    });
  };

  //Get a list of enrolments based on the id arrays from selected face
  let raceEnrolments: Array<EnrolmentType> = [];

  if (selectedRaceId !== "") {
    let raceIndex = props.raceList?.findIndex((r) => r.uid === selectedRaceId);
    if (raceIndex !== undefined && raceIndex > -1) {
      props.raceList &&
        props.raceList[raceIndex].enrolments.map((id) => {
          let enrolmentIndex = props.enrolments.findIndex(
            (e) => e.enrolmentId === id.enrolmentId
          );
          if (enrolmentIndex > -1) {
            raceEnrolments.push(props.enrolments[enrolmentIndex]);
          }
        });
    }
  }

  return (
    <div className={classNames(classes.root, classes.subRoot)}>
      {/** Drawer on normal screen */}
      <Hidden smDown>
        <ImagusRaceDrawer
          handleSearch={props.fetchRaces}
          raceList={props.raceList}
          handleRaceSelect={handleRaceSelect}
          selectedId={selectedRaceId}
        />
      </Hidden>

      {/**
       * To do:
       * Drawer on smaller and mobile screen */}
      {/** Main screen */}
      <main className={classes.content}>
        {props.raceList && raceIndex !== undefined && (
          // <RaceDetails
          //   races={props.raceList}
          //   selectedId={selectedRaceId}
          //   enrolments={raceEnrolments}
          //   editClicked={() => setModal(true)}
          //   // updateFromPermanents={addFromPermanentList}
          //   addEnrolment={addNewEnrolments}
          //   removeEnrolment={removeEnrolmentsFromItem}
          //   faceSearch={props.faceSearch}
          //   uploadFile={props.uploadFile}
          //   permanentEnrolments={props.permanentEnrolments}
          //   profileName={props.profileName}
          // />

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <PhotoDetails
                faces={props.faceSearch.detectedFaces}
                uploadFile={props.uploadFile}
                addEnrolmentsToProfile={addEnrolmentsToProfile}
                selectedProfile={props.raceList[raceIndex]}
                handleDeleteDialog={handleDeleteDialog}
                handleEditButtonClick={handleEditProfileName}
                allowRemoveProfile
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                display="inline"
                className={classes.title}
              >
                Photos uploaded to {props.raceList[raceIndex].name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <PermanentPhotos
                profile={props.raceList[raceIndex]}
                column={5}
                removePhoto={removePhoto}
                peoplePage
              />
            </Grid>
          </Grid>
        )}
      </main>

      <ConfirmationDialog
        open={confirmDialogOpen}
        handleCancel={() => handleDeleteDialog(false)}
        confirmFunction={handleRemoveProfile}
        itemName={selectedRace?.name}
      />

      {props.raceList && raceIndex !== undefined && (
        <ModalForm
          title="Edit Profile Name"
          open={isProfileModalOpen}
          handleModalStatus={() => setIsProfileModalOpen(false)}
          form={
            <CreateProfileFormRedux
              onClose={onEditProfileDone}
              edit
              userId={props.user.id}
              currentProfile={props.raceList[raceIndex]}
            />
          }
        />
      )}
    </div>
  );
};
