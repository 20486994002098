import { Dispatch } from "redux";
import {
  GET_ALL_EVENTS,
  GET_ALL_PARTICIPANTS,
  GET_PARTICIPANT_BY_ID,
  SET_PARTICIPANTS_AVATAR,
  SET_PARTICIPANTS_ENROLMENTS_IMAGE,
  SET_UPDATING_PARTICIPANTS,
} from "../../global/actions/actionNames";
import { handleResponse } from "../../global/actions/handleResponse";
import { RunnerType } from "../../global/reducers/profilesReducers";
import { Data, deleteBody, getInit } from "../../global/types/requestConstants";

import Properties from "../../Properties";
import { EventType } from "../types/participantsType";
const root = Properties.api_root;

export const getEventsByQuery = (
  query: string,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  dispatch({ type: SET_UPDATING_PARTICIPANTS, status: true });
  return fetch(root + "/api/v2/event/search?nameFilter=" + query, getInit())
    .then(handleResponse)
    .then(
      (result: Array<EventType>) => {
        dispatch({ type: GET_ALL_EVENTS, events: result });
        dispatch({ type: SET_UPDATING_PARTICIPANTS, status: false });
        doneBack && doneBack(result);
      },
      (error) => {
        dispatch({ type: SET_UPDATING_PARTICIPANTS, status: false });
        failBack && failBack(error);
      }
    );
};

/**Get all person ids from people database */
export const fetchAllProfiles = (
  count: number,
  nameFilter?: string,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  return fetch(
    `${root}/api/v2/people/search?rate=${count}&nameFilter=` + nameFilter,
    getInit()
  )
    .then(handleResponse)
    .then(
      (response) => {
        dispatch({ type: GET_ALL_PARTICIPANTS, participants: response });
        doneBack && doneBack(response);
      },
      (error) => {
        failBack && failBack(error);
      }
    );
};

export const fetchProfileById = (
  profileId: string,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  return fetch(`${root}/api/v2/profile/${profileId}`, getInit())
    .then(handleResponse)
    .then(
      (response) => {
        let profile: RunnerType = {
          uid: response.profileId,
          name: response.name,
          enrolments: response.enrolments ? response.enrolments : [],
          dateOfBirth: response.dateOfBirth,
        };
        console.log(response);
        dispatch({ type: GET_PARTICIPANT_BY_ID, participant: profile });
        doneBack && doneBack(profile);
      },
      (error) => {
        failBack && failBack(error);
      }
    );
};

/** get image for a profile enrolments */
export const getProfileEnrolmentsImage = (
  profileId: string,
  faceId: string,
  enrolmentId: string,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  dispatch({ type: SET_UPDATING_PARTICIPANTS, status: true });
  return fetch(`${root}/api/v2/people/enrolments/faces/${faceId}`, getInit())
    .then((response: Response) => handleResponse(response, "IMAGE"))
    .then(
      (imgUrl) => {
        dispatch({
          type: SET_PARTICIPANTS_ENROLMENTS_IMAGE,
          profileId: profileId,
          enrolmentId: enrolmentId,
          faceId: faceId,
          faceImageUrl: imgUrl,
        });
        dispatch({ type: SET_UPDATING_PARTICIPANTS, status: false });
        doneBack && doneBack(imgUrl);
      },
      (error) => {
        dispatch({ type: SET_UPDATING_PARTICIPANTS, status: false });
        failBack && failBack(error);
      }
    );
};

/** get avatar for participant */

export const getProfileAvatar = (
  profileId: string,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  return fetch(`${root}/api/v2/people/${profileId}/cover`, getInit())
    .then((response: Response) => handleResponse(response, "IMAGE"))
    .then((imageUrl) => {
      dispatch({
        type: SET_PARTICIPANTS_AVATAR,
        imageUrl: imageUrl,
        profileId: profileId,
      });
    });
};

export const downloadFiles = (
  count: number,
  nameFilter?: string,
  doneBack?: Function, failBack?: Function) => {
  return fetch(root + "/api/v1/download" + `?rate=${count}&nameFilter=` + nameFilter, getInit())
    .then((response: Response) => response.blob())
    .then(
      (result) => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `enrolments_${new Date().getDate()}${(
            "0" + new Date().getMonth()
          ).slice(-2)}${new Date().getFullYear()}.zip`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        doneBack && doneBack(result);
      },
      () => {
        console.log("error");
        failBack && failBack();
      }
    );
};
