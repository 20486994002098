import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Theme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";

import { buttonStyles } from "../../styles/buttonStyles";
import { CancelButton } from "../Buttons/CancelButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...buttonStyles(theme),
    warningIcon: {
      marginRight: "10px",
      verticalAlign: "middle",
    },
    content: {
      fontWeight: 500,
      fontSize: "1.125rem",
    },
    highlight: {
      color: theme.palette.secondary.main,
    },
  })
);

type ConfirmationDialogPropsType = {
  open: boolean;
  handleCancel: Function;
  confirmFunction: Function;
  actionWord?: string;
  itemName?: string;
  extraWord?: string;
};

export const ConfirmationDialog = (props: ConfirmationDialogPropsType) => {
  const classes = useStyles();

  const handleClose = () => {
    props.handleCancel();
  };

  const handleConfirm = () => {
    props.confirmFunction();
    handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="dialog-confirmation"
      aria-describedby="dialog-confirmation-item"
      fullWidth={true}
    >
      <DialogTitle id="confirmation-dialog-title">
        {props.actionWord ? (
          <InfoIcon color="secondary" className={classes.warningIcon} />
        ) : (
          <WarningIcon color="secondary" className={classes.warningIcon} />
        )}
        Confirm {props.actionWord}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="action-dialog-content"
          className={classes.content}
        >
          Do you want to{" "}
          <span>{props.actionWord ? props.actionWord : "delete"}</span>{" "}
          <b>{props.itemName}</b>{" "}
          {props.extraWord ? props.extraWord : "from the list"}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleClose} />
        <Button type="button" variant="contained" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
