import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from "react";
import classNames from "classnames";
import {
  Button,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ImagusWebcam } from "../../../ImagusComponents/Gadgets/ImagusWebCam";
import { DraggableUploadButton } from "../Buttons/DraggableUploadButton";

import { iconStyles } from "../../styles/iconStyles";
import { formText } from "../../styles/generalStyles";
import { buttonStyles } from "../../styles/buttonStyles";
import { ImagusSampleImages } from "../../../ImagusComponents/Gadgets/ImagusSampleImages";
import { SubmitButton } from "../Buttons/SubmitButton";
import { CameraLabelButton } from "../Buttons/CameraLabelButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...iconStyles(theme),
    ...buttonStyles(theme),
    formText,
    helpText: {
      color: theme.palette.primary.light,
      width: "100%",
      textAlign: "left",
      fontSize: "0.875rem",
      padding: "5px",
    },

    tipsText: {
      color: theme.palette.error.light,
      width: "100%",
      textAlign: "left",
      fontSize: "0.875rem",
      fontWeight: 500,
      padding: "5px",
    },

    rightMargin: {
      marginRight: "5px",
    },
  })
);

type FaceUploadPropsType = {
  uploadFile: Function;
  samples: Array<string>;
  setImageError: Function;
  disabled?: boolean;
  selectPermanent: Function;
  permanent?: boolean;
  setDisplaySubmit?: Function;
};

type FaceUploadHandle = {
  resetFaceUpload: () => void;
};

const FaceUpload: ForwardRefRenderFunction<
  FaceUploadHandle,
  FaceUploadPropsType
> = (props: FaceUploadPropsType, forwardedRef) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [panel, setPanel] = useState({
    isCamera: false,
    isSample: false,
    isFromPermanent: false,
  });

  useImperativeHandle(forwardedRef, () => ({
    resetFaceUpload() {
      resetForm();
    },
  }));

  const switchToCamera = () => {
    setPanel({ isCamera: true, isSample: false, isFromPermanent: false });
    props.setDisplaySubmit && props.setDisplaySubmit(false);
  };

  const switchToSamples = () => {
    setPanel({ isCamera: false, isSample: true, isFromPermanent: false });
    props.setDisplaySubmit && props.setDisplaySubmit(false);
  };

  const switchToPermanents = () => {
    setPanel({ isCamera: false, isSample: false, isFromPermanent: true });
    props.setDisplaySubmit && props.setDisplaySubmit(false);
  };

  const resetForm = () => {
    setPanel({ isCamera: false, isSample: false, isFromPermanent: false });
    setLoading(false);
    props.setDisplaySubmit && props.setDisplaySubmit(true);
  };

  const captureCamera = async (data: string) => {
    setLoading(true);
    let file;
    fetch(data)
      .then((res: Response) => res.blob())
      .then((blob: Blob) => {
        file = new Blob([blob], { type: "image/jpeg" });
        props.uploadFile(file, uploadDone, uploadFail);
      });
  };

  const loadSampleImages = async (imgUrl: string) => {
    setLoading(true);
    let file;
    fetch(imgUrl)
      .then((res: Response) => res.blob())
      .then((blob: Blob) => {
        //create a new blob with the url
        file = new Blob([blob], { type: "image/jpeg" });
        //upload the blob
        props.uploadFile(file, uploadDone, uploadFail);
      });
  };

  //---------handle select permanent-----------//
  const handleSelectPermanent = (imgUrl: string) => {
    // setLoading(true);
    //get the enrolmentId
    props.selectPermanent(imgUrl);
    resetForm();
  };

  //-------------------------------------------//

  const uploadDone = (canvas: HTMLCanvasElement) => {
    const img = canvas.toDataURL("image/jpeg");
    resetForm();
    //set to the next process
  };

  const uploadFail = () => {
    //setError
    setLoading(false);
  };

  let content: JSX.Element;
  if (loading) {
    content = (
      <div className={classes.formText}>
        <CircularProgress />
      </div>
    );
  } else if (panel.isCamera) {
    content = (
      <ImagusWebcam cancelCamera={resetForm} captureCamera={captureCamera} />
    );
  } else if (panel.isSample) {
    content = (
      <ImagusSampleImages
        cancelSample={resetForm}
        loadSample={loadSampleImages}
        samples={props.samples}
      />
    );
  } else if (panel.isFromPermanent) {
    content = (
      <ImagusSampleImages
        cancelSample={resetForm}
        loadSample={handleSelectPermanent}
        samples={props.samples}
      />
    );
  } else {
    content = (
      <div>
        <Grid item xs={12} container>
          <Grid item xs={6}>
            <DraggableUploadButton
              uploadFileAction={props.uploadFile}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={6}>
            {/* <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classNames(classes.darkBtn, classes.rightMargin)}
              onClick={switchToCamera}
              disabled={props.disabled}
            >
              <PhotoCameraIcon
                className={classNames(classes.lightIcon, classes.rightMargin)}
              />
              Capture Photo
            </Button> */}
            <CameraLabelButton
              enableCamera={switchToCamera}
              disabled={props.disabled}
            />
          </Grid>

          <Typography
            variant="caption"
            component="div"
            className={classes.tipsText}
          >
            Photo requirements:
            <br />* Good quality photos
            <br />* Uniform lighting with appropriate brightness and contrast to
            show natural skin tone
            <br />* Face centred and looking at the camera straight on; not
            tilted in any direction
          </Typography>

          {/* <Button
            color="primary"
            variant="contained"
            type="submit"
            className={classNames(classes.darkBtn, classes.rightMargin)}
            onClick={props.permanent ? switchToSamples : switchToPermanents}
            disabled={props.disabled}
          >
            {props.permanent ? "Sample Gallery" : "From Permanent List"}
          </Button> */}
        </Grid>
      </div>
    );
  }

  return content;
};

export default forwardRef(FaceUpload);
