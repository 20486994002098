import React, { useState } from "react";
import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Race } from "../../reducers/raceReducers";
import { drawerStyles } from "../../styles/generalStyles";
import { iconStyles } from "../../styles/iconStyles";
import { RaceItem } from "./RaceItem";
import { useSelector } from "react-redux";
import { AppState } from "../../../Store";
import { compareItems } from "../../helperFunctions/stringFunctions";
import { RunnerType } from "../../reducers/profilesReducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...drawerStyles(theme),
    ...iconStyles(theme),
  })
);

export type RaceListPropsType = {
  raceList: Array<RunnerType> | null;
  selectRace: Function;
  selectedId: string;
  sideComponent?: JSX.Element;
};

export const RaceList = (props: RaceListPropsType) => {
  const classes = useStyles();
  // const [loading, setLoading] = useState(false);

  const fetching = useSelector(
    (state: AppState) => state.global.races.updating
  );

  const raceList = props.raceList && props.raceList.sort(compareItems);

  let races =
    raceList &&
    raceList.map((race: RunnerType, i: number) => {
      return (
        <RaceItem
          key={"race_" + i}
          race={race}
          selectedRace={props.selectedId}
          selectRace={props.selectRace}
          sideComponent={props.sideComponent}
        />
      );
    });

  if (fetching) {
    races = [
      <ListItem key={"people_loading"}>
        <ListItemIcon>
          <CircularProgress size={30} />
        </ListItemIcon>
        <ListItemText primary="Fetching for people....." />
      </ListItem>,
    ];
  } else if (races === null || races.length <= 0) {
    races = [
      <ListItem key={"people_not_found"}>
        <ListItemIcon>
          <WarningIcon />
        </ListItemIcon>
        <ListItemText primary="No profiles Found" />
      </ListItem>,
    ];
  }

  return <List className={classes.list}>{races}</List>;
};
