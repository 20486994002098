import {
  GET_ALL_EVENTS,
  GET_ALL_EVENT_RACES,
  GET_ALL_PARTICIPANTS,
  GET_PARTICIPANT_BY_ID,
  SET_PARTICIPANTS_AVATAR,
  SET_PARTICIPANTS_ENROLMENTS_IMAGE,
  SET_UPDATING_PARTICIPANTS,
} from "../../global/actions/actionNames";
import { FaceIdType, RunnerType } from "../../global/reducers/profilesReducers";
import { Race } from "../../global/reducers/raceReducers";
import { EventType, ParticipantsActionType } from "../types/participantsType";

export interface ParticipantsState {
  events: Array<EventType> | null;
  updating: boolean;
  participants: Array<RunnerType> | null;
  races: Array<Race>;
}

const initialParticipantsState: ParticipantsState = {
  events: null,
  updating: false,
  participants: null,
  races: [],
};

export const participantsReducers = (
  state = initialParticipantsState,
  actions: ParticipantsActionType
) => {
  switch (actions.type) {
    case SET_UPDATING_PARTICIPANTS: {
      if (actions.status !== undefined) {
        return {
          ...state,
          updating: actions.status,
        };
      }
      return state;
    }

    case GET_ALL_EVENTS: {
      if (actions.events !== undefined) {
        {
          return {
            ...state,
            events: actions.events,
          };
        }
      }
      return state;
    }

    case GET_ALL_EVENT_RACES: {
      if (actions.races !== undefined) {
        return {
          ...state,
          races: actions.races,
        };
      }
      return state;
    }

    case GET_ALL_PARTICIPANTS: {
      if (actions.participants !== undefined) {
        return {
          ...state,
          participants: actions.participants,
        };
      }
      return state;
    }

    case SET_PARTICIPANTS_ENROLMENTS_IMAGE: {
      if (
        actions.enrolmentId !== undefined &&
        actions.faceId !== undefined &&
        actions.profileId !== undefined
      ) {
        let profiles = state.participants ? [...state.participants] : [];
        let profileIndex = profiles.findIndex(
          (p) => p.uid === actions.profileId
        );

        if (profileIndex > -1) {
          let profile = profiles[profileIndex];
          let enrolments = profile.enrolments ? [...profile.enrolments] : [];
          let enrolmentIndex = enrolments.findIndex(
            (i) => i.enrolmentId === actions.enrolmentId
          );

          if (enrolmentIndex > -1) {
            //enrolment exists
            let enrolment = enrolments[enrolmentIndex];
            //check if the faceId exists in the facesArray
            let faces = enrolment.faces ? [...enrolment.faces] : [];
            let faceIndex = faces.findIndex((i) => i.faceId === actions.faceId);

            if (faceIndex > -1) {
              //faceId exists
              //add/replace the image associate to this faceId with a new image
              faces[faceIndex].imgUrl = actions.faceImageUrl;
            } else {
              //faceId does not exists
              //append faceId to facesArray and add an image
              faces.push({
                faceId: actions.faceId,
                imgUrl: actions.faceImageUrl,
              });
            }
          } else {
            //enrolment does not exist
            //create a new enrolment
            let face: FaceIdType = {
              faceId: actions.faceId,
              imgUrl: actions.faceImageUrl,
            };
            enrolments.push({
              enrolmentId: actions.enrolmentId,
              faces: [face],
            });
          }

          profiles[profileIndex] = profile;

          return {
            ...state,
            participants: profiles,
          };
        }
      }
      return state;
    }

    case SET_PARTICIPANTS_AVATAR:
      if (actions.imageUrl !== null && actions.profileId !== null) {
        let participants = state.participants ? [...state.participants] : [];
        let index = participants.findIndex((p) => p.uid === actions.profileId);
        if (index > -1) {
          let participant = participants[index];
          participant.avatar = actions.imageUrl;

          return {
            ...state,
            participants: participants,
          };
        }
      }
      return state;

    case GET_PARTICIPANT_BY_ID:
      if (actions.participant !== null) {
        let participants = state.participants ? [...state.participants] : [];
        let index = participants.findIndex(
          (p) => p.uid === actions.participant.uid
        );

        if (index > -1) {
          participants[index] = actions.participant;
        } else {
          participants.push(actions.participant);
        }

        return {
          ...state,
          participants: participants,
        };
      }
      return state;

    default:
      return state;
  }
};
