import {
  GET_RACES,
  UPDATE_RACE,
  SET_RACE_UPDATING,
  SET_SEARCH_QUERY,
  GET_SEARCH_RESULTS,
} from "../actions/actionNames";
import { EnrolmentInputType } from "../types/faceSearchTypes";
import { RacesActionType } from "../types/raceTypes";

import { EnrolmentType } from "./profilesReducers";

export type Race = {
  raceId: string;
  eventId?: string;
  raceName: string;
  timerId?: string;
  date: Date;
  enrolmentIds: Array<string>;
};

export interface RacesState {
  raceList: Array<Race> | null;
  updating: boolean;
  searchQuery: string;
}

const initialRaceState: RacesState = {
  raceList: null,
  updating: false,
  searchQuery: "",
};

export const raceReducers = (
  state = initialRaceState,
  actions: RacesActionType
) => {
  switch (actions.type) {
    case GET_RACES:
      if (actions.races !== null && actions.races.length > 0) {
        return {
          ...state,
          raceList: actions.races,
        };
      }
      return state;

    case UPDATE_RACE: {
      if (actions.race !== null) {
        //check if race is already exist
        let races = state.raceList ? [...state.raceList] : [];
        let raceIndex = races.findIndex(
          (r) => r.raceId === actions.race.raceId
        );

        if (raceIndex > -1) {
          //replace the current race with the new one
          races[raceIndex] = actions.race;
        } else {
          races.push(actions.race);
        }

        return {
          ...state,
          raceList: races,
        };
      }
      return state;
    }

    case GET_SEARCH_RESULTS: {
      if (actions.races !== null) {
        let raceList: Array<Race> = [];
        actions.races.map((race: Race) => raceList.push(race));
        return {
          ...state,
          raceList: raceList,
        };
      }
      return state;
    }

    case SET_RACE_UPDATING: {
      if (actions.status !== null) {
        return {
          ...state,
          updating: actions.status,
        };
      }
      return state;
    }

    case SET_SEARCH_QUERY: {
      if (actions.query !== undefined) {
        return {
          ...state,
          searchQuery: actions.query,
        };
      }
      return state;
    }

    default:
      return state;
  }
};
