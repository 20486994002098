import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { InfoType } from "../../reducers/userReducers";
import {
  fetchActiveProfile,
  fetchProfiles,
} from "../../actions/profilesActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../Store";
import { profile } from "console";
import { RunnerType } from "../../reducers/profilesReducers";

type UserInfoPropsType = {
  profileInfo: RunnerType;
};

export const UserProfileRedux = (props: UserInfoPropsType) => {
  return (
    <Typography>This is the info page of {props.profileInfo.name}</Typography>
  );

  // if (!activeProfile.uid) {
  //   return <Typography>Fetching profile...........</Typography>;
  // } else {
  //   return (
  //     <Typography>This is the info page of {activeProfile.name}</Typography>
  //   );
  // }
};
