import React from "react";
import { changePassword } from "../../actions/userActions";
import { ChangePasswordForm } from "../../components/users/ChangePasswordForm";

type ChangePasswordReduxPropsType = {
  onClose: Function;
};

export const ChangePasswordRedux = (props: ChangePasswordReduxPropsType) => {
  const handlePasswordChange = (
    oldPassword: string,
    newPassword: string,
    doneBack?: Function,
    failBack?: Function
  ) => {
    changePassword(oldPassword, newPassword, doneBack, failBack);
  };
  return (
    <ChangePasswordForm
      onClose={props.onClose}
      changePassword={handlePasswordChange}
    />
  );
};
