import { createStyles, Theme } from "@material-ui/core";
import chroma from "chroma-js";

export const buttonStyles = (theme: Theme) =>
  createStyles({
    darkBtn: {
      // color:  theme.palette.primary.contrastText,
      color:
        chroma.contrast(theme.palette.primary.main, "white") > 2
          ? "white"
          : "black",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    linkBtn: {
      color: theme.palette.primary.light,
      "&:hover": {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.light,
      },
    },
    primaryBtn: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      fontSize: "0.9375rem",
      border: "3px solid " + theme.palette.primary.light,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    disabledBtn: {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.grey[500],
    },
    lightBtn: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: "1px solid " + theme.palette.primary.light,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
      },
    },
  });
