import { Dispatch } from "redux";
import { CircleFaceType } from "../../ImagusComponents/Gadgets/ImagusFacePicker";
import Properties from "../../Properties";
// import { DetectedFace } from "../reducers/faceSearchReducers";
import { getInit, postInit } from "../types/requestConstants";
import { CLEAR_FORM, DETECT_IMAGE, SAVE_TOKEN } from "./actionNames";
import { handleResponse } from "./handleResponse";

const root = Properties.api_root;

export const clearForm = (dispatch: Dispatch) => {
  dispatch({ type: CLEAR_FORM });
};

/** Upload image to the database */
export const uploadFile = (
  file: File | Blob,
  shouldSaveToken: boolean,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  let formData = new FormData();
  formData.append("image", file);
  return fetch(root + "/api/v2/images", postInit(formData))
    .then(handleResponse)
    .then(
      (token: string) => {
        if (shouldSaveToken) {
          dispatch({ type: SAVE_TOKEN, token: token });
        }
        doneBack && doneBack(token);
      },
      (error) => {
        failBack && failBack(error);
      }
    );
};

/** Detect faces from the given image token */
export const detectFaces = (
  token: string,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  return fetch(root + "/api/v2/images/" + token + "/detect-profile", getInit())
    .then(handleResponse)
    .then(
      (result: Array<CircleFaceType>) => {
        dispatch({ type: DETECT_IMAGE, detectedFaces: result });
        doneBack && doneBack(result);
      },
      (error) => {
        failBack && failBack(error);
      }
    );
};
