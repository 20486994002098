import React, { Fragment, ReactElement } from "react";

import LogoImage from "../../images/AHA-Logo-White.png";

import { NavbarMenu } from "./navs/NavbarMenu";
import { NavList } from "../container/NavList";
import { MenuItemType } from "../types/MenuItemType";
import { AuthedNavbarMenuItem } from "./navs/AuthedNavbarMenuItem";
import { useSelector } from "react-redux";
import { AppState } from "../../Store";

type MenuPropsType = {
  children: ReactElement;
};

export const Menu = (props: MenuPropsType) => {
  const userInfo = useSelector((state: AppState) => state.global.user.info);

  const IsUserAuthorizedForMenuItem = (item : MenuItemType) =>{
    if(item.auth){
      if(userInfo){
        return item.auth.filter(au => !userInfo.roles.includes(au)).length === 0
      }
      else{
        return false;
      }
    }
    else{
      return true
    }
  }
  return (
    <NavbarMenu
      children={props.children}
      logo={LogoImage}
      title="Photo Portal"
      menus={
        <Fragment>
          {NavList.filter(item => IsUserAuthorizedForMenuItem(item) ).map((item: MenuItemType) => {
            return (
              <AuthedNavbarMenuItem
                text={item.label}
                key={item.label}
                to={item.to}
                icon={item.icon}
                onClick={item.onClick}
              />
            );
          })}
        </Fragment>
      }
    />
  );
};
