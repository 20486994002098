import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Store";
import { detectFaces, uploadFile } from "../actions/faceSearchActions";
import {
  downloadFilesById,
  fetchActiveProfile,
  updateProfile,
} from "../actions/profilesActions";

import { AppHeader } from "../components/gadgets/AppHeader";
import { AppHeaderSubtitle } from "../components/gadgets/AppHeaderSubtitle";
import { ModalForm } from "../components/gadgets/ModalForm";

import { PermanentPhotoFormRedux } from "../container/profiles/PermanentPhotoFormRedux";
import { PhotoDisplayRedux } from "../container/profiles/PhotoDisplayRedux";
import { UserAuth } from "../container/UserAuth";
import { EnrolmentType } from "../reducers/profilesReducers";
import { buttonStyles } from "../styles/buttonStyles";
import { contentStyles, panelStyles } from "../styles/generalStyles";
import { EnrolmentInputType } from "../types/faceSearchTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...contentStyles(theme),
    ...panelStyles(theme),
    ...buttonStyles(theme),
    rightMargin: {
      marginRight: "5px",
    },
  })
);

export const Photo = () => {
  const classes = useStyles();
  // const [openAddForm, setOpenAddForm] = useState(false);

  const accountDetails = useSelector(
    (state: AppState) => state.global.user.info
  );
  const activeProfile = useSelector(
    (state: AppState) => state.global.profiles.activeProfile
  );

  let permanentEnrolments: Array<EnrolmentType> = [];
  activeProfile.enrolments.map((eachEnrolment) => {
    if (eachEnrolment.permanent) {
      return permanentEnrolments.push(eachEnrolment);
    }
  });

  const dispatch = useDispatch();

  const handleUpdateProfile = (
    newEnrolments: Array<EnrolmentInputType>,
    deleteEnrolments: Array<string>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    const profileName = activeProfile.name;
    console.log(activeProfile);
    accountDetails &&
      updateProfile(
        activeProfile.uid,
        profileName,
        newEnrolments,
        deleteEnrolments,
        accountDetails.id,
        () => {
          //fetch new enrolments
          fetchActiveProfile(activeProfile.uid, doneBack, failBack)(dispatch);
        },
        failBack
      )(dispatch);
  };

  return (
    <UserAuth principles="Basic">
      <div className={classes.root}>
        <AppHeader
          title="Photos"
          className={classes.appHeader}
          actions={[
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classNames(classes.darkBtn, classes.rightMargin)}
              onClick={() => downloadFilesById(activeProfile.uid)}
            >
              Download your enrolments
            </Button>,
          ]}
        />
        {/* <AppHeaderSubtitle text="Use this page to upload not more than five(5) photos with variations such as plain, with hat, sunnies, etc for each race. Images uploaded will only be used for the race and kept in the system for up to three(3) days after the race's closure, unless specified otherwise by the account holder" /> */}
        <PhotoDisplayRedux />
        {/* <PhotoDisplayRedux />
        <ModalForm
          title="Edit permanent photos"
          open={openAddForm}
          handleModalStatus={() => {
            setOpenAddForm(false);
          }}
          form={
            <PermanentPhotoFormRedux
              onClose={() => setOpenAddForm(false)}
              permanent={true}
              updateEnrolment={handleUpdateProfile}
              enrolments={permanentEnrolments}
            />
          }
        /> */}
      </div>
    </UserAuth>
  );
};
