import { Dispatch } from "redux";
import {
  Data,
  deleteBody,
  getInit,
  PasswordChangeModel,
  postBody,
  putBody,
  putInit,
  ResetPasswordModel,
} from "../types/requestConstants";
import Properties from "../../Properties";
import { handleResponse } from "./handleResponse";
import { removeStorage, setStorage } from "../helperFunctions/localStorage";
import { SET_AUTH, SET_FETCHING_AUTH, SET_USER } from "./actionNames";
import { InfoType } from "../reducers/userReducers";

const root = Properties.api_root;

export const userLogin = (
  email: string,
  password: string,
  doneBack?: Function,
  failBack?: Function
) => {
  // let formData: Data = {
  //   email: email,
  //   password: password,
  // };
  // //return Error if no username or email is found
  // if (!email) {
  //   return failBack && failBack({ credentials: "Email is required" });
  // }
  let formData:  Data = {
    username: email,
    password: password
  }

  return fetch(`${root}/api/v2/users/login`, postBody(formData))
    .then((response: Response): Function => handleResponse(response, "HTML"))
    .then(
      (json: any): void => {
        setStorage("token", json);
        doneBack && doneBack();
      },
      (error: any) => {
        failBack && failBack(error);
      }
    );
};

export const userSignup = (
  username: string,
  password: string,
  email: string,
  doneBack: Function,
  failBack: Function
) => {
  let formData: Data = {
    username: username,
    password: password,
    email: email,
  };

  return fetch(`${root}/api/v2/users/basic/register`, postBody(formData))
    .then((response: Response) => handleResponse(response, "HTML"))
    .then(
      (result: JSON) => {
        doneBack && doneBack(result);
      },
      (error) => {
        failBack && failBack(error);
      }
    );
};

export const userAssignRole = (
  userId: string,
  roleName:string,
  doneBack?: Function,
  failBack?: Function
) => {
  let formData: Data = {
    userId: userId,
    roleName: roleName
  };

  return fetch(`${root}/api/v1/roles/assign`, postBody(formData))
    .then(handleResponse)
    .then(
      (result) => {
        doneBack && doneBack(result);
      },
      (error) => {
        failBack && failBack(error);
      }
    );
};

//delete current user
export const userDelete = (
  userId: string,
  doneBack?: Function,
  failBack?: Function
) => {
  console.log("got to delete");
  console.log("userId:", userId);
  userLogout();

  return fetch(`${root}/api/v2/users/delete`, deleteBody(userId))
    .then((response: Response) => handleResponse(response, "TEXT"))
    .then(
      (result) => {
        console.log(result);
        doneBack && doneBack(result);
      },
      (error) => {
        failBack && failBack(error);
      }
    );
};

//user change their own password
export const changePassword = (
  oldPassword: string,
  newPassword: string,
  doneBack?: Function,
  failBack?: Function
) => {
  let formData: PasswordChangeModel = {
    newPassword: newPassword,
    currentPassword: oldPassword,
  };

  return fetch(root + "/api/v2/users/password", putBody(formData))
    .then((res: Response) => handleResponse(res, "TEXT"))
    .then(
      () => {
        doneBack && doneBack();
      },
      (error) => {
        failBack && failBack(error);
      }
    );
};

//user reset password
export const resetPassword = (
  email: string,
  token: string,
  newPassword: string,
  doneBack?: Function,
  failBack?: Function
) => {
  let formData: ResetPasswordModel = {
    email: email,
    token: token,
    password: newPassword,
  };

  return fetch(root + "/api/v2/users/password/reset", putBody(formData))
    .then((res: Response) => handleResponse(res, "TEXT"))
    .then(
      () => {
        doneBack && doneBack();
      },
      (error) => {
        failBack && failBack(error);
      }
    );
};

//user request for token to be sent to email
export const forgotPassword = (
  email: string,
  doneBack?: Function,
  failBack?: Function
) => {
  return fetch(root + "/api/v2/users/password/forgot", postBody(email))
    .then((res: Response) => handleResponse(res, "TEXT"))
    .then(
      () => {
        doneBack && doneBack();
      },
      (err) => {
        failBack && failBack(err);
      }
    );
};

const removeUserFromPeopleDB = (name: string) => {};

export const authenticate = (
  shouldSetAuth: boolean = true,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  dispatch({ type: SET_FETCHING_AUTH, status: true });
  return fetch(`${root}/api/v2/users/authenticate`, getInit())
    .then(handleResponse)
    .then(
      (json: JSON): void => {
        if (shouldSetAuth) {
          dispatch({ type: SET_AUTH, authenticated: true });
          dispatch({ type: SET_FETCHING_AUTH, status: false });
          dispatch({ type: SET_USER, user: json });
        }
        doneBack && doneBack(json);
      },
      (errors): void => {
        if (shouldSetAuth) {
          dispatch({ type: SET_AUTH, authenticatied: false });
          dispatch({ type: SET_FETCHING_AUTH, status: false });
        }
        failBack && failBack(errors);
      }
    );
};

export const userLogout = async () => {
  return fetch(`${root}/api/v2/users/logout`, postBody(undefined, true)).then(
    () => {
      removeStorage("token");
      window.location.href = "login";
    }
  );
};
