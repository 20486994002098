// @flow
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

import {
  WhiteText,
  BlackText,
  InvalidText,
  ValidText,
  WarningText,
  BlueText,
} from "./styleConstants";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface ThemeOptions {
    themeName?: string;
  }
  interface Theme {
    themeName?: string;
  }
}

declare module "@material-ui/core/styles/createPalette" {
  interface PaletteOptions {
    main?: string;
    backgroundColor?: string;
    contentBackgroundColor?: string;
  }

  interface Palette {
    main?: PaletteColor;
    backgroundColor?: string;
    contentBackgroundColor?: string;
  }
}

const basePalette = {
  common: {
    black: BlackText,
    white: WhiteText,
  },
  error: {
    main: InvalidText,
    contrast: ValidText,
  },
  warning: {
    main: WarningText,
  },
  blue: {
    main: BlueText,
  },
};

const baseTheme = createMuiTheme({
  overrides: {
    MuiTableHead: {
      root: {
        fontSize: "0.9375rem",
      },
    },
    MuiTableBody: {
      root: {
        fontSize: "0.9375rem",
      },
    },
    MuiAccordionDetails: {
      root: {
        display: "block",
      },
    },
    MuiTooltip: {
      tooltipPlacementBottom: {
        fontSize: "0.8125rem",
      },
      tooltipPlacementTop: {
        fontSize: "0.8125rem",
      },
      tooltipPlacementLeft: {
        fontSize: "0.8125rem",
      },
      tooltipPlacementRight: {
        fontSize: "0.8125rem",
      },
    },
    MuiCard: {
      root: {
        overflow: "visible",
      },
    },
    MuiCardContent: {
      root: {
        padding: "5px",
        "&:last-child": {
          paddingBottom: "5px",
        },
      },
    },
    MuiListItem: {
      root: {
        "&$selected, &$selected:hover ": {
          backgroundColor: "#787c7f",
        },
      },
      button: {
        "&:hover": {
          backgroundColor: "#787c7f57",
        },
      },
    },
  },
});

export const blueTheme = createMuiTheme({
  ...baseTheme,
  themeName: "blueTheme",
  palette: {
    ...basePalette,
    primary: {
      light: "#479bba",
      main: "#1a83a9",
      dark: "#146887",
      contrastText: BlackText,
    },
    secondary: {
      light: "#ba6647",
      main: "#a9401a",
      dark: "#873314",
    },
  },
});

export const darkTheme = createMuiTheme({
  ...baseTheme,

  themeName: "darkTheme",
  palette: {
    ...basePalette,
    primary: {
      light: "#293a4a",
      main: "#192734",
      dark: "#1F2833",
      contrastText: BlackText,
    },
    // backgroundColor: "#19273430",
    contentBackgroundColor: "#1927342e",
    backgroundColor: "000000",
  },
});
