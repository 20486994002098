import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppHeader } from "../../global/components/gadgets/AppHeader";
import { panelStyles } from "../../global/styles/generalStyles";
import { ThemeSelector } from "../containers/ThemeSelector";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...panelStyles(theme),
  })
);
export const Themes = () => {
  const classes = useStyles();
  return (
    <div className={classes.appMain}>
      <AppHeader title="Theme Management" />
      <div className={classes.appPanel}>
        <div className={classes.appPanelContent}>
          <ThemeSelector />
        </div>
      </div>
    </div>
  );
};
