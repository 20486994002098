import { DeleteRounded } from "@material-ui/icons";
import { Dispatch } from "redux";
import Properties from "../../Properties";
import { compareItems } from "../helperFunctions/stringFunctions";
import { Race } from "../reducers/raceReducers";
import { RaceReturnModel } from "../types/ApiReturnTypes";
import { Data, getInit, postBody, putBody } from "../types/requestConstants";
import {
  UPDATE_RACE,
  GET_RACES,
  GET_SEARCH_RESULTS,
  SET_RACE_UPDATING,
  SET_SEARCH_QUERY,
} from "./actionNames";
import { handleResponse } from "./handleResponse";

const root = Properties.api_root;

// export const getRegisteredRaces = (
//   profileId: string,
//   doneBack?: Function,
//   failBack?: Function
// ) => (dispatch: Dispatch) => {
//   dispatch({ type: SET_RACE_UPDATING, status: true });
//   return fetch(root + "/api/v2/profile/" + profileId + "/races", getInit())
//     .then(handleResponse)
//     .then(

//         races.map((eachRace: Race) => {
//           return dispatch({ type: UPDATE_RACE, race: eachRace });
//         });

//         dispatch({ type: SET_RACE_UPDATING, status: false });
//         doneBack && doneBack(races);
//       },
//       (error) => {
//         dispatch({ type: SET_RACE_UPDATING, status: false });
//         failBack && failBack(error);
//       }
//     );
// };

/** Get current race details given raceID and current profileId */
export const fetchRaceDetailsById = (
  raceId: string,
  profileId: string,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  console.log(raceId, profileId);
  return fetch(
    root + "/api/v2/race/" + raceId + "/profile?profileId=" + profileId,
    getInit()
  )
    .then(handleResponse)
    .then(
      (result: RaceReturnModel) => {
        let enrolmentList: Array<string> = [];
        result.enrolmentIds.forEach((element) => {
          return (enrolmentList = [
            ...enrolmentList,
            ...Object.values(element),
          ]);
        });
        console.log(enrolmentList);

        const race: Race = {
          raceId: result.raceId,
          raceName: result.raceName,
          date: result.date,
          enrolmentIds: enrolmentList,
        };
        dispatch({ type: UPDATE_RACE, race: race });
        doneBack && doneBack(race);
      },
      (error) => {
        failBack && failBack(error);
      }
    );
};

export const fetchRace = (
  profileId: string,
  query: string,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  dispatch({ type: SET_RACE_UPDATING, status: true });
  return fetch(
    root + "/api/v2/race/" + profileId + "/search?nameFilter=" + query,
    getInit()
  )
    .then(handleResponse)
    .then(
      (result: Array<RaceReturnModel>) => {
        let races: Array<Race> = [];
        //Create the enrolmentId Array
        result.map((eachRace) => {
          let enrolmentList: Array<string> = [];
          eachRace.enrolmentIds.forEach((element) => {
            return (enrolmentList = [
              ...enrolmentList,
              ...Object.values(element),
            ]);
          });
          console.log(enrolmentList);

          const race: Race = {
            raceId: eachRace.raceId,
            raceName: eachRace.raceName,
            date: eachRace.date,
            enrolmentIds: enrolmentList,
          };

          return (races = [...races, race]);
        });

        dispatch({ type: SET_SEARCH_QUERY, query: query });
        dispatch({ type: GET_SEARCH_RESULTS, races: races });
        dispatch({ type: SET_RACE_UPDATING, status: false });
        doneBack && doneBack(races);
      },
      (error) => {
        dispatch({ type: SET_RACE_UPDATING, status: false });
        failBack && failBack(error);
      }
    );
};

interface EnrolmentRaceInput extends Data {
  newEnrolmentIds: Array<string>;
  deleteEnrolmentIds: Array<string>;
  profileId: string;
}

export const updateRaceEnrolment = (
  raceId: string,
  profileId: string,
  newEnrolments: Array<string>,
  deleteEnroments: Array<string>,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  let formData: EnrolmentRaceInput = {
    newEnrolmentIds: newEnrolments,
    deleteEnrolmentIds: deleteEnroments,
    profileId: profileId,
  };

  console.log(formData);

  dispatch({ type: SET_RACE_UPDATING, status: true });
  console.log(raceId);
  return fetch(
    root + "/api/v2/race/" + raceId + "/enrolments",
    putBody(formData)
  )
    .then((response: Response) => handleResponse(response, "EMPTY"))
    .then(
      () => {
        // dispatch({ type: GET_RACE, race: res });
        dispatch({ type: SET_RACE_UPDATING, status: false });
        doneBack && doneBack();
      },
      (error) => {
        dispatch({ type: SET_RACE_UPDATING, status: false });
        failBack && failBack(error);
      }
    );
};
