import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { AppHeader } from "../../global/components/gadgets/AppHeader";
import { UserAuth } from "../../global/container/UserAuth";
import { contentStyles } from "../../global/styles/generalStyles";
import { ParticipantsRedux } from "../containers/ParticipantsRedux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...contentStyles(theme),
  })
);

export const Participants = () => {
  const classes = useStyles();
  return (
    <UserAuth>
      <div className={classes.root}>
        <AppHeader title="Participants" />
        <ParticipantsRedux />
      </div>
    </UserAuth>
  );
};
