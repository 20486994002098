import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";
import { RaceRedux } from "../container/race/RaceRedux";
import { contentStyles, panelStyles } from "../styles/generalStyles";
import { AppHeader } from "../components/gadgets/AppHeader";
import { UserAuth } from "../container/UserAuth";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Store";
import { RunnerType } from "../reducers/profilesReducers";
import { buttonStyles } from "../styles/buttonStyles";
import { ModalForm } from "../components/gadgets/ModalForm";
import { CreateProfileFormRedux } from "../container/profiles/CreateProfileFormRedux";
import {
  downloadFiles,
  fetchAllProfiles,
} from "../../admin/actions/participantsActions";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...contentStyles(theme),
    ...panelStyles(theme),
    ...buttonStyles(theme),

    root: {
      color: theme.palette.primary.main,
    },

    rightMargin: {
      marginRight: "5px",
    },
  })
);

export const Race = () => {
  const classes = useStyles();
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const races = useSelector(
    (state: AppState) => state.admin.participants.participants
  );
  const userInfo = useSelector((state: AppState) => state.global.user.info);
  const accountId = userInfo ? userInfo.id : "";
  const dispatch = useDispatch();
  const activeProfile = useSelector(
    (state: AppState) => state.global.profiles.activeProfile
  );
  const [currentNameQuery, setCurrentNameQuery] = useState("");
  const getProfileList = (
    query: string,
    doneBack?: Function,
    failBack?: Function
  ) => {
    setCurrentNameQuery(query);

    fetchAllProfiles(1000, query, doneBack, failBack)(dispatch);
  };

  const onCreateProfileDone = () => {
    setIsProfileModalOpen(false);
    getProfileList("");
  };

  const downloadEnrolments = (doneBack?: Function, failBack?: Function) => {
    downloadFiles(1000, currentNameQuery, doneBack, failBack);
  };

  return (
    <UserAuth principles="Admin">
      <div className={classes.root}>
        <AppHeader
          title="People"
          actions={[
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classNames(classes.darkBtn, classes.rightMargin)}
              onClick={() => downloadEnrolments()}
            >
              Download enrolments
            </Button>,
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classes.darkBtn}
              onClick={() => setIsProfileModalOpen(true)}
            >
              Create profile
            </Button>,
          ]}
        />

        <div className={classes.appPanel}>
          <div className={classes.appPanelContent}>
            <RaceRedux races={races} getRaces={getProfileList} />

            <ModalForm
              title="Create Profile"
              open={isProfileModalOpen}
              darkBackdrop
              disableExit
              handleModalStatus={() => setIsProfileModalOpen(false)}
              form={
                <CreateProfileFormRedux
                  onClose={onCreateProfileDone}
                  userId={accountId}
                  currentProfile={activeProfile}
                />
              }
            />
          </div>
        </div>
      </div>
    </UserAuth>
  );
};
