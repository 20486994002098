import {
  AccountCircle,
  DirectionsRun,
  ExitToApp,
  Palette,
  PhotoLibrary,
  SupervisorAccount as ParticipantIcon,
  LocalHotel as HotelIcon,
  People,
} from "@material-ui/icons";
import { userLogout } from "../actions/userActions";
import { MenuItemType } from "../types/MenuItemType";

export const NavList: Array<MenuItemType> = [
  {
    to: "profile",
    icon: <AccountCircle />,
    label: "Profile",
    key: "link-to-home",
    onClick: null,
  },

  {
    to: "photo",
    icon: <PhotoLibrary />,
    label: "Photo",
    key: "link-to-photo",
    onClick: null,
    auth: ["Basic"],
  },

  {
    to: "people",
    icon: <People />,
    label: "People",
    key: "link-to-people",
    onClick: null,
    auth: ["Admin"],
  },
  // {
  //   to: "participant",
  //   icon: <ParticipantIcon />,
  //   label: "Participant",
  //   key: "link-to-participant",
  //   onClick: null,
  // },
  {
    to: "theme",
    icon: <Palette />,
    label: "Theme",
    key: "link-to-theme",
    onClick: null,
  },
  {
    to: "",
    icon: <ExitToApp />,
    label: "Log Out",
    key: "link-to-logout",
    onClick: userLogout.bind(undefined),
  },
];
