import React from "react";
import "./App.css";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as Global from "./global/views";
import * as Admin from "./admin/views";

import { Provider } from "react-redux";
import Store from "./Store";

function App() {
  return (
    <Provider store={Store}>
      <BrowserRouter>
        {/* <Menu menuItems={menuItems} /> */}
        <Global.Template>
          <Switch>
            <Route path="/reset" component={Global.ResetPassword} />
            <Route path="/login" component={Global.Login} />
            <Route path="/forgot" component={Global.Forgot} />
            <Route path="/photo" component={Global.Photo} />
            <Route path="/people" component={Global.Race} />
            <Route path="/profile" component={Global.Profile} />
            <Route path="/theme" component={Admin.Themes} />
            <Route path="/participant" component={Admin.Participants} />
            <Route exact path="/signup" component={Global.Signup} />
            {/* <Route path="/signup/success" component={Global.SignupSuccess} /> */}
            <Redirect to="/login" />
          </Switch>
        </Global.Template>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
