import React, { ChangeEvent, Fragment, useState } from "react";
import classNames from "classnames";
import { Drawer, IconButton, Theme } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { drawerStyles } from "../../global/styles/generalStyles";
import { iconStyles } from "../../global/styles/iconStyles";
import { ImagusSearchBar } from "../FormInputs/ImagusSearchBar";
import { SingleCheckBox } from "../../global/components/formInputs/SingleCheckBox";
import { RaceList } from "../../global/components/race/RaceList";
import { Race } from "../../global/reducers/raceReducers";
import { Runtime } from "node:inspector";
import { RunnerType } from "../../global/reducers/profilesReducers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...drawerStyles(theme),
    ...iconStyles(theme),
    hide: {
      display: "none",
    },
    checkBoxes: {
      paddingLeft: "15px",
    },
  })
);

type ImagusRaceDrawerPropsType = {
  handleSearch: Function;
  raceList: Array<RunnerType> | null;
  handleRaceSelect: Function;
  selectedId: string;
};

export const ImagusRaceDrawer = (props: ImagusRaceDrawerPropsType) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [raceStatusSearch, setRaceStatusCheck] = useState({
    closed: false,
    open: false,
  });

  const toggleSearchBarDrawer = () => {
    setOpen(!open);
  };

  const handleCheckBoxClick = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.checked;
    setRaceStatusCheck({ ...raceStatusSearch, [name]: value });
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !open && classes.drawerPaperClose
        ),
      }}
    >
      <Fragment>
        <div className={classes.toolbar}>
          <div
            className={classNames(classes.toolbarSearch, !open && classes.hide)}
          >
            <ImagusSearchBar searchQuery="" handleSearch={props.handleSearch} />
          </div>
          {open ? (
            <IconButton onClick={toggleSearchBarDrawer}>
              <ChevronLeftIcon className={classes.greenIcon} />
            </IconButton>
          ) : (
            <IconButton onClick={toggleSearchBarDrawer}>
              <ChevronRightIcon className={classes.greenIcon} />
            </IconButton>
          )}
        </div>
        {/* {open && (
          <div className={classes.checkBoxes}>
            <SingleCheckBox
              name="open"
              label="Open"
              checked={raceStatusSearch.open}
              onChange={handleCheckBoxClick}
            />

            <SingleCheckBox
              name="closed"
              label="Closed"
              checked={raceStatusSearch.closed}
              onChange={handleCheckBoxClick}
            />
          </div>
        )} */}

        {open && (
          <RaceList
            raceList={props.raceList}
            selectRace={props.handleRaceSelect}
            selectedId={props.selectedId}
          />
        )}
      </Fragment>
    </Drawer>
  );
};
