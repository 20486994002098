import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import LogoImage from "../../images/AHA-Logo.png";
import { panelStyles, formText } from "../styles/generalStyles";
import { iconStyles } from "../styles/iconStyles";
import { RedirectToLogin } from "./RedirectToLogin";
import { useDispatch, useSelector } from "react-redux";
// import { addUserToPeopleDB } from "../actions/profilesActions";

import { AppState } from "../../Store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...panelStyles(theme),
    ...iconStyles(theme),
    formText,
    wrapper: {
      width: "100%",
      maxWidth: "480px",
      margin: "10% auto",
      backgroundColor: "inherit",
      height: "auto",
      border: "1px solid black",
    },
    maxHeight: {
      hieght: "auto",
    },
    formLogo: {
      width: "100%",
      margin: "2% 0",
      padding: "10px",
      height: "auto",
      float: "none",
    },
    titleText: {
      color: theme.palette.common.black,
    },
    titleMargin: {
      marginBottom: "30px",
    },
    panel: {
      width: "85%",
      margin: "auto",
    },
  })
);

type SignupSuccessPropsType = {
  name: string;
  profileId: string;
};

export const SignupSuccess = (props: SignupSuccessPropsType) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const updating = useSelector(
    (state: AppState) => state.global.profiles.updating
  );
  const dispatch = useDispatch();
  // useEffect(() => {
  //   addUserToPeopleDB(
  //     props.name,
  //     props.profileId,
  //     () => {
  //       setLoading(false);
  //     },
  //     (error: any) => {
  //       console.log(error);
  //       setLoading(false);
  //     }
  //   )(dispatch);
  // }, [dispatch, props]); //these will not change => only call api once

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [props]);

  return (
    <div className={classNames(classes.appMain, classes.maxHeight)}>
      <div className={classes.wrapper}>
        <img src={LogoImage} className={classes.formLogo} alt="Logo" />
        <div className={classNames(classes.panel, classes.formText)}>
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <CheckCircleIcon
                style={{ color: "#00b300", width: "50px", height: "50px" }}
              />
            </Grid>
            <Grid item xs={11}>
              <Typography
                variant="h5"
                className={classNames(classes.titleText, classes.titleMargin)}
              >
                Registration Completed!
              </Typography>
            </Grid>
          </Grid>
        </div>
        <Typography variant="subtitle1" className={classes.formText}>
          Redirecting to Login Page ...
        </Typography>
        {!loading && <RedirectToLogin />}
      </div>
    </div>
  );
};
