import React, {
  ElementRef,
  forwardRef,
  ForwardRefRenderFunction,
  Fragment,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import loadImage from "blueimp-load-image";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@material-ui/core";

import {
  CircleFaceType,
  ImagusFacePicker,
} from "../../../ImagusComponents/Gadgets/ImagusFacePicker";

import FaceUpload from "./FaceUpload";

import Image1 from "../../../images/samples/Abdel_Nasser_Assidi_0002.jpg";
import Image2 from "../../../images/samples/George_HW_Bush_0006.jpg";
import Image3 from "../../../images/samples/George_P_Bush_0002.jpg";
import Image4 from "../../../images/samples/George_W_Bush_0042.jpg";
import Image5 from "../../../images/samples/George_W_Bush_0098.jpg";
import Image6 from "../../../images/samples/Michael_Jackson_0002.jpg";
import Image7 from "../../../images/samples/Michael_Jackson_0004.jpg";

import { CancelButton } from "../Buttons/CancelButton";
import { SubmitButton } from "../Buttons/SubmitButton";
import {
  EnrolmentInputType,
  ImageType,
  PairCoordinateType,
  PersonType,
} from "../../types/faceSearchTypes";
import {
  cropImageToFace,
  faceCentreFromEyes,
  radiusFromEyes,
} from "../../helperFunctions/cropImageToFace";
import { EnrolmentType } from "../../reducers/profilesReducers";
import { ErrorDisplay, setErrorMessage } from "../ErrorDisplay";
import { useSelector } from "react-redux";
import { AppState } from "../../../Store";

type ImageUploadProcessPropsType = {
  detectedFaces: Array<CircleFaceType>;
  uploadFile: Function;
  errors: Record<string, string>;
  addEnrolment?: Function;
  disabled?: boolean;
  profileName?: string;
  permanent?: boolean;
  fromPermanentList?: Array<EnrolmentType>;
  disabledDialog?: boolean;
  setDisplaySubmit?: Function;
  setShowDetails?: Function;
  setImageErrors?: Function;
  setFaceIndex?: Function;
  enrolFace?: Function;
};
type ImageUploadProcessHandle = {
  resetImageUploadProcess: () => void;
};

type FaceUploadHandle = ElementRef<typeof FaceUpload>;

const ImageUploadProcess: ForwardRefRenderFunction<
  ImageUploadProcessHandle,
  ImageUploadProcessPropsType
> = (props: ImageUploadProcessPropsType, forwardedRef) => {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [token, setToken] = useState("");
  const [image, setImage] = useState<ImageType>();
  const [selectedEye, setSelectedEye] = useState<PairCoordinateType | null>(
    null
  );
  const [errors, setError] = useState<Record<string, string>>(props.errors);
  const [faceIndex, setFaceIndex] = useState(0);
  const ref = useRef<FaceUploadHandle>(null);
  const currentTotalEnrolment = useSelector(
    (state: AppState) => state.global.profiles.activeProfile.enrolments
  ).length;
  const resetFaceUploadFromParent = () => {
    if (ref.current) {
      ref.current.resetFaceUpload();
    }
  };

  useImperativeHandle(forwardedRef, () => ({
    resetImageUploadProcess() {
      resetFaceUploadFromParent();
      resetForm();
    },
  }));

  const resetForm = () => {
    setImage(undefined);
    setOpenDialog(false);
    setLoading(false);
    setSelectedEye(null);
    setFaceIndex(0);
    setToken("");

    setError({});
    props.setDisplaySubmit && props.setDisplaySubmit(true);
    props.setShowDetails && props.setShowDetails(false);
  };

  /** Upload the image => do face search */
  const handleUploadFile = (file: File) => {
    setLoading(true);
    setImageError("");
    setError({});
    props.uploadFile(
      file,
      (token: string) => loadImageToCanvas(token, file),
      uploadFail
    );
    //todo: set image error
  };

  /** Dispaly result */
  const loadImageToCanvas = (token: string, file: File) => {
    // console.log(token);
    setToken(token);
    loadImage(file, uploadDone, { orientation: true, canvas: true });
  };

  /** Display image after search for face */
  const uploadDone = (canvas: HTMLCanvasElement | Event | HTMLImageElement) => {
    if (canvas instanceof HTMLCanvasElement) {
      const img = canvas.toDataURL("image/jpeg");
      setImage({ url: img, canvas: canvas });
      props.setShowDetails && props.setShowDetails(true);
      setLoading(false);
      setOpenDialog(true);
    }
  };

  /** Failback for upload errors */
  const uploadFail = (error: string) => {
    setLoading(false);
    // setImageError("Not supported");
    setError({ image: "File not supported" });
    console.log(error);
  };

  /** Return true if the photo matches the profile */
  const personCheck = (face: CircleFaceType): boolean => {
    return face.search.searchName === props.profileName;
  };

  /** Return true if the image passes the threshold */
  const blurrinessCheck = (face: CircleFaceType): boolean => {
    return face.blurriness >= 5.5;
  };

  /** Enrol face to DB */
  const faceEnrol = () => {
    if (selectedEye) {
      let validImage = true;
      let error: string = "";

      if (validImage) {
        const eyes = selectedEye;
        const person: PersonType = {
          eyes: eyes,
          centre: faceCentreFromEyes(eyes),
          radius: radiusFromEyes(eyes),
        };

        const img = image && cropImageToFace(image.canvas, person);

        //Create a temporary enrolment item
        const enrolment: EnrolmentInputType = {
          enrolmentId: Date.now().toString(),
          token: token,
          imageUrl: img && img.src,
          eyes: eyes,
          nose: props.detectedFaces[faceIndex].nose,
          mouth: props.detectedFaces[faceIndex].mouth,
          size: props.detectedFaces[faceIndex].size,
          permanent: props.permanent,
        };
        if (props.enrolFace) {
          props.enrolFace(enrolment, resetForm);
        } else {
          props.addEnrolment && props.addEnrolment(enrolment);
        }
        // resetForm();
      } else {
        setImageError(error);
      }
    } else {
      //set error
      // setError({ image: "No face detected" });
      setImageError("No face detected");
    }
  };

  /**Change eye selection */
  const changeEyeSelection = (eyes: PairCoordinateType, index: number) => {
    setSelectedEye(eyes);
    setFaceIndex(index);
    props.setFaceIndex && props.setFaceIndex(index);
  };

  /** Set image errors */
  const setImageError = (message: string) => {
    setError({ image: message });
  };

  /** Handle user select from permanent list */
  const handlePermanentSelection = (imgUrl: string) => {
    if (props.fromPermanentList) {
      var index = props.fromPermanentList.findIndex(
        (enrolment) => enrolment.faces[0].imgUrl === imgUrl
      );
      props.addEnrolment && props.addEnrolment(props.fromPermanentList[index]);
      resetForm();
    }
  };

  /** Check whether should disable enrolment */
  const shouldDisable = (): boolean => {
    const errorList = Object.keys(props.errors);

    if (errorList.length === 1 && errorList.indexOf("person") > -1) {
      return false;
    } else {
      if (errorList.length === 0) {
        return false;
      }
    }

    return true;
  };

  let imgList: Array<string> = [];
  if (props.permanent) {
    //if in permanent page, list the sample images
    imgList = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];
  } else {
    //else display from permanent list
    if (props.fromPermanentList) {
      props.fromPermanentList.map((enrolment) => {
        if (enrolment.faces[0].imgUrl) {
          return imgList.push(enrolment.faces[0].imgUrl);
        }
      });
    }
  }

  // const FacePicker = () => {
  //   if (props.detectedFaces && image) {
  //     props.setDisplaySubmit && props.setDisplaySubmit(false);
  //     props.setShowDetails && props.setShowDetails(true);
  //     return (
  //       <ImagusFacePicker
  //         faces={props.detectedFaces}
  //         imageUrl={image.url}
  //         name={props.profileName}
  //         changeEyeSelection={changeEyeSelection}
  //       />
  //     );
  //   } else return <CircularProgress />;
  // };

  if (loading) {
    return <CircularProgress />;
  } else if (image) {
    /** Display photo with face captured */
    if (props.disabledDialog) {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={10}>
            {props.detectedFaces ? (
              <ImagusFacePicker
                faces={props.detectedFaces}
                imageUrl={image.url}
                name={props.profileName}
                changeEyeSelection={changeEyeSelection}
              />
            ) : (
              <CircularProgress />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={10}>
            <ErrorDisplay
              errors={
                Object.keys(props.errors).indexOf("image") > -1
                  ? props.errors
                  : {}
              }
            />
          </Grid>
          <Grid item container>
            <CancelButton onClick={resetForm} />
            <SubmitButton
              title="Enrol Face"
              onClick={faceEnrol}
              disabled={shouldDisable()}
            />
          </Grid>
        </Grid>
      );
    } else
      return (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={openDialog}
          onClose={resetForm}
          fullWidth
          maxWidth="lg"
        >
          <DialogContent>
            {props.detectedFaces ? (
              <ImagusFacePicker
                faces={props.detectedFaces}
                imageUrl={image.url}
                name={props.profileName}
                changeEyeSelection={changeEyeSelection}
              />
            ) : (
              <CircularProgress />
            )}
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={12}>
                <ErrorDisplay errors={props.errors} />
              </Grid>
              <Grid item container alignItems="flex-end" justify="flex-end">
                <CancelButton onClick={resetForm} />
                <SubmitButton
                  onClick={faceEnrol}
                  disabled={Object.keys(props.errors).length > 0}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      );
  } else {
    /** Face Upload Process */
    return (
      <FaceUpload
        setImageError={setImageError}
        uploadFile={handleUploadFile}
        samples={imgList}
        disabled={props.disabled}
        selectPermanent={handlePermanentSelection}
        permanent={props.permanent}
        setDisplaySubmit={props.setDisplaySubmit}
        ref={ref}
      />
    );
  }
};

export default forwardRef(ImageUploadProcess);
