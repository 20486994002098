import { ColorObject } from "@material-ui/core";
import {
  ADD_PROFILE,
  ADD_RUNNER,
  DELETE_PROFILES,
  DELETE_RUNNER,
  LIST_PROFILES,
  SET_ACTIVE_PROFILE,
  SET_FACE_IMAGE,
  SET_UPDATING,
} from "../actions/actionNames";
import { Coordinate, PairCoordinateType } from "../types/faceSearchTypes";
import { ProfilesActionTypes } from "../types/profilesTypes";

export interface FaceIdType {
  faceId: string;
  imgUrl?: string;
}

export interface EnrolmentType {
  enrolmentId: string;
  faces: Array<FaceIdType>;
  token?: string;
  imageUrl?: string;
  eyes?: PairCoordinateType;
  nose?: Coordinate;
  mouth?: PairCoordinateType;
  size?: number;
  permanent?: boolean;
}

export interface RunnerType {
  uid: string;
  name: string;
  enrolments: Array<EnrolmentType>;
  dateOfBirth?: Date;
  avatar?: string;
}

export const initialRunner = {
  uid: "",
  name: "",
  enrolments: new Array<EnrolmentType>(),
};

export interface ProfilesState {
  profileIds: Array<string> | null;
  activeProfile: RunnerType;
  updating: boolean;
}

const initialProfilesState: ProfilesState = {
  profileIds: null,
  activeProfile: initialRunner,
  updating: false,
};

export const profilesReducers = (
  state = initialProfilesState,
  actions: ProfilesActionTypes
) => {
  switch (actions.type) {
    case LIST_PROFILES:
      if (actions.profileIds !== undefined) {
        let currentRunners: Array<string> = state.profileIds
          ? [...state.profileIds]
          : [];
        //loop through current profiles list
        for (let i = 0; i < actions.profileIds.length; i++) {
          //check if a runner exist in the list
          let index = currentRunners.findIndex(
            (id) => id === actions.profileIds[i]
          );
          if (index < 0) {
            //if not, add to the list
            currentRunners.push(actions.profileIds[i]);
          }
        }
        return {
          ...state,
          profileIds: currentRunners,
        };
      }
      return state;

    case ADD_RUNNER:
      if (actions.id !== undefined) {
        let currentProfiles = state.profileIds ? [...state.profileIds] : [];
        let index = currentProfiles.findIndex((id) => id === actions.id);

        if (index > -1) {
          //runner is in the current list
          //do nothing for now
        } else {
          //add this runner to the lsit
          currentProfiles.push(actions.id);
        }
        return {
          ...state,
          profileIds: currentProfiles,
        };
      }
      return state;

    case DELETE_RUNNER:
      if (actions.uid !== undefined) {
        let currentRunners = state.profileIds ? [...state.profileIds] : [];
        let index = currentRunners.findIndex((id) => id === actions.uid);
        //removing the index which the uid has
        currentRunners.splice(index, 1);
        return {
          ...state,
          profileIds: currentRunners,
        };
      }
      return state;

    case ADD_PROFILE:
      if (actions.uid !== null) {
        let currentProfiles = state.profileIds ? [...state.profileIds] : [];
        currentProfiles.push(actions.uid);
        return {
          ...state,
          profileIds: currentProfiles,
        };
      }
      return state;
    case DELETE_PROFILES:
      if (actions.runners !== undefined) {
        return {
          ...state,
          //set profiles to a new empty array
          profileIds: new Array<string>(),
        };
      }
      return state;

    case SET_UPDATING:
      if (actions.status !== undefined) {
        return {
          ...state,
          updating: actions.status,
        };
      }
      return state;

    case SET_ACTIVE_PROFILE:
      if (actions.activeProfile !== undefined) {
        return {
          ...state,
          activeProfile: actions.activeProfile,
        };
      }
      return state;

    case SET_FACE_IMAGE:
      if (actions.enrolmentId !== undefined && actions.faceId !== undefined) {
        //check if enrolment exist
        let profile = { ...state.activeProfile };
        let enrolments = profile.enrolments ? [...profile.enrolments] : [];
        let enrolmentIndex = enrolments.findIndex(
          (i) => i.enrolmentId === actions.enrolmentId
        );

        if (enrolmentIndex > -1) {
          //enrolment exists
          let enrolment = enrolments[enrolmentIndex];
          //check if the faceId exists in the facesArray
          let faces = enrolment.faces ? [...enrolment.faces] : [];
          let faceIndex = faces.findIndex((i) => i.faceId === actions.faceId);

          if (faceIndex > -1) {
            //faceId exists
            //add/replace the image associate to this faceId with a new image
            faces[faceIndex].imgUrl = actions.faceImageUrl;
          } else {
            //faceId does not exists
            //append faceId to facesArray and add an image
            faces.push({
              faceId: actions.faceId,
              imgUrl: actions.faceImageUrl,
            });
          }
          //change existed facesArray to the newly modified facesArray
          enrolment.faces = faces;
          //update the existed enrolment
          enrolments[enrolmentIndex] = enrolment;
        } else {
          //enrolment does not exist
          //create a new enrolment
          let face: FaceIdType = {
            faceId: actions.faceId,
            imgUrl: actions.faceImageUrl,
          };
          enrolments.push({
            enrolmentId: actions.enrolmentId,
            faces: [face],
          });
        }
        //update the profiles state
        profile.enrolments = enrolments;

        return {
          ...state,
          activeProfile: profile,
        };
      }
      return state;
    default:
      return state;
  }
};
