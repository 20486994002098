import { createStyles, Theme } from "@material-ui/core";

export const iconStyles = (theme: Theme) =>
  createStyles({
    lightIcon: {
      color: theme.palette.primary.light,
      "&:hover": {
        color: theme.palette.primary.contrastText,
      },
    },
    shineIcon: {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.light,
      },
    },
    greenIcon: {
      color: theme.palette.primary.main,
    },
    inheritIcon: {
      fill: "currentColor",
      color: "currentColor",
    },
    mediumIcon: {
      width: "30px",
      height: "30px",
    },
    largeIcon: {
      width: "100px",
      height: "100px",
    },
    tinyIcon: {
      width: "20px",
      height: "20px",
    },
  });
