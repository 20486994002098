import React, { ReactNode, useState } from "react";
import classNames from "classnames";
import {
  createStyles,
  Drawer,
  Hidden,
  IconButton,
  List,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import { iconStyles } from "../../styles/iconStyles";
import { NavBarCloseWidth, NavBarWidth } from "../../styles/styleConstants";
import {
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon,
} from "@material-ui/icons";
import chroma from "chroma-js";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...iconStyles(theme),
    root: {
      display: "block",
      margin: "0 auto",
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.backgroundColor,
    },
    drawer: {
      width: NavBarWidth,
      height: "100%",
      flexShrink: 0,
      position: "fixed",
      zIndex: 2,
    },

    drawerClose: {
      width: NavBarCloseWidth,
    },
    drawerPaper: {
      width: NavBarWidth,
      backgroundColor: theme.palette.primary.main,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      width: NavBarCloseWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    header: {
      width: "100%",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: "38px",
      height: "38px",
    },
    hide: {
      display: "none !IMPORTANT",
    },
    title: {
      lineHeight: "38px",
      color: theme.palette.common.white,
      marginLeft: "8px",
    },
    wrap: {
      display: "block",
      width: "auto",
      height: "100%",
      marginLeft: NavBarWidth + 16,
      marginRight: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },
    wrapClose: {
      marginLeft: NavBarCloseWidth + 16,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("sm")]: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    },

    menuButton: {
      color:
        chroma.contrast(theme.palette.primary.main, "white") > 2
          ? "white"
          : "black",
    },
  })
);

type NavbarMenuPropsType = {
  children: ReactNode;
  menus: ReactNode;
  logo: string;
  title: string;
};

export const NavbarMenu = (props: NavbarMenuPropsType) => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(window.innerWidth > 1400);
  const [isHover, setIsHover] = useState(false);

  const handleDrawerOpen = (status: boolean) => () => {
    setIsDrawerOpen(status);
  };

  const handleDrawerHover = (status: boolean) => () => {
    if (!isDrawerOpen) {
      setIsHover(status);
    }
  };

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Drawer
          className={classNames(classes.drawer, {
            [classes.drawerClose]: !isDrawerOpen,
          })}
          variant="permanent"
          anchor="left"
          open={isDrawerOpen}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperClose]: !isDrawerOpen && !isHover,
            }),
          }}
        >
          <div className={classes.drawerHeader}>
            <div
              className={classNames(classes.header, {
                [classes.hide]: !isDrawerOpen && !isHover,
              })}
            >
              <img
                alt={props.title}
                src={props.logo}
                className={classes.logo}
              />
              <Typography variant="subtitle1" className={classes.title}>
                {props.title}
              </Typography>
            </div>
            {isDrawerOpen ? (
              <IconButton onClick={handleDrawerOpen(false)}>
                <ChevronLeftIcon className={classes.menuButton} />
              </IconButton>
            ) : (
              <IconButton onClick={handleDrawerOpen(true)}>
                <MenuIcon className={classes.menuButton} />
              </IconButton>
            )}
          </div>
          <List
            onMouseEnter={handleDrawerHover(true)}
            onMouseLeave={handleDrawerHover(false)}
          >
            {props.menus}
          </List>
        </Drawer>
      </Hidden>
      <main
        className={classNames(classes.wrap, {
          [classes.wrapClose]: !isDrawerOpen,
        })}
      >
        {props.children}
      </main>
    </div>
  );
};
