import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  createStyles,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import StarIcon from "@material-ui/icons/Star";
import { EnrolmentType, RunnerType } from "../../reducers/profilesReducers";
import { EnrolmentItem } from "./EnrolmentItem";
import { SingleCheckBox } from "../formInputs/SingleCheckBox";
import classNames from "classnames";
import { buttonStyles } from "../../styles/buttonStyles";
import { FaceDisplay } from "./FaceDisplay";
import { useFetchImageForEnrolments } from "../../helperFunctions/useFetchImageForEnrolments";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...buttonStyles(theme),
    grid: {
      width: "100%",
      height: "auto",
      overflow: "hidden",
      paddingLeft: "15px",
      paddingTop: "15px",
    },

    titleBar: {
      background:
        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
      height: "20%",
    },

    icon: {
      color: theme.palette.common.white,
    },

    checkBox: {
      position: "absolute",
      right: "-1px",
      top: "-1px",
      display: "block",
      cursor: "pointer",
      color: theme.palette.grey[400],
      padding: "0",
      "&$checked": {
        color: "#2196f3",
      },
    },
    checked: {},
  })
);

type PermanentPhotoPropsType = {
  // enrolments: Array<EnrolmentType>;
  profile: RunnerType;
  removePhoto?: Function;
  edit?: boolean;
  editClicked?: Function;
  column: number;
  displayTag?: boolean;
  peoplePage?: boolean;
};

export const PermanentPhotos = (props: PermanentPhotoPropsType) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [selecting, setSelecting] = useState(false);
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const [enrolments, setEnrolments] = useState<Array<EnrolmentType>>(
    props.profile.enrolments
  );

  useEffect(() => {
    setEnrolments(props.profile.enrolments);
    setLoading(false);
  }, [props]);

  useFetchImageForEnrolments(
    props.profile.uid,
    props.profile.enrolments,
    props.peoplePage
  );

  const handleEditClick = () => {
    props.editClicked && props.editClicked();
  };

  const resetSelection = () => {
    setSelectedIds([]);
    setSelecting(false);
  };

  const selectionIndex = (id: string): number => {
    let index = selectedIds.findIndex((i) => i === id);
    return index;
  };

  // const toggleCheckBoxDisplay = (name: string) => {
  //   setSelecting(!selecting);
  //   addOrRemoveSelection(name);
  // };

  const addOrRemoveSelection = (id: string) => {
    setSelecting(true);
    let currentList = [...selectedIds];
    let index = selectionIndex(id);
    if (index < 0) {
      //added if not in selected
      currentList.push(id);
      setSelectedIds(currentList);
    } else {
      //remove if currently selected
      currentList.splice(index, 1);
      setSelectedIds(currentList);
      //remove selection checkboxes if no image is selected
      if (currentList.length <= 0) setSelecting(false);
    }
  };

  const handleCheckBoxClick = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    setSelecting(true);
    addOrRemoveSelection(name);
  };

  const removeSelections = () => {
    console.log("removing..");
    props.removePhoto &&
      props.removePhoto(
        selectedIds,
        () => {
          console.log("remove done");
          resetSelection();
        },
        () => {
          console.log("remove fail");
        }
      );
  };

  let enrolmentList: Array<JSX.Element> = [];
  if (enrolments && enrolments.length > 0) {
    enrolmentList = enrolments.map(
      (enrolment) =>
        enrolment.faces && (
          <GridListTile key={"enrolment_" + enrolment.enrolmentId}>
            <EnrolmentItem
              imgUrl={enrolment.faces[0].imgUrl} //select image on click if currently selecting. Do nothing if not selecting
              selectImage={addOrRemoveSelection}
              // longClick={toggleCheckBoxDisplay}
              checked={selectionIndex(enrolment.enrolmentId) > -1}
              name={enrolment.enrolmentId}
            />
            {/* {props.displayTag && enrolment.permanent && (
              <GridListTileBar
                titlePosition="top"
                actionPosition="left"
                actionIcon={<StarIcon className={classes.icon} />}
                classes={{ root: classes.titleBar }}
              />
            )} */}

            <SingleCheckBox
              name={enrolment.enrolmentId}
              classes={{ root: classes.checkBox, checked: classes.checked }}
              checked={selectionIndex(enrolment.enrolmentId) > -1}
              circle
              onChange={handleCheckBoxClick}
            />
          </GridListTile>
        )
    );
  } else {
    enrolmentList = [<FaceDisplay key={"empty-default-person"} />];
  }

  if (loading) {
    return <CircularProgress />;
  } else
    return (
      <>
        <GridList
          cols={props.column}
          cellHeight="auto"
          spacing={10}
          className={classes.grid}
        >
          {enrolmentList}
        </GridList>
        {props.edit && (
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        )}

        {selecting && (
          <Button
            color="primary"
            variant="contained"
            // type="submit"
            className={classNames(classes.darkBtn)}
            onClick={removeSelections}
          >
            Remove selection
          </Button>
        )}
      </>
    );
};
