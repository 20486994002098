import {
  createStyles,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import PersonIcon from "@material-ui/icons/Person";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import EmailIcon from "@material-ui/icons/Email";
import { iconStyles } from "../../styles/iconStyles";
import {
  formText,
  formRightBtnBlock,
  inputStyles,
  bannerStyles,
} from "../../styles/generalStyles";
import LogoImage from "../../../images/AHA-Logo.png";
import React, { ChangeEvent, FormEvent, Fragment, useState } from "react";
import { SingleTextField } from "../formInputs/SingleTextField";
import { SubmitButton } from "../Buttons/SubmitButton";
import { ErrorDisplay, setErrorMessage } from "../ErrorDisplay";
import { Link } from "react-router-dom";
import {
  checkEmailFormat,
  checkPasswordStrength,
  checkUserNameFormat,
  PasswordStrength,
} from "../../helperFunctions/stringFunctions";
import { PasswordStrengthBox } from "./PasswordStrengthBox";
import { SignupSuccess } from "../../container/SignupSuccess";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...iconStyles(theme),
    ...inputStyles(theme),
    ...bannerStyles(theme),
    formText,
    formRightBtnBlock,
    wrapper: {
      width: "100%",
      maxWidth: "480px",
      margin: "0 auto",
      // paddingTop: "50px",

      backgroundColor: "inherit",
      height: "auto",
      border: "1px solid black",
    },
    formLogoWrapper: {
      width: "100%",
      maxWidth: "480px",
      margin: "0 auto",
      // paddingTop: "50px",
      textAlign: "center",
      backgroundColor: "inherit",
      height: "auto",
    },
    formLogo: {
      margin: "0 auto",
      width: "50%",
      padding: "10px",
      height: "auto",
      maxHeight: "150px",
      float: "none",
    },
    clear: {
      clear: "both",
    },
    titleText: {
      color: theme.palette.common.black,
    },
    textAlignLeft: {
      width: "100%",
      textAlign: "left",
    },
    titleMargin: {
      marginBottom: "30px",
    },
    iconBlock: {
      height: "54px",
      lineHeight: "110px",
    },
    formHint: {
      fontStyle: "italic",
      paddingRight: "10px",
    },
    formLink: {
      color: theme.palette.primary.main,
      textDecoration: "underline",
      fontWeight: 500,
    },
    panel: {
      width: "85%",
      margin: "auto",
    },
    switchText: {
      fontStyle: "italic",
    },
    bannerTitle: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      height: "50px",
    },
  })
);

type LoginFormPropsType = {
  userAction: Function;
  isSignup?: boolean;
};

export const LoginForm = (props: LoginFormPropsType) => {
  //states
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);
  // const [username, setUsername] = useState("");
  // const [user, setUser] = useState({
  //   firstname: "",
  //   lastname: "",
  //   password: "",
  // });
  const [signUpDone, setSignUpDone] = useState(false);
  const [user, setUser] = useState({
    username: "",
    password: "",
    passwordRepeat: "",
    email: "",
  });

  const [passwordDisplay, setPasswordDispaly] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState<PasswordStrength>({
    number: false,
    length: false,
    uppercase: false,
    lowercase: false,
    space: false,
  });
  const [id, setId] = useState("");
  const dispatch = useDispatch();

  const classes = useStyles();

  //functions
  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);
    if (props.isSignup) {
      //action for sign up
      props.userAction(
        //combining first name and last name to a single username
        //in order to use current api and database
        // user.firstname.trim().concat(user.lastname.trim()).trim(),
        user.username.trim(),
        user.password.trim(),
        user.email.trim(),
        signupDone,
        submitFail
      );
    } else {
      //action for login
      props.userAction(
        user.email.trim(),
        user.password.trim(),
        loginDone,
        submitFail
      );
    }
  };

  const loginDone = () => {
    const param = window.location.search;
    const searchParam = new URLSearchParams(param);
    //search for next parameter
    const next = searchParam.get("next");
    if (next) {
      //if found, redirect to predefined location
      window.location.href = next;
    } else {
      //redirect to default location
      window.location.href = "profile";
    }
  };

  const submitFail = (errors: Record<string, string>) => {
    setErrors(errors);
    setLoading(false);
  };

  const signupDone = (res: any) => {
    const user = JSON.parse(res);
    setId(user.id);
    setSignUpDone(true);
  };

  const handleUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setUser((state) => ({ ...state, [name]: value }));
    //clear credentials errors when user start input
    setErrors(setErrorMessage(errors, "credentials", ""));

    if (name === "username") {
      usernameErrorHandler(value);
    }

    if (name === "email") {
      emailErrorHandler(value);
    }

    if (name === "passwordRepeat") {
      passwordRepeatErrorHandler(value);
    }

    if (name === "password") {
      passwordErrorHandle(value);
      if (props.isSignup) {
        passwordStrengthErrorHandler(value);
      }
    }
  };

  const usernameErrorHandler = (value: string) => {
    setErrors(
      setErrorMessage(
        errors,
        "userName",
        !checkUserNameFormat(value)
          ? "Username could only contains these characters: a-zA-Z0-9-._@+/\\"
          : ""
      )
    );
  };

  const passwordRepeatErrorHandler = (value: string) => {
    let error = false;

    if (!!value && user.password !== value) {
      error = true;
    }

    setErrors(
      setErrorMessage(errors, "password", error ? "Passwords do not match" : "")
    );
  };

  const passwordErrorHandle = (value: string) => {
    let error = false;
    if (!!user.passwordRepeat && user.passwordRepeat !== value) {
      error = true;
    }

    setErrors(
      setErrorMessage(errors, "password", error ? "Passwords do not match" : "")
    );
  };

  const passwordStrengthErrorHandler = (value: string) => {
    let error = false;

    const checkStrength = checkPasswordStrength(passwordStrength, value);
    setPasswordStrength(checkStrength);

    if (Object.values(checkStrength).includes(false) && !!value) {
      error = true;
    }

    setErrors(
      setErrorMessage(
        errors,
        "passwordStrength",
        error ? "Password format incorrect" : ""
      )
    );
  };

  const emailErrorHandler = (value: string) => {
    setErrors(
      setErrorMessage(
        errors,
        "email",
        !!value && !checkEmailFormat(value) ? "Invalid email format" : ""
      )
    );
  };
  if (signUpDone && id) {
    return <SignupSuccess name={user.username} profileId={id} />;
  } else
    return (
      <div className={classes.wrapper}>
        <div className={classes.formLogoWrapper}>
          <img
            src={LogoImage}
            className={classes.formLogo}
            alt="Finsh Time Logo"
          />
        </div>
        <div className={classes.clear} />
        <div className={classes.formText}>
          <Typography
            variant="h5"
            className={classNames(classes.titleText, classes.titleMargin)}
          >
            {props.isSignup ? "Create a new account" : "Sign In"}
          </Typography>
        </div>
        <div className={classNames(classes.bannerTitle, classes.box)}>
          <Typography variant="subtitle1">
            Face Capture Module for AHA
          </Typography>
        </div>
        <form
          onSubmit={(!loading && handleFormSubmit) || undefined}
          className={classes.panel}
        >
          <Grid container spacing={1}>
            <Fragment>
              {props.isSignup && (
                <>
                  {" "}
                  <Grid item xs={1} className={classes.iconBlock}>
                    <PersonIcon
                      className={classNames(
                        classes.greenIcon,
                        classes.mediumIcon
                      )}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <SingleTextField
                      label="Username"
                      name="username"
                      inputType="text"
                      value={user.username}
                      onChange={handleUserChange}
                    />
                  </Grid>
                </>
              )}

              {/* <Grid item xs={11}>
              <SingleTextField
                label="First name"
                name="firstname"
                inputType="text"
                onChange={handleUserChange}
                value={user.firstname}
                isRequired={true}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <SingleTextField
                label="Last name"
                name="lastname"
                value={user.lastname}
                onChange={handleUserChange}
                isRequired={true}
                inputType="text"
              />
            </Grid> */}

              <Grid item xs={1} className={classes.iconBlock}>
                <EmailIcon
                  className={classNames(classes.greenIcon, classes.mediumIcon)}
                />
              </Grid>
              <Grid item xs={11}>
                <SingleTextField
                  label="Email"
                  name="email"
                  inputType="email"
                  value={user.email}
                  onChange={handleUserChange}
                  isRequired={props.isSignup}
                />
              </Grid>
              <Grid item xs={1} className={classes.iconBlock}>
                <LockOutlinedIcon
                  className={classNames(classes.greenIcon, classes.mediumIcon)}
                />
              </Grid>
              <Grid item xs={11}>
                <SingleTextField
                  label="Password"
                  name="password"
                  isRequired={true}
                  value={user.password}
                  onChange={handleUserChange}
                  inputType={passwordDisplay ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        className={classes.greenIcon}
                        onClick={() => setPasswordDispaly(!passwordDisplay)}
                      >
                        {passwordDisplay ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {props.isSignup && (
                  <PasswordStrengthBox
                    password={user.password}
                    passwordStrength={passwordStrength}
                  />
                )}
              </Grid>
              <Grid item xs={1} />
              {props.isSignup && (
                <Grid item xs={11}>
                  <SingleTextField
                    label="Confirm Password"
                    name="passwordRepeat"
                    value={user.passwordRepeat}
                    inputType="password"
                    onChange={handleUserChange}
                    isRequired={true}
                  />
                </Grid>
              )}
            </Fragment>
            <Grid item xs={12}>
              <ErrorDisplay errors={errors} />
            </Grid>
            <Grid item xs={12} className={classes.titleMargin}>
              <SubmitButton
                loading={loading}
                title={props.isSignup ? "Signup" : "Login"}
                fullWidth
                isMainButton
                disabled={Object.keys(errors).length > 0}
              />

              <Typography
                variant="subtitle1"
                className={classNames(classes.titleText, classes.textAlignLeft)}
              >
                <span className={classes.formHint}>Forget your password?</span>
                <Link to="/forgot" className={classes.formLink}>
                  Reset Here
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {props.isSignup ? (
                <Typography
                  variant="subtitle1"
                  className={classNames(
                    classes.titleText,
                    classes.textAlignLeft
                  )}
                >
                  <span className={classes.formHint}>
                    Already have an account?
                  </span>
                  <Link to="/login" className={classes.formLink}>
                    Login
                  </Link>
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  className={classNames(
                    classes.titleText,
                    classes.textAlignLeft
                  )}
                >
                  <span className={classes.formHint}>
                    Don't have an account?
                  </span>
                  <Link to="/signup" className={classes.formLink}>
                    Sign Up
                  </Link>
                </Typography>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    );
};
