import { CircleFaceType } from "../../ImagusComponents/Gadgets/ImagusFacePicker";
import {
  ADD_IMAGE_TO_FACE_SEARCH,
  CLEAR_FORM,
  DETECT_IMAGE,
  SAVE_TOKEN,
  SET_LOADED_IMAGE,
} from "../actions/actionNames";
import {
  FaceSearchActionTypes,
  FaceSearchResult,
} from "../types/faceSearchTypes";

export type FaceSearchState = {
  imageToken: string;
  loadedImage: string;
  detectedFaces: Array<CircleFaceType>;
  faceSearchingImage: string;
  faceSearchResult: FaceSearchResult;
};

const initialFaceSearchState: FaceSearchState = {
  imageToken: "",
  loadedImage: "",
  detectedFaces: [],
  faceSearchingImage: "",
  faceSearchResult: { searchResults: [], token: "" },
};

export const faceSearchReducers = (
  state = initialFaceSearchState,
  actions: FaceSearchActionTypes
) => {
  switch (actions.type) {
    case SAVE_TOKEN:
      if (actions.token !== null) {
        return {
          ...state,
          imageToken: actions.token,
        };
      }
      return state;

    case SET_LOADED_IMAGE:
      if (actions.image !== null) {
        return {
          ...state,
          loadedImage: actions.image,
        };
      }
      return state;

    case DETECT_IMAGE: {
      if (actions.detectedFaces !== null) {
        return {
          ...state,
          detectedFaces: actions.detectedFaces,
        };
      }
      return state;
    }
    case CLEAR_FORM:
      return { ...initialFaceSearchState };
    default:
      return state;
  }
};
