import {
  CircularProgress,
  IconButton,
  InputAdornment,
  Theme,
} from "@material-ui/core";
import { Fragment, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { SingleTextField } from "../../global/components/formInputs/SingleTextField";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { iconStyles } from "../../global/styles/iconStyles";
import { SingleCheckBox } from "../../global/components/formInputs/SingleCheckBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...iconStyles(theme),
    loadingIcon: {
      width: "24px",
      height: "24px",
    },
  })
);

type ImagusSearchBarPropType = {
  updating?: string;
  searchQuery: string;
  handleSearch: Function;
};

export const ImagusSearchBar = (props: ImagusSearchBarPropType) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(props.searchQuery || "");
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
    props.handleSearch(e.currentTarget.value);
  };
  return (
    <Fragment>
      <SingleTextField
        label="Search..."
        name="inputValue"
        value={inputValue}
        inputType="text"
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            {loading ? (
              <CircularProgress
                size={18}
                color="primary"
                className={classes.loadingIcon}
              />
            ) : (
              <IconButton>
                <SearchIcon className={classes.greenIcon} />
              </IconButton>
            )}
          </InputAdornment>
        }
      />
    </Fragment>
  );
};
