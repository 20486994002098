import { Grid, RadioGroup } from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../Store";

import { changeTheme } from "../actions/siteActions";
import { ThemeRadio } from "../components/ThemeRadio";

export const ThemeSelector = () => {
  const currentTheme = useSelector(
    (state: AppState) => state.admin.site.themeName
  );

  // const currentTheme = useSelector<SiteState, SiteState["themeName"]>(
  //   (state) => state.themeName
  // );

  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState(currentTheme);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedValue(event.target.value);
    dispatch(changeTheme(event.target.value));
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <RadioGroup value={selectedValue} onChange={handleChange} row>
          <ThemeRadio theme="blue" />
          <ThemeRadio theme="dark" />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};
