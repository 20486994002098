import { Fragment, ReactElement } from "react";
import { useLocation, Link } from "react-router-dom";
import { NavbarMenuItem } from "./NavbarMenuItem";

type AuthedNavbarMenuItemProps = {
  text: string;
  icon: ReactElement;
  auth?: Array<string>;
  to?: string;
  onClick?: any;
};

export const AuthedNavbarMenuItem = (props: AuthedNavbarMenuItemProps) => {
  const { to, ...other } = props;
  const location = useLocation();

  const pathname = location.pathname;
  const pathnameArray = pathname.split("/");
  const lastPath = pathnameArray[pathnameArray.length - 1];

  const Navbar = <NavbarMenuItem selected={lastPath === to} {...other} />;
  const LinkNavbar = to && <Link to={to}>{Navbar}</Link>;

  return <Fragment>{to ? LinkNavbar : Navbar}</Fragment>;
};
