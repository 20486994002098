import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import AdminReducers from "./admin/reducers/index";
import GlobalReducers from "./global/reducers/index";
import { composeWithDevTools } from "redux-devtools-extension";

const composer = composeWithDevTools(applyMiddleware(thunkMiddleware));

const reducers = combineReducers({
  admin: AdminReducers,
  global: GlobalReducers,
});

const Store = createStore(reducers, composer);

export type AppState = ReturnType<typeof reducers>;
export default Store;
