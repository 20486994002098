import {
  createStyles,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import classNames from "classnames";
import PersonIcon from "@material-ui/icons/Person";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import EmailIcon from "@material-ui/icons/Email";
import { iconStyles } from "../../styles/iconStyles";
import {
  formText,
  formRightBtnBlock,
  inputStyles,
  bannerStyles,
} from "../../styles/generalStyles";
import LogoImage from "../../../images/TracyWhiteBackground.png";
import React, { ChangeEvent, FormEvent, Fragment, useState } from "react";
import { SingleTextField } from "../formInputs/SingleTextField";
import { SubmitButton } from "../Buttons/SubmitButton";
import { ErrorDisplay, setErrorMessage } from "../ErrorDisplay";
import { Link, useHistory } from "react-router-dom";
import {
  checkEmailFormat,
  checkPasswordStrength,
  checkUserNameFormat,
  PasswordStrength,
} from "../../helperFunctions/stringFunctions";
import { PasswordStrengthBox } from "./PasswordStrengthBox";
import { SignupSuccess } from "../../container/SignupSuccess";
import { useDispatch } from "react-redux";
import { CancelButton } from "../Buttons/CancelButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...iconStyles(theme),
    ...inputStyles(theme),
    ...bannerStyles(theme),
    formText,
    formRightBtnBlock,
    wrapper: {
      width: "100%",
      maxWidth: "480px",
      margin: "10% auto",

      backgroundColor: "inherit",
      height: "auto",
      border: "1px solid black",
    },
    formLogo: {
      width: "100%",
      margin: "2% 0",
      padding: "10px",
      height: "auto",
      maxHeight: "150px",
      float: "none",
    },
    clear: {
      clear: "both",
    },
    titleText: {
      color: theme.palette.common.black,
    },
    textAlignLeft: {
      width: "100%",
      textAlign: "left",
    },
    titleMargin: {
      marginBottom: "30px",
    },
    iconBlock: {
      height: "54px",
      lineHeight: "130px",
    },
    formHint: {
      fontStyle: "italic",
      paddingRight: "10px",
    },
    formLink: {
      color: theme.palette.primary.main,
      textDecoration: "underline",
      fontWeight: 500,
    },
    panel: {
      width: "85%",
      margin: "auto",
    },
    switchText: {
      fontStyle: "italic",
    },
    bannerTitle: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      height: "50px",
    },
  })
);

type ForgotFormPropsType = {
  forgotPassword: Function;
};

export const ForgotForm = (props: ForgotFormPropsType) => {
  const classes = useStyles();
  //states
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");

  const history = useHistory();

  //functions
  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    props.forgotPassword(email, submitDone, submitFail);
  };

  const submitDone = () => {
    history.push("./reset");
  };

  const submitFail = (errors: Record<string, string>) => {
    setErrors(errors);
    setLoading(false);
  };

  const backToLogin = () => {
    history.push("./login");
  };

  const handleUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
    emailErrorHandler(value);
  };

  const emailErrorHandler = (value: string) => {
    setErrors(
      setErrorMessage(
        errors,
        "email",
        !!value && !checkEmailFormat(value) ? "Invalid email format" : ""
      )
    );
  };

  return (
    <div className={classes.wrapper}>
      <img src={LogoImage} className={classes.formLogo} alt="Finsh Time Logo" />
      <div className={classes.clear} />
      <div className={classes.formText}>
        <Typography
          variant="h5"
          className={classNames(classes.titleText, classes.titleMargin)}
        >
          Forgot your Password?
        </Typography>
      </div>
      <div className={classNames(classes.box)}>
        <Typography variant="subtitle1">
          If you have forgot your password, please enter your email address and
          we'll send you a token to reset your password
        </Typography>
      </div>
      <form
        onSubmit={(!loading && handleFormSubmit) || undefined}
        className={classes.panel}
      >
        <Grid container spacing={1}>
          <Fragment>
            <Grid item xs={1} className={classes.iconBlock}>
              <EmailIcon
                className={classNames(classes.greenIcon, classes.mediumIcon)}
              />
            </Grid>
            <Grid item xs={11}>
              <SingleTextField
                label="Email"
                name="email"
                inputType="email"
                value={email}
                onChange={handleUserChange}
                isRequired
              />
            </Grid>
          </Fragment>
          <Grid item xs={12}>
            <ErrorDisplay errors={errors} />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} className={classes.titleMargin}>
            <CancelButton onClick={backToLogin} />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5} className={classes.titleMargin}>
            <SubmitButton
              loading={loading}
              title="Send email"
              fullWidth
              isMainButton
              disabled={Object.keys(errors).length > 0}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
