import {
  createStyles,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  Theme,
} from "@material-ui/core";
import classNames from "classnames";
import { ReactElement } from "react";
import { inputStyles } from "../../styles/generalStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...inputStyles(theme),
  })
);

type SingleTextFieldPropsType = {
  label: string;
  name: string;
  value?: string;
  inputType: string;
  onChange: Function;
  className?: string;
  isRequired?: boolean;
  isInvalid?: boolean;
  isAutoFocus?: boolean;
  isDisabled?: boolean;
  onBlur?: Function;
  onFocus?: Function;
  onEnter?: Function;
  endAdornment?: ReactElement;
  helper?: string;
  inputStyle?: string;
  autoFocus?: boolean;
};

export const SingleTextField = (props: SingleTextFieldPropsType) => {
  const classes = useStyles();

  const handleChange = (e: React.ChangeEvent) => props.onChange(e);
  return (
    <FormControl fullWidth={true} disabled={props.isDisabled}>
      {props.label !== "" && (
        <InputLabel
          htmlFor={props.name}
          required={props.isRequired}
          error={props.isInvalid}
          // shrink={true}
          classes={{
            root: classes.label,
            formControl: classes.labelFormControl,
            focused: classes.labelFocused,
            error: classes.labelError,
            disabled: classes.labelDisabled,
          }}
        >
          {props.label}
        </InputLabel>
      )}

      <Input
        id={props.name}
        name={props.name}
        value={props.value}
        // className={props.className}
        classes={{
          root: classes.inputRoot,
          input: classes.inputFont,
          underline: classes.underline,
          error: classes.inputError,
          disabled: classes.inputDisabled,
        }}
        error={props.isInvalid}
        onChange={handleChange}
        endAdornment={props.endAdornment}
        disabled={props.isDisabled}
        type={props.inputType}
        autoComplete={props.value}
        required={props.isRequired}
        autoFocus={props.autoFocus}
      />
    </FormControl>
  );
};
