import { Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { SingleTextField } from "../formInputs/SingleTextField";
import { formRightBtnBlock } from "../../styles/generalStyles";
import { ErrorDisplay, setErrorMessage } from "../ErrorDisplay";
import { CancelButton } from "../Buttons/CancelButton";
import { SubmitButton } from "../Buttons/SubmitButton";
import { PasswordStrengthBox } from "./PasswordStrengthBox";
import {
  checkPasswordStrength,
  PasswordStrength,
} from "../../helperFunctions/stringFunctions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRightBtnBlock,
  })
);

type PasswordType = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

type ChangePasswordFormPropsType = {
  onClose: Function;
  changePassword: Function;
};

export const ChangePasswordForm = (props: ChangePasswordFormPropsType) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [passwords, setPasswords] = useState<PasswordType>({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  });

  const [passwordStrength, setPasswordStrength] = useState<PasswordStrength>({
    number: false,
    length: false,
    uppercase: false,
    lowercase: false,
    space: false,
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setPasswords({ ...passwords, [name]: value });

    if (name === "oldPassword") {
      //clear errors on input
      setErrors(setErrorMessage(errors, "currentPassword", ""));
    }

    if (name === "newPassword") {
      handleNewPasswordInputChange(value);
    }

    if (name === "newPasswordConfirm") {
      handlePasswordConfirm(value);
    }
  };

  const handleNewPasswordInputChange = (value: string) => {
    //check password strength
    const checkedStrength = checkPasswordStrength(passwordStrength, value);
    setPasswordStrength(checkedStrength);
    console.log(checkedStrength);
    if (Object.values(checkedStrength).includes(false)) {
      setErrors(
        setErrorMessage(errors, "passwordStrength", "Invalid password format")
      );
    } else {
      setErrorMessage(errors, "passwordStrength", "");
    }

    //check matching if new password exist
    if (
      !!passwords.newPasswordConfirm &&
      passwords.newPasswordConfirm !== value
    ) {
      setErrorMessage(errors, "password", "Password do not matched");
    } else {
      setErrorMessage(errors, "password", "");
    }
  };

  const handlePasswordConfirm = (value: string) => {
    //check matching
    if (!!value && value !== passwords.newPassword) {
      setErrorMessage(errors, "password", "Password do not matched");
    } else {
      setErrorMessage(errors, "password", "");
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (Object.keys(errors).length === 0) {
      setLoading(true);
      props.changePassword(
        passwords.oldPassword,
        passwords.newPassword,
        submitDone,
        submitFail
      );
    }
  };

  const submitDone = () => {
    props.onClose();
  };

  const submitFail = (error: Record<string, string>) => {
    // setErrors({ errors: error });
    setErrors(error);
    setLoading(false);
  };

  return (
    <form onSubmit={(!loading && handleSubmit) || undefined}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SingleTextField
            label="Old Password"
            name="oldPassword"
            inputType="password"
            value={passwords.oldPassword}
            onChange={handleInputChange}
            // className={classes.input}
            // inputStyle={classes.input}
          />
        </Grid>
        <Grid item xs={12}>
          <SingleTextField
            label="New Password"
            name="newPassword"
            inputType="password"
            value={passwords.newPassword}
            onChange={handleInputChange}
            // className={classes.input}
          />
          <PasswordStrengthBox
            password={passwords.newPassword}
            passwordStrength={passwordStrength}
          />
        </Grid>
        <Grid item xs={12}>
          <SingleTextField
            label="Confirm New Password"
            name="newPasswordConfirm"
            inputType="password"
            value={passwords.newPasswordConfirm}
            onChange={handleInputChange}
            isRequired
            // className={classes.input}
          />
        </Grid>
        <Grid item xs={12}>
          <ErrorDisplay errors={errors} />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.formRightBtnBlock}>
            <CancelButton onClick={props.onClose} />
            <SubmitButton
              loading={loading}
              disabled={Object.keys(errors).length > 0}
            />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
