import { Button, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DoneIcon from "@material-ui/icons/Done";
import chroma from "chroma-js";
import classNames from "classnames";
import { useState } from "react";
import { buttonStyles } from "../../styles/buttonStyles";
import { iconStyles } from "../../styles/iconStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...buttonStyles(theme),
    ...iconStyles(theme),
    fileUploadButton: {
      //Mui-Button style (in used because MuiButton do not work with label)
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      lineHeight: 1.75,

      //custom styling
      height: "270px",
      color: theme.palette.grey[500],
      textTransform: "capitalize",
      padding: "22px 0",
      borderRadius: "3px",
      boxShadow: "0 0 0 1px " + theme.palette.primary.main,
      textAlign: "center",
      width: "100%",
      backgroundColor: theme.palette.contentBackgroundColor,
      "&:hover, &.hover, &:active, &.active": {
        cursor: "pointer",
        backgroundColor: theme.palette.primary.light,
        color:
          chroma.contrast(theme.palette.primary.light, "white") > 2
            ? "white"
            : "black",
      },
      "& > SPAN": {
        display: "block",
      },
    },
    disabled: {
      backgroundColor: theme.palette.grey[200],
      "&:hover, &.hover, &:active, &.active": {
        cursor: "not-allowed",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[500],
      },
      "& > SPAN": {
        display: "block",
      },
    },
    fileUploadText: {
      fontSize: "1.5rem",
      lineHeight: "30px",
    },
    fileUploadInput: {
      opacity: 0,
      width: "0.1px",
      "&:hover, &.hover": {
        cursor: "pointer",
      },
    },
  })
);

type DraggableUploadButtonPropsType = {
  uploadFileAction: Function;
  disabled?: boolean;
};

export const DraggableUploadButton = (
  props: DraggableUploadButtonPropsType
) => {
  const classes = useStyles();
  const [dragged, setDragged] = useState(false);

  const customPreventDefault = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const inputDragEnter = (event: React.DragEvent) => {
    customPreventDefault(event);
    setDragged(true);
  };

  const inputDragLeave = (event: React.DragEvent) => {
    customPreventDefault(event);
    setDragged(false);
  };

  const inputDrop = (event: React.DragEvent) => {
    customPreventDefault(event);
    if (event.dataTransfer.files) {
      let file = event.dataTransfer.files[0];
      props.uploadFileAction(file);
    } else {
      //set error
    }
  };

  const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      let file = event.target.files[0];
      props.uploadFileAction(file);
    } else {
      //set Error
    }
  };
  return (
    <div>
      <label
        htmlFor="photo-input-button"
        draggable={true}
        onDragEnter={inputDragEnter}
        onDragLeave={inputDragLeave}
        onDragOver={customPreventDefault}
        onDrop={inputDrop}
      >
        <div
          className={classNames(
            classes.primaryBtn,
            classes.fileUploadButton,
            props.disabled && classes.disabled
          )}
        >
          <span className={classes.fileUploadText}>
            Drag and Drop
            <br />
            Photo here.
            <br />
            Or Click to Upload
          </span>
          {dragged ? (
            <DoneIcon className={classes.largeIcon} />
          ) : (
            <CloudUploadIcon className={classes.largeIcon} />
          )}
        </div>
      </label>
      <input
        onChange={inputChange}
        accept="image/*"
        id="photo-input-button"
        type="file"
        className={classes.fileUploadInput}
        disabled={props.disabled}
      />
    </div>
  );
};
