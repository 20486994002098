import { createStyles, makeStyles, Theme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";

import { PasswordStrength } from "../../helperFunctions/stringFunctions";
import { iconStyles } from "../../styles/iconStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...iconStyles(theme),
    root: {
      position: "relative",
      width: "40%",
      minWidth: "218px",
      padding: "5px 20px",
      borderRadius: "15px",
      border: "1px solid " + theme.palette.grey[300],
      backgroundColor: "rgb(238,238,238)",
      boxShadow: "5px 5px 5px #888888",
      "&:before": {
        position: "absolute",
        bottom: "100%",
        content: "''",
        right: "52px",
        borderRight: "17px solid transparent",
        borderBottom: "17px solid " + theme.palette.grey[300],
        borderLeft: "17px solid transparent",
      },
      "&:after": {
        position: "absolute",
        bottom: "100%",
        content: "''",
        right: "53px",
        borderRight: "16px solid transparent",
        borderBottom: "16px solid rgb(238,238,238)",
        borderLeft: "16px solid transparent",
      },
      "& $title": {
        margin: "0 0 5px 0",
        fontWeight: "bold",
        fontSize: "0.9375rem",
      },
      "& ul": {
        padding: 0,
        margin: 0,
        listStyleType: "none",
        "& li": {
          height: "24px",
          minHeight: "20px",
          lineHeight: "22px",
          fontSize: "0.75rem",
          "& SVG": {
            verticalAlign: "top",
          },
        },
      },
      "& $invalid": {
        color: theme.palette.error.main,
      },
      "& $valid": {
        color: theme.palette.success.main,
      },
    },
    title: {},
    invalid: {},
    valid: {},
  })
);

type PasswordStrengthBoxPropsType = {
  password: string;
  passwordStrength: PasswordStrength;
};

export const PasswordStrengthBox = (props: PasswordStrengthBoxPropsType) => {
  const classes = useStyles();

  const shouldDisplay = (): boolean => {
    return (
      Object.values(props.passwordStrength).includes(false) && !!props.password
    );
  };

  if (shouldDisplay()) {
    return (
      <div className={classes.root}>
        <div className={classes.title}>Password Rules:</div>
        <ul>
          <li
            className={
              props.passwordStrength.length ? classes.valid : classes.invalid
            }
          >
            {props.passwordStrength.length ? (
              <DoneIcon className={classes.tinyIcon} />
            ) : (
              <CloseIcon className={classes.tinyIcon} />
            )}
            At least <strong>8 characters</strong>
          </li>
          <li
            className={
              props.passwordStrength.uppercase ? classes.valid : classes.invalid
            }
          >
            {props.passwordStrength.uppercase ? (
              <DoneIcon className={classes.tinyIcon} />
            ) : (
              <CloseIcon className={classes.tinyIcon} />
            )}
            At least <strong>one uppercase letter</strong>
          </li>
          <li
            className={
              props.passwordStrength.lowercase ? classes.valid : classes.invalid
            }
          >
            {props.passwordStrength.lowercase ? (
              <DoneIcon className={classes.tinyIcon} />
            ) : (
              <CloseIcon className={classes.tinyIcon} />
            )}
            At least <strong>one lowercase letter</strong>
          </li>
          <li
            className={
              props.passwordStrength.number ? classes.valid : classes.invalid
            }
          >
            {props.passwordStrength.number ? (
              <DoneIcon className={classes.tinyIcon} />
            ) : (
              <CloseIcon className={classes.tinyIcon} />
            )}
            At least <strong>one number</strong>
          </li>
          <li
            className={
              props.passwordStrength.space ? classes.valid : classes.invalid
            }
          >
            {props.passwordStrength.space ? (
              <DoneIcon className={classes.tinyIcon} />
            ) : (
              <CloseIcon className={classes.tinyIcon} />
            )}
            No <strong>whitespace</strong>
          </li>
        </ul>
      </div>
    );
  } else return null;
};
