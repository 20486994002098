import { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Search } from "history";
import { useEffect, useRef, useState } from "react";
import {
  Coordinate,
  PairCoordinateType,
} from "../../global/types/faceSearchTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
    },

    canvas: {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },

    square: {
      fill: theme.palette.common.white,
      fillOpacity: 0.05,
      transformOrigin: "centre",
      transformBox: "fill-box",
      stroke: theme.palette.grey[400],
      "&$selected": {
        stroke: theme.palette.success.light,
      },
    },
    header: {
      alignmentBaseline: "central",
      textAnchor: "middle",
      fontSize: "1rem",
      fontWeight: 500,
      fill: theme.palette.grey[400],
      "&$selected": {
        fill: theme.palette.success.light,
      },
    },
    eye: {
      strokeWidth: 1,
      fill: theme.palette.grey[400],
      stroke: theme.palette.grey[400],
      "&$selected": {
        stroke: theme.palette.success.light,
        fill: theme.palette.success.light,
      },
    },
    selected: {},
  })
);
type SearchType = {
  probability: number;
  searchName: string;
  profileId: string;
};
type SquareFaceType = {
  square: {
    start: Coordinate;
    width: number;
    angle: number;
  };
  eyes: PairCoordinateType;
  mouth: PairCoordinateType;
  nose: Coordinate;
  search: SearchType;
};

type ResultMetricsType = {
  matchBlurrinessThreshold: number;
  matchConfidenceThreshold: number;
  matchFrontalThreshold: number;
  isFace: boolean;
};

export type CircleFaceType = {
  eyes: PairCoordinateType;
  mouth: PairCoordinateType;
  nose: Coordinate;
  centre: Coordinate;
  size: number;
  search: SearchType;
  angle: number;
  blurriness: number;
  quality: number;
  resultMetrics: ResultMetricsType;
  frontalness: number;
};

export type ImagusFacePickerPropsType = {
  imageUrl: string;
  faces: Array<CircleFaceType>;
  name?: string;
  changeEyeSelection: Function;
};

export const ImagusFacePicker = (props: ImagusFacePickerPropsType) => {
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState("");
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [faces, setFaces] = useState<Array<SquareFaceType>>([]);
  const [faceSelection, setFaceSelection] = useState<number>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    loadImage();
  }, [props.faces]);

  /** Load selected image */
  const loadImage = () => {
    setImageUrl(props.imageUrl);
    let img = new Image();

    img.onload = () => {
      const imgWidth = img.width;
      const imgHeight = img.height;

      let containerWidth = 0;
      if (ref.current) {
        containerWidth = ref.current.offsetWidth;
      }

      const width = containerWidth;
      const height = (containerWidth * imgHeight) / imgWidth;

      setImageSize({ width: width, height: height });
      loadFaces(width / imgWidth);
    };
    img.src = props.imageUrl;
  };

  /** Detect faces in the image */
  const loadFaces = (ratio: number) => {
    const faces: Array<SquareFaceType> = props.faces.map(
      (face: CircleFaceType) => {
        let squareFace: SquareFaceType = {
          square: {
            start: {
              x: face.centre.x * ratio - (face.size * ratio) / 2,
              y: face.centre.y * ratio - (face.size * ratio) / 2,
            },
            width: face.size * ratio,
            angle: face.angle,
          },
          eyes: {
            left: {
              x: face.eyes.left.x * ratio,
              y: face.eyes.left.y * ratio,
            },
            right: {
              x: face.eyes.right.x * ratio,
              y: face.eyes.right.y * ratio,
            },
          },
          mouth: {
            left: {
              x: face.mouth.left.x * ratio,
              y: face.mouth.left.y * ratio,
            },
            right: {
              x: face.mouth.right.x * ratio,
              y: face.mouth.right.y * ratio,
            },
          },
          nose: {
            x: face.nose.x * ratio,
            y: face.nose.y * ratio,
          },
          search:
            //only display the name if match the profile

            face.search && {
              probability: face.search.probability,
              searchName: face.search.searchName,
              profileId: face.search.profileId,
            },
        };

        return squareFace;
      }
    );

    let selected: number | null = null;
    if (faces.length > 0) {
      selected = 0;
    }

    setFaces(faces);
    if (selected !== null) {
      setFaceSelection(selected);
      props.changeEyeSelection(props.faces[selected].eyes, selected);
    }
  };

  const selectFace = (index: number) => {
    setFaceSelection(index);
    props.changeEyeSelection(props.faces[index].eyes, index);
  };

  return (
    <div className={classes.container} ref={ref}>
      <div
        className={classes.canvas}
        style={{
          backgroundImage: "url(" + imageUrl + ")",
          width: imageSize.width,
          height: imageSize.height,
        }}
      >
        <svg width="100%" height="100%">
          {faces &&
            faces.map((eachFace: SquareFaceType, i: number) => {
              return (
                <g
                  key={"eachFace_" + i}
                  onClick={() => {
                    selectFace(i);
                  }}
                >
                  {/**Drawing the square around the face */}
                  <rect
                    x={eachFace.square.start.x}
                    y={eachFace.square.start.y}
                    width={eachFace.square.width}
                    height={eachFace.square.width}
                    className={classNames(
                      classes.square,
                      i === faceSelection && classes.selected
                    )}
                    style={{
                      transform: "rotate(" + eachFace.square.angle + "deg)",
                      strokeWidth: Math.max(eachFace.square.width / 100, 2),
                    }}
                  />
                  {/**Putting the name on the square if probability is > 0.5 */}
                  {eachFace.search && eachFace.search.probability > 0.5 && (
                    <text
                      x={eachFace.square.start.x + eachFace.square.width / 2}
                      y={eachFace.square.start.y + eachFace.square.width - 20}
                      className={classNames(
                        classes.header,
                        i === faceSelection && classes.selected
                      )}
                      transform={`rotate(${eachFace.square.angle},${
                        eachFace.square.start.x + eachFace.square.width / 2
                      }, ${eachFace.square.start.y - 20})`}
                    >
                      {eachFace.search.searchName +
                        `(${(eachFace.search.probability * 100).toFixed(1)}%)`}
                    </text>
                  )}

                  {/**Drawing the eyes */}
                  <circle
                    cx={eachFace.eyes.left.x}
                    cy={eachFace.eyes.left.y}
                    r={eachFace.square.width * 0.02}
                    className={classNames(
                      classes.eye,
                      i === faceSelection && classes.selected
                    )}
                  />
                  <circle
                    cx={eachFace.eyes.right.x}
                    cy={eachFace.eyes.right.y}
                    r={eachFace.square.width * 0.02}
                    className={classNames(
                      classes.eye,
                      i === faceSelection && classes.selected
                    )}
                  />
                  {/** Drawing the nose */}
                  <circle
                    cx={eachFace.nose.x}
                    cy={eachFace.nose.y}
                    r={eachFace.square.width * 0.02}
                    className={classNames(
                      classes.eye,
                      i === faceSelection && classes.selected
                    )}
                  />
                  {/**Drawing the mouth */}
                  <circle
                    cx={eachFace.mouth.left.x}
                    cy={eachFace.mouth.left.y}
                    r={eachFace.square.width * 0.02}
                    className={classNames(
                      classes.eye,
                      i === faceSelection && classes.selected
                    )}
                  />
                  <circle
                    cx={eachFace.mouth.right.x}
                    cy={eachFace.mouth.right.y}
                    r={eachFace.square.width * 0.02}
                    className={classNames(
                      classes.eye,
                      i === faceSelection && classes.selected
                    )}
                  />
                </g>
              );
            })}
        </svg>
      </div>
    </div>
  );
};
