import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { panelStyles } from "../styles/generalStyles";
import { LoginForm } from "../components/users/LoginForm";
import { userLogin } from "../actions/userActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...panelStyles(theme),
    maxHeight: {
      height: "100%",
    },

    padding: {
      paddingTop: "30px",
    },

    // center: {
    //   position: "absolute",
    //   top: "50%",
    //   left: "50%",
    //   transform: "translate(-50%, -55%)",
    // },
  })
);

export const Login = () => {
  const classes = useStyles();

  const handleUserLogin = (
    email: string,
    password: string,
    doneBack?: Function,
    failBack?: Function
  ) => {
    userLogin(email, password, doneBack, failBack);
  };

  return (
    <div
      className={classNames(
        classes.appMain,
        classes.maxHeight,
        classes.padding
      )}
    >
      <LoginForm userAction={handleUserLogin} />
    </div>
  );
};
