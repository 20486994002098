import React from "react";
import {
  createStyles,
  GridList,
  GridListTile,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import classNames from "classnames";
import RemoveIcon from "@material-ui/icons/Cancel";
import { iconStyles } from "../../styles/iconStyles";
import PersonImage from "../../../images/PersonDefault.svg";
import useLongPress from "../../helperFunctions/useLongPress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...iconStyles(theme),
    gridRoot: {
      textAlign: "center",
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    gridBlock: {
      width: "100%",
      height: "auto",
      position: "relative",
    },
    closeButton: {
      position: "absolute",
      right: "-1px",
      top: "-1px",
      display: "none",
      cursor: "pointer",
      color: "#e100ff",
      padding: "0",
    },
    gridImage: {
      width: "100%",
      height: "100%",
      minWidth: "inherit",
      transform: "translateY(0%)",
      top: "0%",
      left: "0%",
      border: "none",
      transition: theme.transitions.create("border", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    item: {
      width: "100%",
      height: "100%",
      maxHeight: "150.8px",
      maxWidth: "150.8px",
      // margin: "0 15px 15px 0",
      float: "left",
      "&:hover": {
        "& $closeButton": {
          display: "block",
        },
      },
    },

    displayChecked: {
      transition: theme.transitions.create("border", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
      }),
      border: "10px solid black",
    },

    checked: {},
  })
);

type FaceDisplayPropsType = {
  img?: string | undefined;
  longClick?: Function;
  isZoomIn?: boolean;
  checked?: boolean;
  selectImage?: Function;
  permanent?: boolean;
  name?: string;
};

export const FaceDisplay = (props: FaceDisplayPropsType) => {
  const classes = useStyles();

  const onLongPress = (e: React.MouseEvent | React.TouchEvent) => {
    props.longClick && props.longClick(props.name);
  };

  const onClick = () => {
    props.selectImage && props.selectImage(props.name);
  };

  const longPressEvent = useLongPress({ onLongPress, onClick });

  return (
    <div className={classes.item}>
      <img
        src={props.img ? props.img : PersonImage}
        alt="your face"
        className={classNames(
          classes.gridImage,
          props.checked && classes.displayChecked
        )}
        // {...longPressEvent}
        onClick={onClick}
      />
    </div>
  );
};
