import { RunnerType } from "../reducers/profilesReducers";
import { Race } from "../reducers/raceReducers";

export const checkUserNameFormat = (val: string): boolean => {
  return /^[a-zA-Z0-9-._@+/\\]+$/.test(val);
};

export const checkEmailFormat = (val: string): boolean => {
  return /^[a-z0-9][-a-z0-9.!#$%&'*+-=?^_`{|}~\/]+@([-a-z0-9]+\.)+[a-z]{2,5}$/.test(
    val
  );
};

export type PasswordStrength = {
  number: boolean;
  lowercase: boolean;
  uppercase: boolean;
  length: boolean;
  space: boolean;
};

export const initalPasswordStrength: PasswordStrength = {
  number: false,
  lowercase: false,
  uppercase: false,
  length: false,
  space: false,
};

export const checkPasswordStrength = (
  passwordStrength: PasswordStrength,
  val: string
): PasswordStrength => {
  if (/\d/.test(val)) {
    passwordStrength["number"] = true;
  } else {
    passwordStrength["number"] = false;
  }
  if (/[a-z]/.test(val)) {
    passwordStrength["lowercase"] = true;
  } else {
    passwordStrength["lowercase"] = false;
  }
  if (/[A-Z]/.test(val)) {
    passwordStrength["uppercase"] = true;
  } else {
    passwordStrength["uppercase"] = false;
  }
  if (val.trim().length >= 8) {
    passwordStrength["length"] = true;
  } else {
    passwordStrength["length"] = false;
  }
  if (/\s/g.test(val)) {
    passwordStrength["space"] = false;
  } else {
    passwordStrength["space"] = true;
  }
  return passwordStrength;
};

export const compareItems = (a: RunnerType, b: RunnerType): number => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameB > nameA) {
    comparison = -1;
  }

  return comparison;
};
