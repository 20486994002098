import { Theme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { buttonStyles } from "../../styles/buttonStyles";

const useStyles = makeStyles((theme: Theme) => {
  createStyles({
    ...buttonStyles(theme),
    rightMargin: {
      marginRight: "10px",
    },
  });
});

type CancelButtonPropsType = {
  onClick: Function;
  title?: string;
};

export const CancelButton = (props: CancelButtonPropsType) => {
  const handleClick = () => {
    props.onClick();
  };

  return (
    <Button color="primary" onClick={handleClick}>
      {props.title ? props.title : "Cancel"}
    </Button>
  );
};
