import { Button, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useCallback, useRef } from "react";
import Webcam from "react-webcam";
import { buttonStyles } from "../../global/styles/buttonStyles";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { CancelButton } from "../../global/components/Buttons/CancelButton";
import classNames from "classnames";
import { iconStyles } from "../../global/styles/iconStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...buttonStyles(theme),
    ...iconStyles(theme),
    webcam: {
      width: "100%",
      height: "100%",
    },
  })
);

type ImagusWebcamPropsType = {
  captureCamera: Function;
  cancelCamera: Function;
  setError?: Function;
  isDisabled?: boolean;
};

export const ImagusWebcam = (props: ImagusWebcamPropsType) => {
  const classes = useStyles();
  const webcamRef = useRef<Webcam>(null);

  // const capture = webcamRef.current.getScreenshot();
  const videoConstrain = {
    facingMode: "user",
  };

  const captureCamera = props.captureCamera;
  const capture = useCallback(() => {
    webcamRef !== null &&
      webcamRef.current !== null &&
      captureCamera(webcamRef.current.getScreenshot());
  }, [webcamRef, captureCamera]);

  return (
    <div>
      <Webcam
        audio={false}
        ref={webcamRef}
        videoConstraints={videoConstrain}
        className={classes.webcam}
        screenshotFormat="image/jpeg"
      />
      <Button
        variant="contained"
        type="submit"
        disabled={props.isDisabled}
        className={classes.darkBtn}
        onClick={capture}
      >
        <AddAPhotoIcon className={classNames(classes.lightIcon)} />
        Say "Cheese"
      </Button>
      <CancelButton onClick={props.cancelCamera} />
    </div>
  );
};
