import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileEnrolmentsImage } from "../../admin/actions/participantsActions";
import { AppState } from "../../Store";
import { getEnrolments } from "../actions/profilesActions";
import { EnrolmentType } from "../reducers/profilesReducers";

export const useFetchImageForEnrolments = (
  profileId: string,
  enrolments: Array<EnrolmentType> | undefined,
  peoplePage?: boolean
) => {
  // const enrolments = useSelector(
  //     (state: AppState) => state.global.profiles.activeProfile.enrolments
  //   );
  const dispatch = useDispatch();
  const displayedEnrolments = enrolments ? enrolments : [];
  useEffect(() => {
    if (enrolments !== null && displayedEnrolments.length > 0) {
      getEnrolmentImages();
    }
  }, [displayedEnrolments]);

  const getEnrolmentImages = () => {
    for (var i = 0; i < displayedEnrolments.length; i++) {
      if (!displayedEnrolments[i].faces[0].imgUrl) {
        let faceId = displayedEnrolments[i].faces[0].faceId;
        // getEnrolments(faceId, displayedEnrolments[i].enrolmentId)(dispatch);
        if (peoplePage) {
          getProfileEnrolmentsImage(
            profileId,
            faceId,
            displayedEnrolments[i].enrolmentId
          )(dispatch);
        } else {
          getEnrolments(faceId, displayedEnrolments[i].enrolmentId)(dispatch);
        }
      }
    }
  };
};
