import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";
import { panelStyles } from "../styles/generalStyles";
import { LoginForm } from "../components/users/LoginForm";
import { userAssignRole, userSignup } from "../actions/userActions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...panelStyles(theme),
    maxHeight: {
      hieght: "100vh",
    },
    padding: {
      paddingTop: "30px",
    },
  })
);

export const Signup = () => {
  const classes = useStyles();

  const handleUserSignup = (
    username: string,
    password: string,
    email: string,
    doneBack: Function,
    failBack: Function
  ) => {
    userSignup(username, password, email, doneBack, failBack);
  };

  const handleUserRoleSignup =(result: any) =>{
    console.log("here", result);
    
    const user = JSON.parse(result);
    userAssignRole(user.id,"Basic");
  }
  return (
    <div
      className={classNames(
        classes.appMain,
        classes.maxHeight,
        classes.padding
      )}
    >
      <LoginForm userAction={handleUserSignup} isSignup />
    </div>
  );
};
