//admin-site
export const SET_THEME: string = "admin/site/SET_THEME";

//admin-participants
export const GET_ALL_EVENTS = "admin/participants/GET_ALL_EVENTS";
export const GET_ALL_EVENT_RACES = "admin/participants/GET_ALL_RACES";
export const GET_ALL_PARTICIPANTS = "admin/participants/GET_ALL_PARTICIPANTS";
export const SET_UPDATING_PARTICIPANTS =
  "admin/participants/SET_UPDATING_PARTICIPANTS";
export const SET_PARTICIPANTS_ENROLMENTS_IMAGE =
  "admin/participants/SET_PARTICIPANTS_ENROLMENTS_IMAGE";
export const SET_PARTICIPANTS_AVATAR =
  "admin/participants/SET_PARTICIPANT_AVATAR";
export const GET_PARTICIPANT_BY_ID = "admin/participants/GET_PARTICIPANT_BY_ID";
//global-user
export const SET_FETCHING_AUTH = "global/user/SET_FETCHING_AUTH";
export const SET_AUTH = "global/user/SET_AUTH";
export const SET_USER = "global/user/SET_USER";

//global-profiles
export const SET_ACTIVE_PROFILE = "global/profiles/SET_ACTIVE_PROFILE";
export const SET_FACE_IMAGE = "global/profiles/SET_FACE_IMAGE";
export const LIST_PROFILES = "global/profiles/LIST_PROFILES";
export const ADD_PROFILE = "global/profiles/ADD_PROFILE";
export const DELETE_PROFILES = "global/profiles/DELETE_PROFILES";
export const ADD_RUNNER = "global/profiles/ADD_RUNNER";
export const DELETE_RUNNER = "global/profiles/DELETE_RUNNER";
export const GET_AVATAR = "global/profiles/GET_AVATAR";
export const SET_UPDATING = "global/profiles/SET_UPDATING;";
export const UPDATE_PROFILE = "global/profiles/UPDATE_PROFILE";

//global-facesearch
export const SAVE_TOKEN = "global/faceSearch/SAVE_TOKEN";
export const DETECT_IMAGE = "global/faceSearch/DETECT_IMAGE";
export const SET_LOADED_IMAGE = "global/faceSearch/SET_LOADED_IMAGE";
export const ADD_IMAGE_TO_FACE_SEARCH =
  "global/faceSearch/ADD_IMAGE_TO_FACE_SEARCH";
export const CLEAR_FORM = "global/faceSearch/CLEAR_FORM";

//global-race
export const GET_RACES = "global/races/GET_RACES";
export const UPDATE_RACE = "global/races/GET_RACE";
export const SET_RACE_UPDATING = "global/races/SET_RACE_UPDATING";
export const SET_SEARCH_QUERY = "global/races/SET_SEARCH_QUERY";
export const GET_SEARCH_RESULTS = "global/races/GET_SEARCH_RESULTS";
export const UPDATE_RACE_ENROLMENTS = "global/races/UPDATE_RACE_ENROLMENTS";
