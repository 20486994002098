import {
  createStyles,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Theme,
} from "@material-ui/core";
import classNames from "classnames";
import React, { ReactElement } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      "& $text, & $icon": {
        color: theme.palette.common.white,
      },
    },
    menuItemSelected: {
      backgroundColor: theme.palette.primary.light,
      "& $text, & $icon": {
        color: theme.palette.common.white,
      },
    },
    text: {},
    icon: {},
    iconMargin: {
      minWidth: "36px",
    },
    textPadding: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  })
);

type NavbarMenuItemProps = {
  text: string;
  icon?: ReactElement;
  onClick?: any;
  selected?: boolean;
  isNested?: boolean;
  to?: string;
};

export const NavbarMenuItem = (props: NavbarMenuItemProps) => {
  const classes = useStyles();
  return (
    <MenuItem
      className={classes.menuItem}
      selected={props.selected}
      {...(props.onClick ? { onClick: props.onClick } : {})}
    >
      {props.icon && (
        <ListItemIcon
          className={classes.icon}
          classes={{ root: classes.iconMargin }}
        >
          {props.icon}
        </ListItemIcon>
      )}
      <ListItemText
        classes={{ primary: classes.text, root: classes.textPadding }}
        primary={props.text}
      />
    </MenuItem>
  );
};
