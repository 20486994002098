import React from "react";
import { forgotPassword } from "../actions/userActions";
import { ForgotForm } from "../components/users/ForgotForm";

export const Forgot = () => {
  const handleFormSubmit = (
    email: string,
    doneBack?: Function,
    failBack?: Function
  ) => {
    forgotPassword(email, doneBack, failBack);
  };
  return <ForgotForm forgotPassword={handleFormSubmit} />;
};
