import { combineReducers } from "redux";
import { participantsReducers } from "./participantsReducers";
import { siteReducers } from "./siteReducers";

const reducers = combineReducers({
  site: siteReducers,
  participants: participantsReducers,
});

export default reducers;
