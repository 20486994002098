import { useSelector } from "react-redux";
import { AppState } from "../../../Store";
import { userDelete } from "../../actions/userActions";
import { ConfirmationDialog } from "../../components/gadgets/ConfirmationDialog";

type DeleteUserReduxType = {
  open: boolean;
  handleCancle: Function;
  handleConfirm: Function;
};

export const DeleteUserRedux = (props: DeleteUserReduxType) => {
  const userInfo = useSelector((state: AppState) => state.global.user.info);

  const deleteUser = () => {
    userInfo &&
      userDelete(
        userInfo.id,
        () => {},
        (error: {}) => {
          console.log(error);
        }
      );
  };

  return (
    <ConfirmationDialog
      open={props.open}
      handleCancel={props.handleCancle}
      confirmFunction={deleteUser}
    />
  );
};
