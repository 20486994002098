import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import { UserProfileRedux } from "../../container/user/UserProfileRedux";
import { RunnerType } from "../../reducers/profilesReducers";
import { InfoType } from "../../reducers/userReducers";

type UserProfilePropsType = {
  profileInfo: RunnerType | null;
};

export const UserProfile = (props: UserProfilePropsType) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {props.profileInfo ? (
          <UserProfileRedux profileInfo={props.profileInfo} />
        ) : (
          <CircularProgress color="primary" size={80} />
        )}
      </Grid>
    </Grid>
  );
};
