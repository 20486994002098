import {
  createStyles,
  makeStyles,
  MuiThemeProvider,
  Theme,
} from "@material-ui/core";
import { Fragment, ReactElement } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppState } from "../../Store";
import { Menu } from "../components/Menu";
import { panelStyles } from "../styles/generalStyles";
import { blueTheme, darkTheme } from "../styles/theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...panelStyles(theme),
  })
);

type TemplatePropsType = {
  children: ReactElement;
};

const Template = (props: TemplatePropsType) => {
  const classes = useStyles();
  //determine which theme to use
  const themeNameFromState = useSelector(
    (state: AppState) => state.admin.site.themeName
  );
  const themeList = [blueTheme, darkTheme];
  let index = themeList.findIndex((x) => x.themeName === themeNameFromState);
  const theme = themeList[index];

  //determine whether a menu is required
  const location = useLocation();
  const pathnameArray = location.pathname.split("/");
  const lastPath = pathnameArray[pathnameArray.length - 1];

  const excludeFromMenuList = ["login", "forgot", "reset", "signup", "success"];
  const isMenuRequired = excludeFromMenuList.indexOf(lastPath) < 0;

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.appWrapper}>
        {isMenuRequired ? (
          <Fragment>
            <Menu>{props.children}</Menu>
          </Fragment>
        ) : (
          <Fragment>{props.children}</Fragment>
        )}
      </div>
    </MuiThemeProvider>
  );
};

export default Template;
