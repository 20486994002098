import { Hidden, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Race } from "../../global/reducers/raceReducers";
import { drawerStyles, panelStyles } from "../../global/styles/generalStyles";
import { ImagusRaceDrawer } from "../../ImagusComponents/Gadgets/ImagusRaceDrawer";
import { EventType } from "../types/participantsType";
import { EventsPanel } from "./EventsPanel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...drawerStyles(theme),
    ...panelStyles(theme),
  })
);

type ParticipantsPageLayoutPropsType = {
  raceList: Array<Race> | null;
  fetchRaces: Function;
  fetchEvents: Function;
  eventList: Array<EventType> | null;
};

export const ParticipantsPageLayout = (
  props: ParticipantsPageLayoutPropsType
) => {
  const classes = useStyles();
  const [selectedRaceId, setSelectedRaceId] = useState("");
  const [selectedRace, setSelectedRace] = useState<Race>();

  const handleRaceSelect = (raceId: string) => {
    setSelectedRaceId(raceId);
    let race: Race | undefined;
    if (props.raceList) {
      race = props.raceList.find((race) => race.raceId === raceId);
    }
    setSelectedRace(race);
  };

  return (
    <div className={classes.root}>
      {/* <ImagusRaceDrawer
        handleSearch={props.fetchRaces}
        raceList={props.raceList}
        handleRaceSelect={handleRaceSelect}
        selectedId={selectedRaceId}
      /> */}
      <EventsPanel
        handleEventSearch={props.fetchEvents}
        eventList={props.eventList}
      />
    </div>
  );
};
