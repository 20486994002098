import React from "react";
import { resetPassword } from "../actions/userActions";
import { ResetPasswordForm } from "../components/users/ResetPasswordForm";

export const ResetPassword = () => {
  const handleResetPassword = (
    email: string,
    token: string,
    newPassword: string,
    doneBack?: Function,
    failBack?: Function
  ) => {
    resetPassword(email, token, newPassword, doneBack, failBack);
  };
  return <ResetPasswordForm resetPassword={handleResetPassword} />;
};
