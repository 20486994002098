import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../Store";
import { AppHeader } from "../components/gadgets/AppHeader";
import { UserProfile } from "../components/users/UserProfile";
import { UserAuth } from "../container/UserAuth";
import {
  contentStyles,
  headerStyles,
  panelStyles,
} from "../styles/generalStyles";
import { buttonStyles } from "../styles/buttonStyles";

import { ProfileSetting } from "../components/users/ProfileSetting";
import { ModalForm } from "../components/gadgets/ModalForm";
import { ChangePasswordRedux } from "../container/user/ChangePasswordRedux";
import { useSelectProfile } from "../helperFunctions/useSelectProfile";
import { InfoType } from "../reducers/userReducers";

import { fetchActiveProfile, fetchProfiles } from "../actions/profilesActions";
import { CreateProfileFormRedux } from "../container/profiles/CreateProfileFormRedux";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...buttonStyles(theme),

    root: {
      color: theme.palette.primary.main,
    },

    rightMargin: {
      marginRight: "5px",
    },
  })
);

export const Profile = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const userInfo = useSelector((state: AppState) => state.global.user.info);
  const accountId = userInfo ? userInfo.id : "";
  // const profile = useSelectProfile(accountId);
  const profileList = useSelector(
    (state: AppState) => state.global.profiles.profileIds
  );
  const profile = useSelector(
    (state: AppState) => state.global.profiles.activeProfile
  );

  const dispatch = useDispatch();

  useSelectProfile(accountId);
  useEffect(() => {
    if (profileList) {
      if (profileList.length === 0) {
        setIsProfileModalOpen(true);
      } else {
        console.log("got to here");
        fetchActiveProfile(profileList[0])(dispatch);
      }
    }
  }, [profileList]);

  return (
    <UserAuth>
      <Fragment>
        <AppHeader
          title={"Profile"}
          className={classes.root}
          actions={[
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classNames(classes.darkBtn, classes.rightMargin)}
              onClick={() => setIsModalOpen(true)}
            >
              Change Password
            </Button>,
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={classes.darkBtn}
              onClick={() => setIsProfileModalOpen(true)}
            >
              Create profile
            </Button>,
          ]}
        />
        <UserProfile profileInfo={profile} />
        <ProfileSetting />
        <ModalForm
          title="Change my password"
          open={isModalOpen}
          handleModalStatus={() => setIsModalOpen(false)}
          form={<ChangePasswordRedux onClose={() => setIsModalOpen(false)} />}
        />

        <ModalForm
          title="Create Profile"
          open={isProfileModalOpen}
          darkBackdrop
          disableExit
          handleModalStatus={() => setIsProfileModalOpen(false)}
          form={
            <CreateProfileFormRedux
              onClose={() => setIsProfileModalOpen(false)}
              userId={accountId}
              currentProfile={profile}
            />
          }
        />
      </Fragment>
    </UserAuth>
  );
};
