import { Dispatch } from "redux";
import Properties from "../../Properties";
import { RunnerType } from "../reducers/profilesReducers";
import { RunnerResponseModel } from "../types/ApiReturnTypes";
import { EnrolmentInputType } from "../types/faceSearchTypes";
import {
  Data,
  deleteBody,
  getInit,
  postBody,
  putBody,
  UpdateProfileModel,
} from "../types/requestConstants";
import {
  ADD_PROFILE,
  LIST_PROFILES,
  SET_ACTIVE_PROFILE,
  SET_FACE_IMAGE,
  SET_UPDATING,
  SET_UPDATING_PARTICIPANTS,
} from "./actionNames";
import { handleResponse } from "./handleResponse";

const root = Properties.api_root;

interface ProfileInput extends Data {
  name: string;
  accountId: string;
}
interface Guid {
  guid: string;
}
/** Create profile */
export const createProfile = (
  name: string,
  accountId: string,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  let formData: ProfileInput = {
    name: name,
    accountId: accountId,
  };
  dispatch({ type: SET_UPDATING, status: true });
  return fetch(`${root}/api/v2/profile`, postBody(formData))
    .then(handleResponse)
    .then(
      (profileId: Guid) => {
        dispatch({ type: ADD_PROFILE, uid: profileId.guid });
        dispatch({ type: SET_UPDATING, status: false });
        doneBack && doneBack();
      },
      (error) => {
        failBack && failBack(error);
        dispatch({ type: SET_UPDATING, status: false });
      }
    );
};

/** Get all profiles associate with this account */
export const fetchProfiles = (
  userId: string,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  console.log("about to fetch");

  dispatch({ type: SET_UPDATING, status: true });
  // return fetch(
  //   `${root}/api/v2/people/search?accountId=${accountId ? accountId : ""}`,
  //   getInit()
  // )
  //   .then(handleResponse)
  //   .then(
  //     (response) => {
  //       //get the list of ids belong to this account
  //       console.log(response);
  //       let idArray = response.map((eachProfile: any) => eachProfile.uid);
  //       dispatch({ type: LIST_PROFILES, profileIds: idArray });
  //       dispatch({ type: SET_UPDATING, status: false });
  //       doneBack && doneBack(response);
  //     },
  //     (error) => {
  //       console.log("fetch fail: ", error);
  //       dispatch({ type: SET_UPDATING, status: false });
  //       failBack && failBack(error);
  //     }
  //   );

  return fetch(root + "/api/v2/profile/user/" + userId, getInit())
    .then((response: Response) => handleResponse(response))
    .then(
      (response: Array<string>) => {
        let idArray = response;
        dispatch({ type: LIST_PROFILES, profileIds: idArray });
        dispatch({ type: SET_UPDATING, status: false });
        doneBack && doneBack(response);
      },
      (error) => {
        dispatch({ type: SET_UPDATING, status: false });
        failBack && failBack(error);
      }
    );
};

/**Get the profile with the specified ID */
export const fetchActiveProfile = (
  profileId: string,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  console.log(profileId);
  return fetch(`${root}/api/v2/profile/${profileId}`, getInit())
    .then(handleResponse)
    .then(
      (response) => {
        let profile: RunnerType = {
          uid: response.profileId,
          name: response.name,
          enrolments: response.enrolments ? response.enrolments : [],
          dateOfBirth: response.dateOfBirth,
        };
        console.log(response);
        dispatch({ type: SET_ACTIVE_PROFILE, activeProfile: profile });
        doneBack && doneBack(response);
      },
      (error) => {
        failBack && failBack(error);
      }
    );
};

/** Get enroled photos from faceId, enrolmentId, and profileId */
export const getEnrolments = (
  faceId: string,
  enrolmentId: string,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  console.log("getting enrolment for ", enrolmentId);
  dispatch({ type: SET_UPDATING, status: true });
  return fetch(`${root}/api/v2/people/enrolments/faces/${faceId}`, getInit())
    .then((response: Response) => handleResponse(response, "IMAGE"))
    .then(
      (imgUrl) => {
        dispatch({
          type: SET_FACE_IMAGE,
          enrolmentId: enrolmentId,
          faceId: faceId,
          faceImageUrl: imgUrl,
        });
        dispatch({ type: SET_UPDATING, status: false });
        doneBack && doneBack(imgUrl);
      },
      (error) => {
        dispatch({ type: SET_UPDATING, status: false });
        failBack && failBack(error);
      }
    );
};

/** Update current profile enrolment details */
export const updateProfile = (
  profileId: string,
  name: string,
  newEnrolments: Array<EnrolmentInputType>,
  deleteEnrolments: Array<string>,
  accountId?: string,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  let formData: UpdateProfileModel = {
    name: name,
    profileId: profileId,
    accountId: accountId,
  };

  if (newEnrolments.length > 0) {
    formData["newEnrolments"] = newEnrolments;
  }

  if (deleteEnrolments.length > 0) {
    formData["deleteEnrolments"] = deleteEnrolments;
  }

  dispatch({ type: SET_UPDATING, status: true });
  return fetch(root + "/api/v2/profile/" + profileId, putBody(formData))
    .then(handleResponse)
    .then(
      (results: Array<string>) => {
        dispatch({ type: SET_UPDATING, status: false });
        doneBack && doneBack(results);
      },
      (error) => {
        dispatch({ type: SET_UPDATING, status: false });
        failBack && failBack(error);
      }
    );
};

interface RemoveProfileType extends Data {
  profileIds: Array<Guid>;
}

export const removeProfiles = (
  profileIds: Array<string>,
  doneBack?: Function,
  failBack?: Function
) => (dispatch: Dispatch) => {
  dispatch({ type: SET_UPDATING_PARTICIPANTS, status: true });
  return fetch(root + "/api/v2/profile/", deleteBody(profileIds))
    .then((response: Response) => handleResponse(response, "EMPTY"))
    .then(
      () => {
        dispatch({ type: SET_UPDATING_PARTICIPANTS, status: false });
        doneBack && doneBack();
      },
      (error) => {
        dispatch({ type: SET_UPDATING_PARTICIPANTS, status: false });
        failBack && failBack(error);
      }
    );
};

export const downloadFilesById = (
  profileId: string,
  doneBack?: Function,
  failBack?: Function
) => {
  console.log("about to download");
  return fetch(root + "/api/v1/download/" + profileId, getInit())
    .then((response: Response) => response.blob())
    .then(
      (result) => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `enrolments_${new Date().getDate()}${(
            "0" + new Date().getMonth()
          ).slice(-2)}${new Date().getFullYear()}.zip`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        doneBack && doneBack(result);
      },
      () => {
        console.log("error");
        failBack && failBack();
      }
    );
};
