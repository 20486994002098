import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { FaceDisplay } from "./FaceDisplay";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    enrolItem: {
      "&:hover": {
        "& $badgeContent": {
          opacity: 0,
        },
      },
      // width: "150px",
    },
    badgeContent: {},
    cardImage: {
      width: "375px",
      height: "375px",
    },
  })
);

type EnrolmentItemPropsType = {
  imgUrl: string | undefined;
  removePhoto?: Function;
  checked?: boolean;
  selectImage?: Function;
  longClick?: Function;
  name?: string;
};

export const EnrolmentItem = (props: EnrolmentItemPropsType) => {
  const classes = useStyles();
  const [anchorElement, setAnchor] = useState<HTMLImageElement | null>();
  const open = !!anchorElement;

  const imageSelect = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchor(event.currentTarget);
  };

  const imageUnSelect = () => {
    setAnchor(null);
  };

  const handleImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
    imageSelect(e);
  };

  return (
    <div className={classes.enrolItem}>
      <FaceDisplay
        img={props.imgUrl}
        checked={props.checked}
        selectImage={props.selectImage}
        longClick={props.longClick}
        name={props.name}
      />
      {/* <Popover
        open={open}
        anchorEl={anchorElement}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={imageUnSelect}
      >
        <Card raised>
          <CardContent>
            <img
              src={props.imgUrl}
              className={classes.cardImage}
              onClick={handleImageClick}
            />
          </CardContent>
        </Card>
      </Popover> */}
    </div>
  );
};
