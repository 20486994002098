export const PrimaryGreen = "#009c3f";
export const SecondaryGreen = "#32af65";
export const ThirdGreen = "#66c38b";
export const DarkGreen = "#007c32";

export const PrimaryRed = "#9c005d";
export const LightRed = "#af327d";
export const DardRed = "#7c004a";

export const ValidText = "#3a7d34";
export const InvalidText = "#ec3f41";
export const WarningText = "#FFAB00";

export const WhiteText = "#ffffff";
export const BlackText = "#000000";
export const GreyText = "#8a8a8a";
export const GreyLightText = "#dddddd";
export const BlueText = "#23BEBE";

export const NavBarWidth = 248;
export const NavBarCloseWidth = 64;
export const DrawerWidth = 240;
export const ToolBarHeight = 80;
