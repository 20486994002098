import { ChangeEvent, FormEvent, Fragment, useState } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import EmailIcon from "@material-ui/icons/Email";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

import LogoImage from "../../../images/TracyWhiteBackground.png";
import {
  formText,
  formRightBtnBlock,
  inputStyles,
  bannerStyles,
} from "../../styles/generalStyles";
import classNames from "classnames";
import { SingleTextField } from "../formInputs/SingleTextField";
import { PasswordStrengthBox } from "./PasswordStrengthBox";
import {
  checkPasswordStrength,
  initalPasswordStrength,
  PasswordStrength,
} from "../../helperFunctions/stringFunctions";
import { ErrorDisplay, setErrorMessage } from "../ErrorDisplay";
import { CancelButton } from "../Buttons/CancelButton";
import { SubmitButton } from "../Buttons/SubmitButton";
import { useHistory } from "react-router-dom";
import { CompleteMessage } from "../../../ImagusComponents/Gadgets/CompleteMessage";
import { iconStyles } from "../../styles/iconStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...inputStyles(theme),
    ...iconStyles(theme),
    formText,
    formRightBtnBlock,
    wrapper: {
      width: "100%",
      maxWidth: "520px",
      margin: "10% auto",

      backgroundColor: "inherit",
      height: "auto",
      border: "1px solid black",
    },
    formLogo: {
      width: "100%",
      margin: "2% 0",
      padding: "10px",
      height: "auto",
      maxHeight: "150px",
      float: "none",
    },
    clear: {
      clear: "both",
    },
    titleText: {
      color: theme.palette.common.black,
    },
    textAlignLeft: {
      width: "100%",
      textAlign: "left",
    },
    titleMargin: {
      marginBottom: "30px",
    },
    iconBlock: {
      height: "54px",
      lineHeight: "130px",
    },
    formHint: {
      fontStyle: "italic",
      paddingRight: "10px",
    },
    formLink: {
      color: theme.palette.primary.main,
      textDecoration: "underline",
      fontWeight: 500,
    },
    panel: {
      width: "85%",
      margin: "auto",
    },
    switchText: {
      fontStyle: "italic",
    },
    bannerTitle: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      height: "50px",
    },
  })
);

type ResetPasswordFormPropsType = {
  resetPassword: Function;
};

type InputType = {
  email: string;
  code: string;
  password: string;
  passwordConfirm: string;
};

const intialInput: InputType = {
  email: "",
  code: "",
  password: "",
  passwordConfirm: "",
};

export const ResetPasswordForm = (props: ResetPasswordFormPropsType) => {
  const classes = useStyles();
  const [inputs, setInputs] = useState<InputType>(intialInput);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState<PasswordStrength>(
    initalPasswordStrength
  );
  const [errors, setErrors] = useState<Record<string, string>>({});

  const history = useHistory();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { email, code, password } = inputs;
    props.resetPassword(email, code, password, submitDone, submitFail);
  };

  const submitDone = () => {
    setCompleted(true);
  };
  const submitFail = (error: Record<string, string>) => {
    setErrors(error);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    setInputs({ ...inputs, [name]: value });
    setErrors(setErrorMessage(errors, "token", ""));
    setErrors(setErrorMessage(errors, "email", ""));
    if (name === "password") {
      handlePasswordChange(value);
    }

    if (name === "passwordConfirm") {
      handlePasswordConfirm(value);
    }
  };

  const handlePasswordChange = (value: string) => {
    //check strength
    const checkStrength = checkPasswordStrength(passwordStrength, value);
    setPasswordStrength(checkStrength);
    if (Object.values(checkStrength).includes(false) && !!value) {
      setErrors(setErrorMessage(errors, "strength", "Invalid password format"));
    } else {
      setErrors(setErrorMessage(errors, "strength", ""));
    }

    //check matching
    if (!!value && value !== inputs.passwordConfirm) {
      setErrors(setErrorMessage(errors, "password", "Password did not match"));
    } else {
      setErrors(setErrorMessage(errors, "password", ""));
    }
  };

  const handlePasswordConfirm = (value: string) => {
    if (!!value && value !== inputs.password) {
      setErrors(setErrorMessage(errors, "password", "Password did not match"));
    } else {
      setErrors(setErrorMessage(errors, "password", ""));
    }
  };

  const backToLogin = () => {
    history.push("./login");
  };

  if (completed) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <CompleteMessage
            title="Done"
            content="Password updated successfully"
            onClick={backToLogin}
            isPageCenter
          />
        </Grid>
      </Grid>
    );
  } else
    return (
      <div className={classes.wrapper}>
        <img src={LogoImage} className={classes.formLogo} alt="Tracy Logo" />
        <div className={classes.clear} />
        <div className={classes.formText}>
          <Typography
            variant="h5"
            className={classNames(classes.titleText, classes.titleMargin)}
          >
            Reset Your Password
          </Typography>
        </div>
        <form
          onSubmit={(!loading && handleSubmit) || undefined}
          className={classes.panel}
        >
          <Grid container spacing={1}>
            <Grid item xs={1} className={classes.iconBlock}>
              <EmailIcon
                className={classNames(classes.greenIcon, classes.mediumIcon)}
              />
            </Grid>
            <Grid item xs={11}>
              <SingleTextField
                label="Email Address"
                name="email"
                inputType="text"
                value={inputs.email}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={1} className={classes.iconBlock}>
              <VerifiedUserIcon
                className={classNames(classes.greenIcon, classes.mediumIcon)}
              />
            </Grid>
            <Grid item xs={11}>
              <SingleTextField
                label="Verification Code"
                name="code"
                onChange={handleInputChange}
                inputType="text"
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <SingleTextField
                label="New Password"
                name="password"
                inputType="password"
                value={inputs.password}
                onChange={handleInputChange}
                isRequired
              />
              <PasswordStrengthBox
                password={inputs.password}
                passwordStrength={passwordStrength}
              />
            </Grid>
            <Grid item xs={1} />

            <Grid item xs={5}>
              <SingleTextField
                label="Confirm Password"
                name="passwordConfirm"
                inputType="password"
                value={inputs.passwordConfirm}
                onChange={handleInputChange}
                isRequired
              />
            </Grid>
            <Grid item xs={12}>
              <ErrorDisplay errors={errors} />
            </Grid>
            <Grid item xs={12} className={classes.titleMargin}>
              <div className={classes.formRightBtnBlock}>
                <CancelButton onClick={backToLogin} />
                <SubmitButton
                  loading={loading}
                  title="Reset Password"
                  disabled={Object.keys(errors).length > 0}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    );
};
