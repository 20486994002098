import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { ReactNode } from "react";
import { headerStyles } from "../../styles/generalStyles";
import { iconStyles } from "../../styles/iconStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...iconStyles(theme),
    ...headerStyles(theme),
    leftMargin: {
      marginLeft: "10px",
    },
    flex: {
      display: "inline-flex",
      alignItems: "center",
    },
  })
);

type AppHeaderPropType = {
  title?: string;
  className?: string;
  actions?: Array<ReactNode>;
};

export const AppHeader = (props: AppHeaderPropType) => {
  const classes = useStyles();
  return (
    <div className={classes.appHeader}>
      <div className={classes.headerWrapper}>
        <Typography variant="h5" className={classes.headerTitle}>
          {props.title}
        </Typography>

        {props.actions && (
          <div>
            {props.actions.map((action: ReactNode, i: number) => {
              return (
                <span className={classes.actionItem} key={i}>
                  {action}
                </span>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
