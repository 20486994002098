import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRace } from "../../global/actions/raceActions";
import { useSelectProfile } from "../../global/helperFunctions/useSelectProfile";
import { AppState } from "../../Store";
import { getEventsByQuery } from "../actions/participantsActions";
import { ParticipantsPageLayout } from "../components/ParticipantsPageLayout";

export const ParticipantsRedux = () => {
  const accountInfo = useSelector((state: AppState) => state.global.user.info);
  const profile = useSelector(
    (state: AppState) => state.global.profiles.activeProfile
  );

  const races = useSelector((state: AppState) => state.global.races.raceList);
  const events = useSelector(
    (state: AppState) => state.admin.participants.events
  );
  const dispatch = useDispatch();
  const getRaces = (
    query: string,
    doneBack?: Function,
    failBack?: Function
  ) => {
    fetchRace(profile.uid, query, doneBack, failBack)(dispatch);
  };

  const getEvents = (
    query: string,
    doneBack?: Function,
    failBack?: Function
  ) => {
    getEventsByQuery(query, doneBack, failBack)(dispatch);
  };

  useSelectProfile(accountInfo?.id);

  //fetch races upon mounted
  useEffect(() => {
    if (profile.uid) {
      fetchRace(profile.uid, "")(dispatch);
    }
  }, [profile.uid]);

  useEffect(() => {
    getEventsByQuery("")(dispatch);
  }, []);

  return (
    <ParticipantsPageLayout
      raceList={races}
      fetchRaces={getRaces}
      fetchEvents={getEvents}
      eventList={events}
    />
  );
};
