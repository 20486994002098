import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllProfiles,
  fetchProfileById,
  getProfileAvatar,
} from "../../../admin/actions/participantsActions";
import { AppState } from "../../../Store";
import { SET_SEARCH_QUERY } from "../../actions/actionNames";
import { uploadFile, detectFaces } from "../../actions/faceSearchActions";
import {
  fetchActiveProfile,
  removeProfiles,
  updateProfile,
} from "../../actions/profilesActions";
import {
  fetchRace,
  fetchRaceDetailsById,
  updateRaceEnrolment,
} from "../../actions/raceActions";
import { RacePageLayout } from "../../components/race/RacePageLayout";
import { useFetchAllProfiles } from "../../helperFunctions/useFetchAllProfiles";

import { useFetchRaces } from "../../helperFunctions/useFetchRaces";
import { useSelectProfile } from "../../helperFunctions/useSelectProfile";
import { EnrolmentType, RunnerType } from "../../reducers/profilesReducers";
import { Race } from "../../reducers/raceReducers";
import { RunnerResponseModel } from "../../types/ApiReturnTypes";
import { EnrolmentInputType } from "../../types/faceSearchTypes";

type RaceReduxPropsType = {
  races: Array<RunnerType> | null;
  getRaces: Function;
};

export const RaceRedux = (props: RaceReduxPropsType) => {
  const [open, setOpen] = useState(false);

  //----------------Access Redux Store---------------//
  const accountInfo = useSelector((state: AppState) => state.global.user.info);
  // const races = useSelector((state: AppState) => state.global.races.raceList);
  const profile = useSelector(
    (state: AppState) => state.global.profiles.activeProfile
  );
  const enrolments = useSelector(
    (state: AppState) => state.global.profiles.activeProfile.enrolments
  );
  const faceSearch = useSelector((state: AppState) => state.global.faceSearch);
  const dispatch = useDispatch();

  useSelectProfile(accountInfo?.id);
  // useFetchRaces();
  // useFetchAllProfiles();
  // useFetchImage();

  useEffect(() => {
    props.getRaces("");
  }, []);

  //----------------Call Apis---------------//

  const getRaceDetailsById = (
    raceId: string,
    doneBack?: Function,
    failBack?: Function
  ) => {
    fetchRaceDetailsById(raceId, profile.uid, doneBack, failBack)(dispatch);
  };

  const handleUpdateCurrentProfile = (
    name: string,
    newEnrolments: Array<EnrolmentInputType>,
    deleteEnrolments: Array<string>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    accountInfo &&
      updateProfile(
        profile.uid,
        name,
        newEnrolments,
        deleteEnrolments,
        undefined,
        () => {
          doneBack && doneBack();
        },
        failBack
      )(dispatch);
  };

  const handleUpdateProfile = (
    profileId: string,
    name: string,
    newEnrolments: Array<EnrolmentInputType>,
    deleteEnrolments: Array<string>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    console.log("profileId", profileId);
    console.log("name", name);
    console.log("enrolments", newEnrolments);
    accountInfo &&
      updateProfile(
        profileId,
        name,
        newEnrolments,
        deleteEnrolments,
        undefined,
        doneBack,
        failBack
      )(dispatch);
  };

  const handleUpdateRaceEnrolment = (
    raceId: string,
    newEnrolments: Array<string>,
    deleteEnrolments: Array<string>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    //update race
    updateRaceEnrolment(
      raceId,
      profile.uid,
      newEnrolments,
      deleteEnrolments,
      doneBack,
      //if fail, remove enrolment from profile
      failBack
    )(dispatch);
  };

  const handleUploadFile = (
    file: File | Blob,
    doneBack: Function,
    failBack?: Function
  ) => {
    uploadFile(
      file,
      true,
      (token: string) =>
        detectFaces(
          token,
          () => {
            doneBack(token);
          },
          failBack
        )(dispatch),
      failBack
    )(dispatch);
  };

  const handleRemoveProfiles = (
    profileIds: Array<string>,
    doneBack?: Function,
    failBack?: Function
  ) => {
    removeProfiles(profileIds, doneBack, failBack)(dispatch);
  };

  /** Fetch current enrolments */
  const getCurrentEnrolmentList = (profileId: string): Array<EnrolmentType> => {
    let enrolments: Array<EnrolmentType> = [];
    fetchActiveProfile(profileId, () => {
      enrolments = profile.enrolments;
    })(dispatch);

    return enrolments;
  };

  const getProfileEnrolments = (profileId: string): Array<EnrolmentType> => {
    let enrolments: Array<EnrolmentType> = [];
    fetchProfileById(profileId, (profile: RunnerType) => {
      enrolments = profile.enrolments;
    })(dispatch);
    return enrolments;
  };

  let permanentEnrolments: Array<EnrolmentType> = [];
  enrolments.map((e) => {
    if (e.permanent && e.faces[0].imgUrl) {
      return permanentEnrolments.push(e);
    }
  });

  //----------------Return---------------//
  return (
    <RacePageLayout
      user={accountInfo!}
      raceList={props.races}
      fetchRaces={props.getRaces}
      enrolments={enrolments}
      updateProfile={handleUpdateProfile}
      updateRaceEnrolments={handleUpdateRaceEnrolment}
      getCurrentEnrolments={getProfileEnrolments}
      getRaceDetails={getRaceDetailsById}
      removeProfiles={handleRemoveProfiles}
      faceSearch={faceSearch}
      uploadFile={handleUploadFile}
      permanentEnrolments={permanentEnrolments}
      profileName={profile.name}
    />
  );
};
