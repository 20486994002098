import React from "react";
import {
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { EventType } from "../types/participantsType";
import { EventItem } from "./EventItem";
import { drawerStyles } from "../../global/styles/generalStyles";
import { iconStyles } from "../../global/styles/iconStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...drawerStyles(theme),
    ...iconStyles(theme),
  })
);

type EventListPropsType = {
  eventList: Array<EventType> | null;
  selectEvent: Function;
  selectedEventId: string | undefined;
};

export const EventList = (props: EventListPropsType) => {
  const classes = useStyles();

  let events =
    props.eventList &&
    props.eventList.map((event: EventType, i: number) => {
      return (
        <EventItem
          key={"event_" + i}
          event={event}
          selectEvent={props.selectEvent}
          selectedEventId={props.selectedEventId ? props.selectedEventId : ""}
        />
      );
    });

  if (events == null) {
    events = [
      <ListItem key="no_event_found">
        <ListItemIcon>
          <WarningIcon />
        </ListItemIcon>
        <ListItemText primary="No Event Found" />
      </ListItem>,
    ];
  }

  return <List className={classes.list}>{events}</List>;
};
