import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import chroma from "chroma-js";
import classNames from "classnames";
import React from "react";
import { buttonStyles } from "../../global/styles/buttonStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...buttonStyles(theme),
    flagWrapper: {
      background: theme.palette.primary.light,
      borderRadius: "10px",
      color:
        chroma.contrast(theme.palette.primary.light, "white") > 2
          ? "white"
          : "black",
      width: "100%",
      padding: "24px 20px",
      lineHeight: "30px",
    },
    verticalCenter: {
      marginTop: "15%",
    },
    flagText: {
      textAlign: "center",
    },
    flagTitle: {
      fontSize: "1.875rem",
      fontWeight: 800,
      lineHeight: "60px",
    },
    flagContent: {
      whiteSpace: "pre-wrap",
    },
    button: {
      marginTop: "15px",
    },
    checkMark: {
      width: "100px",
      display: "block",
      margin: "0 auto",
    },
    "@keyframes dash": {
      from: {
        strokeDashoffset: 1000,
      },
      to: {
        strokeDashoffset: 0,
      },
    },
    "@keyframes dashCheck": {
      from: {
        strokeDashoffset: -100,
      },
      to: {
        strokeDashoffset: 900,
      },
    },
    circle: {
      fill: "none",
      stroke: "#3a9d48",
      strokeWidth: 9,
      strokeMiterlimit: 10,
      strokeDasharray: 1000,
      strokeDashoffset: 0,
      animationName: "$dash",
      animationDuration: "0.9s",
      animationDelay: "0.35s",
      animationTimingFunction: "ease-in-out",
      animationFillMode: "forwards",
      animationIterationCount: "initial",
    },
    check: {
      fill: "none",
      stroke: "#3a9d48",
      strokeWidth: 12,
      strokeMiterlimit: 10,
      strokeLinecap: "round",
      strokeDasharray: 1000,
      strokeDashoffset: -100,
      animationName: "$dashCheck",
      animationDuration: "0.9s",
      animationDelay: "0.55s",
      animationTimingFunction: "ease-in-out",
      animationFillMode: "forwards",
      animationIterationCount: "initial",
    },
    "@supports (-ms-ime-align:auto)": {
      /*Edge*/
      check: {
        strokeDashoffset: 0,
      },
    },
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      /*IE 10, 11*/
      check: {
        strokeDashoffset: 0,
      },
    },
    para: {
      display: "block",
      marginBlockStart: "1em",
      marginBlockEnd: "1em",
      marginInlineStart: "0px",
      marginInlineEnd: "0px",
    },
  })
);

type CompleteMessagePropsType = {
  title: string;
  content: string;
  isPageCenter: boolean;
  onClick: Function;
};

export const CompleteMessage = (props: CompleteMessagePropsType) => {
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent) => {
    props.onClick();
  };

  return (
    <div
      className={classNames(
        classes.flagWrapper,
        props.isPageCenter && classes.verticalCenter
      )}
    >
      <div className={classes.flagText}>
        <span className={classes.flagTitle}>{props.title}</span>
        <br />
        <svg viewBox="0 0 130.2 130.2" className={classes.checkMark}>
          <circle className={classes.circle} cx="65.1" cy="65.1" r="60.1" />
          <polyline
            className={classes.check}
            points="100.2,40.2 51.5,88.8 29.8,67.5 "
          />
        </svg>
        <div className={classes.flagContent}>
          {props.content.split("<br>").map((item: string, i: number) => (
            <span className={classes.para} key={i}>
              {item}
            </span>
          ))}
        </div>
        {props.onClick && (
          <Button
            color="inherit"
            variant="contained"
            type="button"
            className={classNames(classes.lightBtn, classes.button)}
            onClick={handleClick}
          >
            OK
          </Button>
        )}
      </div>
    </div>
  );
};
