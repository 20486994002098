import { Typography } from "@material-ui/core";
import { Fragment } from "react";

export const TermsAndConditions = () => {
  return (
    <Fragment>
      <Typography variant="h5">Terms and Conditions</Typography>
      <Typography variant="body1">
        1. ACCEPTANCE THE USE OF LOREM IPSUM TERMS AND CONDITIONS Your access to
        and use of Lorem Ipsum (the app) is subject exclusively to these Terms
        and Conditions. You will not use the app for any purpose that is
        unlawful or prohibited by these Terms and Conditions. By using the app
        you are fully accepting the terms, conditions and disclaimers contained
        in this notice. If you do not accept these Terms and Conditions you must
        immediately stop using the app. 2. CREDIT CARD DETAILS All Lorem Ipsum
        purchases are managed by the individual App Stores (Apple, Google
        Windows) and Lorem Ipsum will never store your credit card information
        or make it available to any third parties. Any purchasing information
        provided will be provided directly from you to the respective App Store
        and you will be subject to their credit card policies. 3. LEGAL ADVICE
        The contents of Lorem Ipsum app do not constitute advice and should not
        be relied upon in making or refraining from making, any decision. All
        material contained on Lorem Ipsum is provided without any or warranty of
        any kind. You use the material on Lorem Ipsum at your own discretion 4.
        CHANGE OF USE Lorem Ipsum reserves the right to: 4.1 change or remove
        (temporarily or permanently) the app or any part of it without notice
        and you confirm that Lorem Ipsum shall not be liable to you for any such
        change or removal and. 4.2 change these Terms and Conditions at any
        time, and your continued use of the app following any changes shall be
        deemed to be your acceptance of such change. 5. LINKS TO THIRD PARTY
        APPS AND WEBSITES Lorem Ipsum app may include links to third party apps
        and websites that are controlled and maintained by others. Any link to
        other apps and websites is not an endorsement of such and you
        acknowledge and agree that we are not responsible for the content or
        availability of any such apps and websites. 6. COPYRIGHT 6.1 All
        copyright, trade marks and all other intellectual property rights in the
        app and its content (including without limitation the app design, text,
        graphics and all software and source codes connected with the app) are
        owned by or licensed to Lorem Ipsum or otherwise used by Lorem Ipsum as
        permitted by law. 6.2 In accessing the app you agree that you will
        access the content solely for your personal, non-commercial use. None of
        the content may be downloaded, copied, reproduced, transmitted, stored,
        sold or distributed without the prior written consent of the copyright
        holder. This excludes the downloading, copying and/or printing of pages
        of the app for personal, non-commercial home use only. 7. LINKS TO AND
        FROM OTHER APPS AND WEBSITES 7.1 Throughout this app you may find links
        to third party apps. The provision of a link to such an app does not
        mean that we endorse that app. If you visit any app via a link in this
        app you do so at your own risk. 7.2 Any party wishing to link to this
        app is entitled to do so provided that the conditions below are
        observed: (a) You do not seek to imply that we are endorsing the
        services or products of another party unless this has been agreed with
        us in writing; (b) You do not misrepresent your relationship with this
        app; and (c) The app from which you link to this app does not contain
        offensive or otherwise controversial content or, content that infringes
        any intellectual property rights or other rights of a third party. 7.3
        By linking to this app in breach of our terms, you shall indemnify us
        for any loss or damage suffered to this app as a result of such linking.
        8. DISCLAIMERS AND LIMITATION OF LIABILITY 8.1 The app is provided on an
        AS IS and AS AVAILABLE basis without any representation or endorsement
        made and without warranty of any kind whether express or implied,
        including but not limited to the implied warranties of satisfactory
        quality, fitness for a particular purpose, non-infringement,
        compatibility, security and accuracy. 8.2 To the extent permitted by
        law, Lorem Ipsum will not be liable for any indirect or consequential
        loss or damage whatever (including without limitation loss of business,
        opportunity, data, profits) arising out of or in connection with the use
        of the app. 8.3 Lorem Ipsum makes no warranty that the functionality of
        the app will be uninterrupted or error free, that defects will be
        corrected or that the app or the server that makes it available are free
        of viruses or anything else which may be harmful or destructive. 8.4
        Nothing in these Terms and Conditions shall be construed so as to
        exclude or limit the liability of Lorem Ipsum for death or personal
        injury as a result of the negligence of Lorem Ipsum or that of its
        employees or agents. 9. INDEMNITY You agree to indemnify and hold Lorem
        Ipsum and its employees and agents harmless from and against all
        liabilities, legal fees, damages, losses, costs and other expenses in
        relation to any claims or actions brought against Lorem Ipsum arising
        out of any breach by you of these Terms and Conditions or other
        liabilities arising out of your use of this app. 10. SEVERANCE If any of
        these Terms and Conditions should be determined to be invalid, illegal
        or unenforceable for any reason by any court of competent jurisdiction
        then such Term or Condition shall be severed and the remaining Terms and
        Conditions shall survive and remain in full force and effect and
        continue to be binding and enforceable. 11. WAIVER If you breach these
        Conditions of Use and we take no action, we will still be entitled to
        use our rights and remedies in any other situation where you breach
        these Conditions of Use. 12. GOVERNING LAW These Terms and Conditions
        shall be governed by and construed in accordance with the law of and you
        hereby submit to the exclusive jurisdiction of the courts.
      </Typography>
    </Fragment>
  );
};
