import { combineReducers } from "redux";
import { faceSearchReducers } from "./faceSearchReducers";
import { profilesReducers } from "./profilesReducers";
import { raceReducers } from "./raceReducers";
import { userReducers } from "./userReducers";

const reducers = combineReducers({
  user: userReducers,
  profiles: profilesReducers,
  faceSearch: faceSearchReducers,
  races: raceReducers,
});
export default reducers;
