import { useEffect } from "react";

type RedirectToLoginPropsType = {
  /**
   * Number of seconds to delay for
   */
  timeDelay?: number;
};

export const RedirectToLogin = (props: RedirectToLoginPropsType) => {
  useEffect(() => {
    if (props.timeDelay) {
      //timeDelay used for transfering from sign up success to login
      let seconds: number = props.timeDelay * 1000;

      setTimeout(() => {
        window.location.href = "../login";
      }, seconds);
    } else {
      window.location.href = "./login";
    }
  });

  return null;
};
