import React from "react";
import {
  ButtonBase,
  createStyles,
  GridList,
  GridListTile,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { CancelButton } from "../../global/components/Buttons/CancelButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      position: "relative",
      width: "100%",
      height: "100%",
      "&:hover, &$focusVisible": {
        zIndex: 1,
        "& $imageBackdrop": {
          opacity: 0.15,
        },
      },
    },
    focusVisible: {},

    imageSrc: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundPosition: "center 40%",
    },
    imageBackdrop: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create("opacity"),
    },
  })
);

type ImagusSampleImagesPropsType = {
  samples: Array<string>;
  loadSample: Function;
  cancelSample: Function;
};

export const ImagusSampleImages = (props: ImagusSampleImagesPropsType) => {
  const classes = useStyles();
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <GridList
        cellHeight={160}
        cols={4}
        spacing={16}
        style={{ paddingBottom: "10px" }}
      >
        {props.samples.map((sample: string, index: number) => (
          <GridListTile key={"sampleImage_" + index} cols={1}>
            <ButtonBase
              focusRipple
              className={classes.image}
              onClick={() => props.loadSample(sample)}
            >
              <span
                className={classes.imageSrc}
                style={{ backgroundImage: `url(${sample})` }}
              />
            </ButtonBase>
          </GridListTile>
        ))}
      </GridList>
      <CancelButton onClick={props.cancelSample} />
    </div>
  );
};
