import React, { ChangeEvent, FormEvent, useState } from "react";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { SingleTextField } from "../formInputs/SingleTextField";
import { formRightBtnBlock } from "../../styles/generalStyles";
import { SubmitButton } from "../Buttons/SubmitButton";
import { ErrorDisplay } from "../ErrorDisplay";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRightBtnBlock,
  })
);

type CreateProfileFormPropsType = {
  onClose: Function;
  createProfile: Function;
  editName: Function;
  edit?: boolean;
  currentName?: string;
};

export const CreateProfileForm = (props: CreateProfileFormPropsType) => {
  const classes = useStyles();
  const [name, setName] = useState(props.edit ? props.currentName : "");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (Object.keys(errors).length === 0) {
      setLoading(true);
      if (props.edit) {
        props.editName(name, onSubmitDone, onSubmitFail);
      } else {
        props.createProfile(name, onSubmitDone, onSubmitFail);
      }
    }
  };

  const onSubmitDone = () => {
    props.onClose();
  };

  const onSubmitFail = (error: Record<string, string>) => {
    setErrors(error);
    setLoading(false);
  };

  return (
    <form onSubmit={(!loading && handleSubmit) || undefined}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SingleTextField
            label="Name"
            name="name"
            inputType="string"
            value={name}
            onChange={handleInputChange}
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <ErrorDisplay errors={errors} />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.formRightBtnBlock}>
            <SubmitButton loading={loading} />
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
