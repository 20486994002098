import React, { ChangeEvent, Fragment, ReactNode } from "react";
import {
  Checkbox,
  CheckboxClassKey,
  createStyles,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";
import CircleChecked from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

import { inputStyles } from "../../styles/generalStyles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...inputStyles(theme),
    root: {
      color: theme.palette.primary.main,
      "&$checked": {
        color: theme.palette.primary.main,
      },
    },
    checked: {},
    invalid: {
      color: theme.palette.error.main,
      "&$invalidChecked": {
        color: theme.palette.error.main,
      },
    },
    invalidChecked: {},
  })
);

type SingleCheckBoxPropsType = {
  checked: boolean;
  name: string;
  classes?: Partial<ClassNameMap<CheckboxClassKey>> | undefined;
  value?: boolean;
  label?: string;
  linkAtTheEnd?: string;
  handleLinkClicked?: Function;
  labelPlace?: "bottom" | "top" | "end" | "start" | undefined;
  isInvalid?: boolean;
  isDisabled?: boolean;
  hasTitle?: boolean;
  isIndeterminated?: boolean;
  circle?: boolean;
  onChange:
    | ((event: ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
};

export const SingleCheckBox = (props: SingleCheckBoxPropsType) => {
  const classes = useStyles();

  const CheckNode = (
    <Checkbox
      name={props.name}
      checked={props.checked}
      classes={
        props.classes
          ? props.classes
          : {
              root: props.isInvalid ? classes.invalid : classes.root,
              checked: props.isInvalid
                ? classes.invalidChecked
                : classes.checked,
            }
      }
      onChange={props.onChange}
      indeterminate={props.isIndeterminated}
      disabled={props.isDisabled}
      {...(props.circle && {
        icon: <CircleUnchecked />,
        checkedIcon: <CircleChecked />,
      })}
    />
  );

  const handleClickEvent = () => {
    props.handleLinkClicked && props.handleLinkClicked();
  };

  const CheckNodeWithLabel = (
    <FormControlLabel
      control={CheckNode}
      label={
        props.hasTitle ? (
          <Typography variant="subtitle1">
            {props.value ? "Enabled" : "Disabled"}
          </Typography>
        ) : (
          <Typography>
            {props.label}
            {props.linkAtTheEnd && (
              <Link href="#" onClick={handleClickEvent}>
                {props.linkAtTheEnd}
              </Link>
            )}
          </Typography>
        )
      }
      labelPlacement={props.labelPlace ? props.labelPlace : "end"}
    />
  );

  return <Fragment>{props.label ? CheckNodeWithLabel : CheckNode}</Fragment>;
};
