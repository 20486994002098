import { ListItem, ListItemText, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import { EventType } from "../types/participantsType";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      fontWeight: 800,
    },

    title: {
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      padding: "0 16px",
    },
  })
);

type EventItemPropsType = {
  event: EventType;
  selectedEventId: string;
  selectEvent: Function;
};

export const EventItem = (props: EventItemPropsType) => {
  const classes = useStyles();

  return (
    <ListItem button>
      <ListItemText
        primary={props.event.eventName}
        onClick={() => props.selectEvent(props.event.eventId)}
        disableTypography
        className={classNames(
          classes.title,
          props.selectedEventId === props.event.eventId && classes.selected
        )}
      />
    </ListItem>
  );
};
