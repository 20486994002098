import { getAuthToken } from "../helperFunctions/localStorage";
import { EnrolmentType } from "../reducers/profilesReducers";
import { EnrolmentInputType } from "./faceSearchTypes";

interface RequestOptions extends Record<string, any> {
  method: string;
  mode: string;
  body?: FormData | string | undefined;
  headers: Record<string, string>;
}

export const authHeader = (): Record<string, string> => {
  let token = getAuthToken();
  if (token) return { Authorization: "Bearer " + token };
  else {
    return {};
  }
};

export const postInit = (
  data?: FormData,
  setCookie: boolean = false
): RequestInit => {
  let options: RequestInit = {
    method: "POST",
    mode: "cors",
    body: data,
    headers: authHeader(),
  };

  if (setCookie) {
    options["credentials"] = "include";
  }

  return options;
};

export const putInit = (
  data?: FormData,
  setCookie: boolean = false
): RequestOptions => {
  let options: RequestOptions = {
    method: "PUT",
    mode: "cors",
    body: data,
    headers: authHeader(),
  };

  if (setCookie) {
    options["credentials"] = "include";
  }

  return options;
};

export const getInit = (
  shouldCache: boolean = false,
  setCookie: boolean = false
): RequestInit => {
  let headers = authHeader();

  if (shouldCache) {
    headers["Cache-Control"] = "public, max-age=2592000";
  }

  let options: RequestInit = {
    method: "GET",
    mode: "cors",
    headers: headers,
  };

  if (setCookie) {
    options["credentials"] = "include";
  }
  return options;
};

export const deleteInit = (
  data?: FormData,
  setCookie: boolean = false
): RequestInit => {
  let options: RequestInit = {
    method: "DELETE",
    mode: "cors",
    body: data,
    headers: authHeader(),
  };

  if (setCookie) {
    options["credentials"] = "include";
  }

  return options;
};

// export const postBody = (
//   data?: JSON,
//   setCookie: boolean = false
// ): RequestOptions => {
//   let headers = authHeader();
//   headers["Content-Type"] = "application/json";

//   let options: RequestOptions = {
//     method: "POST",
//     mode: "cors",
//     body: JSON.stringify(data),
//     headers: headers,
//   };

//   if (setCookie) {
//     options["credentials"] = "include";
//   }

//   return options;
// };

export interface Data {
  username?: string;
  password?: string;
  userId?: string;
  profileId?: string;
  name?: string;
  email?: string;
  details?: Array<Record<string, string>>;
  newEnrolments?: Array<EnrolmentInputType>;
  deleteEnrolments?: Array<string>;
  tags?: Array<string>;
  passwordChangeModel?: PasswordChangeModel;
  updateProfileModel?: UpdateProfileModel;
  roleName?:string;
}

export interface UpdateProfileModel extends Data {
  profileId: string;
  accountId?: string;
  name?: string;
  newEnrolments?: Array<EnrolmentInputType>;
  deleteEnrolments?: Array<string>;
}

export interface PasswordChangeModel extends Data {
  newPassword: string;
  currentPassword: string;
}

export interface ResetPasswordModel extends Data {
  email: string;
  token: string;
  password: string;
}

export const postBody = (
  data?: Data | string,
  setCookie: boolean = false
): RequestInit => {
  let headers = authHeader();
  headers["Content-Type"] = "application/json";

  let options: RequestInit = {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(data),
    headers: headers,
  };

  if (setCookie) {
    options["credentials"] = "include";
  }
  console.log(options);
  return options;
};

export const putBody = (
  data?: Data,
  setCookie: boolean = false
): RequestInit => {
  let headers = authHeader();
  headers["Content-Type"] = "application/json";

  let options: RequestInit = {
    method: "PUT",
    mode: "cors",
    body: JSON.stringify(data),
    headers: headers,
  };

  if (setCookie) {
    options["credentials"] = "include";
  }

  return options;
};

export const deleteBody = (
  data?: Data | string | Array<string>,
  setCookie: boolean = false
): RequestInit => {
  let headers = authHeader();
  headers["Content-Type"] = "application/json";

  let options: RequestInit = {
    method: "DELETE",
    mode: "cors",
    body: JSON.stringify(data),
    headers: headers,
  };

  if (setCookie) {
    options["credentials"] = "include";
  }
  return options;
};
